/* eslint-disable no-nested-ternary */
import { Avatar, Loader, Table, Text, Tooltip } from '@mantine/core';
import dayjs from 'dayjs';

import DownloadBtn from '../Buttons/DownloadBtn';
import H1 from '../Texts/H1';

interface TitleOptionsProps {
  text: string;
  fontSize: string;
  textAlign:
    | 'center'
    | 'end'
    | 'justify'
    | 'left'
    | 'match-parent'
    | 'right'
    | 'start';
}

interface ScrubProps {
  text: string;
}

interface MatchesProps {
  internal: string;
  ftc: string;
  tpcaLitigator: string;
}

interface InfoProps {
  text: string;
}

interface ElementsProps {
  time: string;
  file: string;
  user: string;
  numbers: string;
  scrub: ScrubProps[];
  matches: MatchesProps;
  info: InfoProps[];
  downloadsM?: string;
  downloadsC?: string;
  downloadsI?: string;
  badNumbers: number;
  cleanNumbers: number;
  badCount: any;
  invalidNumbers: number;
  duplicateNumbers: number;
  status: string;
  states: string;
  failed?: boolean;
  outputFileFormat?: string;
}

interface ScrubLogsTableProps {
  titleOptions: TitleOptionsProps[];
  elements: ElementsProps[];
  loading: boolean;
}

export default function ScrubLogsTable({
  titleOptions,
  elements,
  loading,
}: ScrubLogsTableProps) {
  const rows =
    elements.length > 0
      ? elements.map((element) => (
          <tr
            className="text-center"
            style={{ verticalAlign: 'text-top' }}
            key={element.time}
          >
            <td
              className="whitespace-nowrap text-left"
              style={{ color: '#626262' }}
            >
              {dayjs(element.time).format('MM/DD/YY hh:mm a')}
            </td>
            <td className="" style={{ color: '#626262' }}>
              <div className="flex items-center">
                <Tooltip
                  label={element.file}
                  styles={{
                    tooltip: { color: 'white !important' },
                  }}
                >
                  <Text
                    lineClamp={1}
                    className="cursor-default text-left text-[14px]"
                  >
                    {element.file}
                  </Text>
                </Tooltip>
              </div>
            </td>
            <td style={{}}>{element.numbers}</td>
            <td>{element.duplicateNumbers?.toString() || 0}</td>
            <td>{element.matches.tpcaLitigator || 0}</td>
            <td>{element.matches.internal || 0}</td>
            <td className="">
              {element.downloadsM ? (
                <DownloadBtn
                  file_name="Bad Numbers"
                  btnText={element.badNumbers?.toString()}
                  s3key={element.downloadsM}
                  iconColor="red"
                  ext={element.outputFileFormat}
                />
              ) : (
                '0'
              )}
            </td>
            <td>
              {element.downloadsC ? (
                <DownloadBtn
                  file_name="Clean Numbers"
                  btnText={element.cleanNumbers?.toString()}
                  s3key={element.downloadsC}
                  iconColor="green"
                  ext={element.outputFileFormat}
                />
              ) : (
                '0'
              )}
            </td>
            <td>
              {element.downloadsI ? (
                <DownloadBtn
                  file_name="Invalid Numbers"
                  btnText={element.invalidNumbers?.toString()}
                  s3key={element.downloadsI}
                  iconColor="yellow"
                  ext={element.outputFileFormat}
                />
              ) : (
                '0'
              )}
            </td>
            <td style={{ color: '#626262' }}>
              <div className="flex items-center justify-center">
                {element.status === 'Ready' && !element.failed && (
                  <Tooltip
                    label={'Ready'}
                    styles={{
                      tooltip: { color: 'white !important' },
                    }}
                  >
                    <Avatar src={`/svg/ready.svg`} size={12} />
                  </Tooltip>
                )}
                {element.status === 'In Progress' && !element.failed && (
                  <Tooltip
                    label={'Progress'}
                    styles={{
                      tooltip: { color: 'white !important' },
                    }}
                  >
                    <Avatar src={`/svg/in-progress.svg`} size={12} />
                  </Tooltip>
                )}
                {element.failed && (
                  <Tooltip
                    label={'Something went wrong. Try again'}
                    styles={{
                      tooltip: { color: 'white !important' },
                    }}
                  >
                    <Avatar src={`/svg/error.svg`} size={12} />
                  </Tooltip>
                )}
              </div>
            </td>
            <td
              style={{
                color: '#626262',
                textAlign: 'left',
                whiteSpace: 'nowrap',
              }}
            >
              {element.user}
            </td>
          </tr>
        ))
      : null;

  return (
    <Table className="w-full">
      <thead className="relative">
        <tr style={{ backgroundColor: '#E8F5FF' }}>
          {titleOptions.map((t, i) => {
            return (
              <th key={i}>
                <H1
                  text={t.text}
                  fontSize={t.fontSize}
                  textAlign={t.textAlign}
                />
              </th>
            );
          })}
        </tr>
      </thead>
      {loading ? (
        // Loading state
        <tbody>
          <tr>
            <td colSpan={100} className="text-center">
              <div className="flex w-full justify-center py-10 pr-36">
                <Loader color={'#0F182D'} />
              </div>
            </td>
          </tr>
        </tbody>
      ) : rows ? (
        // If any rows created, show them
        <tbody>{rows}</tbody>
      ) : (
        // If no rows, show message
        <tbody>
          <tr>
            <td colSpan={100} className="text-center">
              <div className="flex w-full justify-center py-10 pr-36">
                No results found
              </div>
            </td>
          </tr>
        </tbody>
      )}
    </Table>
  );
}
