import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../routes/config';
import LenderBtn from '../Buttons/LenderBtn';
import ExternalIcon from '../Icons/ExternalLink';
import Rating from '../Icons/Stars';

interface LenderProps {
  name: string;
  hidden?: boolean;
  image?: string;
  stars?: number;
  ratings?: number;
  description?: string;
  email?: string;
  phone?: string;
  website?: string;
  id: string;
}

export default function Lender({
  name,
  hidden = false,
  image = 'images/lenderTest.png',
  stars = 4.5,
  ratings = 100,
  description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et massami.',
  website,
  id,
}: LenderProps) {
  const navigate = useNavigate();

  return (
    <div className="lenderShadow rounded-xl bg-white md:min-w-[330px] ">
      <div className="z-0 flex h-full cursor-pointer flex-col">
        <a
          onClick={() => {
            navigate(ROUTES.LENDER.replace(':id', id));
          }}
          className="p-4 hover:bg-gradient-to-b hover:from-[#DFF1FF] hover:to-white"
        >
          <div className={`flex justify-end ${hidden ? 'invisible' : ''}`}>
            <img
              src="/Images/emc_preferred.svg"
              className="object-contain"
              alt="preferred"
            />
          </div>
          <div className="mt-5 flex items-center justify-center">
            <img
              src={image}
              alt="lender"
              className="!h-[80px] !max-w-[343px] object-contain"
            ></img>
          </div>
          <div className="mt-3 flex flex-row justify-between">
            <span
              id="LenderTitle"
              className="font-Segoe text-lg font-bold text-blue"
            >
              {name}
            </span>
            <div className="flex flex-row items-center gap-1">
              <span className="font-Segoe text-[10px] text-gray">
                ({ratings})
              </span>
              <Rating rating={stars} />
            </div>
          </div>
          <div className="h-[48px] min-h-[48px]">
            {description && (
              <span
                className="twolinestext font-Segoe text-sm text-blue"
                dangerouslySetInnerHTML={{
                  __html: `${description}`,
                }}
              ></span>
            )}
          </div>
        </a>
        <div className="z-10 mt-4 flex h-full flex-row items-end gap-2 p-4 pt-0">
          {website && (
            <LenderBtn
              btnText="Website"
              fontSize="11px"
              url={website}
              icon={<ExternalIcon></ExternalIcon>}
            ></LenderBtn>
          )}
        </div>
      </div>
    </div>
  );
}
