import '../../App.css';

import { useQuery } from '@apollo/client';
import { Pagination } from '@mantine/core';
import { useEffect, useState } from 'react';

import { LOG_TYPE_PRETIFY, LOGS_PER_PAGE } from '../../config/constants';
import { GET_LOGS, GET_LOGS_COUNT } from '../../graphql/queries';
import GeneralTable from '../Tables/GeneralTable';
// import FilledBtn from '../Buttons/FilledBtn';
import H1 from '../Texts/H1';
import H2 from '../Texts/H2';

interface GetLogsResponse {
  logs: Log[];
}

export interface Log {
  id: string;
  user_id: string;
  user: string;
  message: any;
  timestamp: string;
  type: string;
  sublogs: Sublogs[];
}

export interface Sublogs {
  information?: string;
  timestamp?: string;
}

const generateMessage = (type: string, message: any, _sublogs: Sublogs[]) => {
  const info = JSON.parse(message);
  switch (type) {
    case 'search_mass':
      if (info.failed) return `Error while searching in file: ${info.filename}`;
      return `File searched: ${info.filename} (${info.totalCount} contacts searched)`;
    case 'search_single':
      return `Searched for single number`;
    // return `Searched for: ${sublogs[0]?.information}`;
    case 'add_mass':
      // return `Added ${sublogs.length} contacts to EMC Database`;
      return `Added new contacts to EMC Database`;
    case 'add_single':
      // eslint-disable-next-line no-case-declarations
      // const contact = JSON.parse(sublogs[0]?.information || '');
      // eslint-disable-next-line no-case-declarations
      // const contactInfo = [];
      // if (contact.name) contactInfo.push(contact.name);
      // if (contact.email) contactInfo.push(contact.email);
      // if (contact.phone) contactInfo.push(contact.phone);
      return `Added new contact to EMC database`;
    default:
      return ' - ';
  }
};

export default function Logs() {
  const [, setClickedLog] = useState<Log | null>(null);
  const [logs, setLogs] = useState<any[]>([]);
  const [activePage, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const { loading: loadingPagination } = useQuery(GET_LOGS_COUNT, {
    onCompleted: (data) =>
      setPageTotal(Math.ceil(data.logsCount / LOGS_PER_PAGE)),
  });
  const mapLogs = (data: GetLogsResponse) => {
    return data.logs.map((value) => ({
      ...value,
      message: generateMessage(value.type, value.message, value.sublogs),
      type: (LOG_TYPE_PRETIFY as any)[value.type],
      time: new Date(value.timestamp).toLocaleString(),
      onclick: () => {
        setClickedLog(value);
      },
    }));
  };
  const { refetch, loading } = useQuery<GetLogsResponse>(GET_LOGS, {
    notifyOnNetworkStatusChange: true,
    variables: { limit: LOGS_PER_PAGE },
    onCompleted: (data) => {
      setLogs(mapLogs(data));
    },
  });

  useEffect(() => {
    refetch({ offset: (activePage - 1) * LOGS_PER_PAGE });
  }, [activePage]);

  return (
    <>
      {/* <LogDetailsModal
        opened={clickedLog !== null}
        close={() => setClickedLog(null)}
        log={clickedLog}
      /> */}
      <div className="flex justify-between">
        <div className="flex">
          <H2 text="Log" fontSize="32px" fontWeight={400} />
          <div className="pl-2">
            <H1 text="History" fontSize="32px" />
          </div>
        </div>
      </div>
      {/* <div className="pt-2">
        <Paragraph text="On this page you can compare your phone numbers with our database to find any DNC/TCPA numbers" />
      </div> */}
      <div className="pt-5">
        <GeneralTable
          titleOptions={[
            { text: 'Time', fontSize: '14px', accessor: 'time' },
            { text: 'User', fontSize: '14px', accessor: 'user' },
            { text: 'Type', fontSize: '14px', accessor: 'type' },
            { text: 'Message', fontSize: '14px', accessor: 'message' },
          ]}
          loading={loading}
          elements={logs}
        />
        <div className="flex justify-center pb-10 pr-36 pt-5">
          {!loadingPagination && logs.length > 0 && (
            <Pagination
              page={activePage}
              onChange={setPage}
              total={pageTotal}
              color="gray"
              size="sm"
              radius="md"
            />
          )}
        </div>
      </div>
    </>
  );
}
