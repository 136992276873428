import '../../App.css';

import { useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Textarea } from '@mantine/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { NEW_REVIEW } from '../../graphql/mutations';
import IconBtn from '../Buttons/IconBtn';
import CrossIcon from '../Icons/Cross';
import ReviewIcon from '../Icons/Review';
import RatingClick from '../Icons/StarsClick';

interface IReviewProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  lender_id: string;
  user_id: string;
  lenderName: string;
  myReviews: any;
  setMyReviews: (value: any) => void;
  user_name: string;
}
type FormData = {
  message: string;
  rating: number;
};

const NewReview = ({
  isOpen,
  setIsOpen,
  lender_id,
  lenderName = 'Example',
  user_id,
  myReviews,
  setMyReviews,
  user_name,
}: IReviewProps) => {
  const [selectedRating, setSelectedRating] = useState<number | null>(0);
  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };
  const { handleSubmit, register, reset } = useForm<FormData>();
  const { user } = useAuth0();

  const [newReview] = useMutation(NEW_REVIEW);

  const onSubmit = async (data: FormData) => {
    newReview({
      variables: {
        id: lender_id,
        rating: selectedRating || 0,
        user_id,
        message: data.message,
        user_picture: user?.picture,
      },
    });
    setMyReviews([
      ...myReviews,
      {
        id: data.message,
        message: data.message,
        rating: selectedRating,
        user_id,
        user_full_name: user_name,
        timestamp: new Date().toISOString(),
        user_picture: user?.picture,
      },
    ]);
    reset();
    setSelectedRating(0);
    toggleDialog();
  };

  return (
    <div className={`dialog-container ${isOpen ? 'open' : ''}`}>
      <div className="dialog-backdrop" onClick={toggleDialog} />
      <div id="filterDialog" className="dialog-content">
        <div className="m-3 px-5 md:px-0">
          <div className="flex justify-end">
            <a onClick={toggleDialog} className="cursor-pointer">
              <CrossIcon color="black" />
            </a>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-2">
                <span
                  id="ReviewTitle"
                  className="font-Segoe text-base font-bold text-gray"
                >
                  Rate {lenderName}
                </span>
                <RatingClick
                  selectedRating={selectedRating}
                  setSelectedRating={setSelectedRating}
                />
              </div>
              <div className="flex w-96 flex-col gap-2">
                <span className="font-Segoe text-base font-bold text-gray">
                  Add a comment
                </span>
                <Textarea
                  placeholder="Your comment"
                  radius="md"
                  id="ReviewMessage"
                  autosize
                  {...register('message')}
                  minRows={3}
                  maxRows={6}
                  styles={{
                    wrapper: {
                      borderColor: '#F1F4FA',
                      width: '100%',
                    },
                    input: {
                      color: '#626262',
                      fontFamily: 'Segoe UI',
                      fontSize: '16px',
                    },
                  }}
                />
              </div>
              <div className="flex items-center justify-end">
                <IconBtn
                  btnText="Add Review"
                  fontSize="16px"
                  id="AddReviewBtn"
                  icon={<ReviewIcon />}
                  type="submit"
                  className="rounded-[10px] bg-blue font-Segoe text-white hover:bg-[#27abe2]"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewReview;
