import { Avatar, Button } from '@mantine/core';

interface LeftTextBtnProps {
  btnText: string;
  onClick: () => void;
}

export default function LeftTextBtnClick({
  btnText,
  onClick,
}: LeftTextBtnProps) {
  return (
    <Button
      leftIcon={<Avatar src={'/svg/text-arrow.svg'} size={20} />}
      variant="white"
      styles={() => ({
        root: {
          '&:hover': {
            color: '#223C78',
            fill: '#223C78',
          },
          color: '#27ABE2',
          fill: '#223C78',
        },
      })}
      onClick={onClick}
    >
      {btnText}
    </Button>
  );
}
