const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6.99996 1.83345L9.66663 4.50012M0.666626 10.8335H3.33329L10.3333 3.83345C10.5084 3.65836 10.6473 3.45049 10.742 3.22171C10.8368 2.99294 10.8856 2.74774 10.8856 2.50012C10.8856 2.2525 10.8368 2.0073 10.742 1.77853C10.6473 1.54975 10.5084 1.34188 10.3333 1.16679C10.1582 0.991691 9.95033 0.852797 9.72155 0.758036C9.49278 0.663275 9.24758 0.614502 8.99996 0.614502C8.75234 0.614502 8.50714 0.663275 8.27836 0.758036C8.04959 0.852797 7.84172 0.991691 7.66663 1.16679L0.666626 8.16679V10.8335Z"
        className="stroke-current"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
