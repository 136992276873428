import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import type { ColDef, GridApi } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Loader } from 'tabler-icons-react';

type DetailTableProps = {
  columns: ColDef[];
  data: any[];
  loading?: boolean;
  edit?: boolean;
  setGridApi?: (gridApi: GridApi) => void;
  lenders?: boolean;
  setLendersUpdated?: React.Dispatch<React.SetStateAction<any[]>>;
};

export default function DetailTable({
  columns,
  data,
  loading,
  edit = false,
  setGridApi,
  lenders = false,
  setLendersUpdated,
}: DetailTableProps) {
  return (
    <div
      className={`w-full  ${lenders ? 'md:h-[80vh] h-[75vh]' : 'h-[300px]'}`}
    >
      <div className="ag-theme-alpine w-full h-full mb-5">
        {!loading && (
          <AgGridReact
            rowData={data}
            columnDefs={columns.map((column) => ({
              ...column,
              editable: edit && column.editable,
            }))}
            suppressMenuHide
            defaultColDef={{
              headerClass: 'bg-blue text-white font-Segoe hover:!bg-blue',
            }}
            alwaysShowHorizontalScroll={lenders}
            alwaysShowVerticalScroll={lenders}
            scrollbarWidth={8}
            onGridReady={(params) => {
              if (setGridApi) {
                setGridApi(params.api);
              }
            }}
            className="font-Segoe whitespace-nowrap overflow-x-auto h-[80vh]"
            // domLayout="autoHeight"
            rowClass="odd:bg-white even:bg-[#DFF1FF]"
            singleClickEdit
            // suppressHorizontalScroll={false}
            stopEditingWhenCellsLoseFocus
            onCellEditingStopped={(params) => {
              if (setLendersUpdated != null) {
                if (params.oldValue !== params.newValue) {
                  setLendersUpdated((current) => {
                    // prevent duplicates
                    if (current.find((item) => item.id === params.data.id)) {
                      return current.map((item) => {
                        if (item.id === params.data.id) {
                          return params.data;
                        }
                        return item;
                      });
                    }
                    return [...current, params.data];
                  });
                }
              }
            }}
          />
        )}
        {loading && (
          <div className="flex w-full justify-center py-10 pr-36">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}
