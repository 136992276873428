import { useAuth0 } from '@auth0/auth0-react';
import fileDownload from 'js-file-download';
import { useEffect, useState } from 'react';

export const useDownloadDocument = (
  onSuccessTrigger?: () => void
): [(val: string) => void, (val: string) => void, Function, boolean] => {
  const [isLoading, setLoading] = useState(false); // Used for loading indicator while saving
  const [url, setUrl] = useState(''); // sets the document key
  const [filename, setFileName] = useState(''); // Used for loading indicator while saving
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const triggerDownload = () => {
    if (!isLoading) setLoading(true);
  };

  useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const token = isAuthenticated ? await getAccessTokenSilently() : '';
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const [result] = await fetcherGetDownload(url, token);

      // processResponse(error, NOTIFY_REPORT_GENERATED);

      if (result && mounted) {
        const blob = await result.blob();
        fileDownload(blob, filename);
        if (onSuccessTrigger) onSuccessTrigger();
      }

      setLoading(false); // stop loading indicator for either case.
    };

    if (isLoading && url) fetchData();

    return () => {
      mounted = false;
    };
  }, [isLoading, url]);

  return [setUrl, setFileName, triggerDownload, isLoading];
};

export const fetcherGetDownload = async (url: string, token?: string) => {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response;
    }
    return [response, null];
  } catch (error: any) {
    return [null, error];
  }
};
