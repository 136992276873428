import './App.css';

import { useLazyQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mantine/core';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import NavBar from './components/Layout/NavBar';
import SideBar from './components/Layout/SideBar';
import ApolloErrorModal from './components/Modals/ApolloErrorModal';
import { roles, userRole } from './components/state/atoms';
import { GET_ALL_ROLES, GET_USER_ROLE } from './graphql/queries';
import { removeLogs } from './hooks/removeLogs';
import RoutesIntranet from './routes';
import { ROUTES } from './routes/config';

function App() {
  const { loginWithRedirect, isAuthenticated, isLoading, error, logout, user } =
    useAuth0();
  const [getRole] = useLazyQuery(GET_USER_ROLE);
  const [getAllRoles] = useLazyQuery(GET_ALL_ROLES);
  const setUserRole = useSetRecoilState(userRole);
  const setRoles = useSetRecoilState(roles);
  // const navigate = useNavigate();
  // Get user info and role from API
  useEffect(() => {
    if (!isAuthenticated && !isLoading && !error) loginWithRedirect();
    else if (user) {
      getRole({
        variables: { id: user?.sub },
        onCompleted: (data) => setUserRole(data.userRole),
      });
      getAllRoles({ onCompleted: (data) => setRoles(data.getAllRoles) });
    }
  }, [
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    error,
    logout,
    getRole,
    user,
    setUserRole,
  ]);

  // Disable console.log on production
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      removeLogs(false, true);
    }
  }, []);
  const links = [
    // {
    //   link: ROUTES.HOME,
    //   color: "#809FB8",
    //   text: "Dashboard",
    // },
    {
      link: ROUTES.WELCOME,
      color: '#809FB8',
      text: 'Welcome',
    },
    {
      link: ROUTES.PHONE_SCRUB,
      color: '#809FB8',
      text: 'DNC',
    },
    {
      link: ROUTES.USERS,
      color: '#809FB8',
      text: 'Users',
    },
    {
      link: ROUTES.LOGS,
      color: '#809FB8',
      text: 'Logs',
    },
    {
      link: ROUTES.LENDERS,
      color: '#809FB8',
      text: 'Lenders',
    },
    {
      link: ROUTES.LOAN_STATUS,
      color: '#809FB8',
      text: 'Loans',
    },
    {
      link: ROUTES.TUTORIALS,
      color: '#809FB8',
      text: 'Tutorials',
    },
    {
      link: ROUTES.ESHOP,
      color: '#809FB8',
      text: 'E-Shop',
    },
    {
      link: ROUTES.MARKETING,
      color: '#809FB8',
      text: 'Marketing',
    },
    {
      link: ROUTES.EDUCATION,
      color: '#809FB8',
      text: 'Education',
    },
    {
      link: ROUTES.PAYROLL,
      color: '#809FB8',
      text: 'Payroll',
    },
  ];
  return (
    <>
      {/* <Head>
          <title>EMC Intranet</title>
          <meta name="robots" content="noindex,nofollow" />
      </Head> */}
      <div>
        <ApolloErrorModal />
        {isLoading || error
          ? error && (
              <div className="absolute h-screen w-screen">
                <div className="flex h-full w-full flex-col items-center justify-center gap-5">
                  Only EMortgage Capital emails are allowed
                  <Button
                    className="bg-[#223C78]"
                    onClick={() =>
                      logout({ returnTo: process.env.REACT_APP_PUBLIC_URL })
                    }
                  >
                    Logout
                  </Button>
                </div>
              </div>
            )
          : user && (
              <section className="w-full">
                <div className="flex flex-row">
                  <SideBar links={links} />
                  <div className="flex w-full md:pl-[17rem] md:pr-10">
                    <div className="flex w-full flex-col">
                      <NavBar links={links} />
                      <div className="px-5 pt-5 md:px-0 md:pt-0">
                        <RoutesIntranet />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
      </div>
    </>
  );
}

export default App;
