import '../../App.css';

import { useQuery } from '@apollo/client';
import { Pagination } from '@mantine/core';
import { useEffect, useState } from 'react';

import { SEARCH_MASS_LOGS_PER_PAGE } from '../../config/constants';
import States from '../../config/states_pretify.json';
import {
  GET_SEARCH_MASS_LOGS,
  GET_SEARCH_MASS_LOGS_COUNT,
} from '../../graphql/queries';
import { ROUTES } from '../../routes/config';
import HNavCard from '../Navigation/HNavCard';
import ScrubLogsTable from '../Tables/ScrubLogsTable';
import H1 from '../Texts/H1';
import H2 from '../Texts/H2';
import Paragraph from '../Texts/Paragraph';

interface GetSearchMassLogsResponse {
  searchMassLogs: {
    id: string;
    user_id: string;
    user: string;
    message: {
      badNumbers?: string;
      cleanNumbers?: string;
      totalCount: number;
      badCount: string;
      cleanCount: number;
      duplicateCount: number;
      duration: number;
      filename: string;
      scrub: string[];
      states: string[];
      invalidNumbers: string;
      invalidCount: number;
      failed?: boolean;
      outputFileFormat?: string;
    };
    timestamp: string;
    type: string;
  }[];
}
const mapBad = (data: string) => {
  const json = JSON.parse(data) as {
    [key: string]: number;
  };
  if (Object.keys(json).length === 0) return [{ text: 'Total: 0' }];
  const internal = json['WCL Database'];
  delete json['WCL Database'];
  let tpcaLitigator = 0;
  // eslint-disable-next-line no-restricted-syntax, unused-imports/no-unused-vars
  for (const [, value] of Object.entries(json)) {
    tpcaLitigator += value;
  }
  return {
    internal,
    tpcaLitigator,
  };
};
const getTotalBadCount = (data: string) => {
  const json = JSON.parse(data) as {
    [key: string]: number;
  };
  if (Object.keys(json).length === 0) return 0;
  return Object.values(json).reduce((a, b) => a + b) || 0;
};
const mapLogs = (data: GetSearchMassLogsResponse) => {
  return data.searchMassLogs.map((value) => ({
    time: new Date(value.timestamp),
    file: value.message.filename,
    numbers: value.message.totalCount,
    user: value.user,
    scrub: value.message.duration
      ? value.message.scrub.map((s) => JSON.parse(s)) || [
          { text: 'TCPA' },
          { text: 'Federal DNC' },
          { text: 'DNC' },
          { text: 'Complainers' },
          { text: 'TCPA troll' },
        ]
      : [],
    badNumbers: value.message.duration
      ? getTotalBadCount(value.message.badCount)
      : 0,
    badCount: value.message.badCount,
    cleanNumbers: value.message.cleanCount,
    invalidNumbers: value.message.invalidCount,
    duplicateNumbers: value.message.duplicateCount,
    states: value.message.duration
      ? value.message.states.length > 0 &&
        `States: ${value.message.states
          .map((s: string) => (States as any)[s])
          .join(', ')}`
      : '',
    matches: value.message.duration
      ? mapBad(value.message.badCount)
      : {
          internal: 0,
          ftc: 0,
          tpcaLitigator: 0,
        },
    info: [
      { text: `Clean numbers: ${value.message.cleanCount}` },
      { text: `Duplicates: ${value.message.duplicateCount}` },
      { text: `Invalid numbers: ${value.message.invalidCount}` },
      {
        text: `Processing time: ${Math.floor(value.message.duration / 1000)}s`,
      },
    ],
    downloadsM: value.message.badNumbers,
    downloadsC: value.message.cleanNumbers,
    downloadsI: value.message.invalidNumbers,
    status: value.message.duration ? 'Ready' : 'In Progress',
    failed: value.message.failed,
    outputFileFormat: value.message.outputFileFormat || 'csv',
  }));
};
export default function PhoneScrub() {
  const [loadingPage, setLoadingPage] = useState(true);
  const [logs, setLogs] = useState<any[]>([]);
  const [activePage, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const { loading: loadingPagination, startPolling } = useQuery(
    GET_SEARCH_MASS_LOGS_COUNT,
    {
      onCompleted: (data) =>
        setPageTotal(
          Math.ceil(data.searchMassLogsCount / SEARCH_MASS_LOGS_PER_PAGE)
        ),
    }
  );
  const {
    refetch,
    loading,
    startPolling: startPollingLogs,
  } = useQuery<GetSearchMassLogsResponse>(GET_SEARCH_MASS_LOGS, {
    notifyOnNetworkStatusChange: true,
    variables: { limit: SEARCH_MASS_LOGS_PER_PAGE },
    onCompleted: (data) => {
      setLoadingPage(false);
      setLogs(mapLogs(data));
    },
    // pollInterval: 5000,
  });

  useEffect(() => {
    startPolling(5000);
    startPollingLogs(5000);
  }, []);

  useEffect(() => {
    setLoadingPage(true);
    refetch({ offset: (activePage - 1) * SEARCH_MASS_LOGS_PER_PAGE });
  }, [activePage]);

  return (
    <>
      <div className="flex justify-between">
        <div className="flex">
          <H2 text="Phone" fontSize="32px" fontWeight={400} />
          <div className="pl-2">
            <H1 text="Scrub" fontSize="32px" />
          </div>
        </div>
      </div>
      <div className="pt-2">
        <Paragraph text="On this page you can compare your phone numbers with our database to find any DNC/TCPA numbers" />
      </div>
      <div className="pt-3">
        <HNavCard
          width="400px"
          numCols={2}
          buttons={[
            {
              btnText: 'New Scrub',
              img: '/svg/blue-phone.svg',
              link: ROUTES.MASS_SCRUB_STEP_1,
            },
            {
              btnText: 'Search Scrub',
              img: '/svg/user.svg',
              link: ROUTES.SINGLE_SCRUB,
            },
          ]}
        />
      </div>
      <div className="pt-5">
        <ScrubLogsTable
          titleOptions={[
            { text: 'Date', fontSize: '14px', textAlign: 'left' },
            { text: 'File', fontSize: '14px', textAlign: 'left' },
            { text: 'Numbers', fontSize: '14px', textAlign: 'center' },
            { text: 'Duplicates', fontSize: '14px', textAlign: 'center' },
            { text: 'TCPA', fontSize: '14px', textAlign: 'center' },
            { text: 'Internal', fontSize: '14px', textAlign: 'center' },
            { text: 'Total Bad', fontSize: '14px', textAlign: 'center' },
            { text: 'Total Clean', fontSize: '14px', textAlign: 'center' },
            { text: 'Invalid', fontSize: '14px', textAlign: 'center' },
            { text: 'Status', fontSize: '14px', textAlign: 'center' },
            { text: 'User', fontSize: '14px', textAlign: 'left' },
          ]}
          loading={loading && loadingPage}
          elements={logs}
        />
        <div className="flex justify-center pb-10 pr-36 pt-5">
          {!loadingPagination && logs.length > 0 && (
            <Pagination
              page={activePage}
              onChange={setPage}
              total={pageTotal}
              color="gray"
              size="sm"
              radius="md"
            />
          )}
        </div>
      </div>
    </>
  );
}
