const CallIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
    >
      <path
        d="M2.40257 2.33341H5.02174L5.86582 4.44391L4.50957 5.34808C4.4297 5.40137 4.36422 5.47355 4.31894 5.55821C4.27366 5.64287 4.24997 5.7374 4.24999 5.83342C4.25174 5.88825 4.24999 5.834 4.24999 5.834V5.84625C4.25033 5.87233 4.2515 5.8984 4.25349 5.92442C4.25699 5.97225 4.26282 6.037 4.2739 6.11692C4.29665 6.27442 4.3404 6.49142 4.42557 6.74691C4.59707 7.26025 4.93307 7.92466 5.58757 8.57916C6.24207 9.23367 6.90649 9.56966 7.41924 9.74117C7.67532 9.82633 7.89174 9.8695 8.0504 9.89283C8.13996 9.90534 8.23011 9.91313 8.32049 9.91617L8.32807 9.91675H8.33274C8.33274 9.91675 8.39807 9.91325 8.33332 9.91675C8.44162 9.91669 8.54777 9.88648 8.63988 9.82951C8.73198 9.77254 8.80641 9.69105 8.85482 9.59417L9.24565 8.8125L11.8333 9.24417V11.7642C10.6019 11.9421 7.27574 12.1177 4.6624 9.50433C2.04907 6.891 2.22407 3.56425 2.40257 2.33341ZM5.45924 6.11692L6.51332 5.41458C6.736 5.26605 6.90084 5.04546 6.98018 4.78981C7.05952 4.53416 7.04853 4.25901 6.94907 4.0105L6.10499 1.9C6.01837 1.68353 5.86888 1.49797 5.67579 1.36728C5.48271 1.23658 5.2549 1.16673 5.02174 1.16675H2.37224C1.84199 1.16675 1.34324 1.53483 1.25632 2.11C1.05799 3.41783 0.78907 7.28067 3.83757 10.3292C6.88607 13.3777 10.7489 13.1082 12.0567 12.9104C12.6319 12.8229 13 12.3247 13 11.7945V9.24417C13 8.96799 12.9021 8.70076 12.7236 8.49002C12.5451 8.27927 12.2976 8.13868 12.0252 8.09325L9.43757 7.66217C9.19146 7.62108 8.93866 7.65999 8.71629 7.77317C8.49392 7.88635 8.31369 8.06784 8.20207 8.291L8.00024 8.69525C7.92886 8.67767 7.85822 8.65724 7.78849 8.634C7.42682 8.51383 6.92457 8.2665 6.4124 7.75433C5.90024 7.24217 5.6529 6.73991 5.53274 6.37767C5.50404 6.29204 5.47971 6.20501 5.45982 6.11692H5.45924Z"
        className="fill-current"
      />
    </svg>
  );
};

export default CallIcon;
