import '../../App.css';

import IconBtn from '../Buttons/IconBtn';
import ResetFilter from '../Buttons/ResetFilters';
import CrossIcon from '../Icons/Cross';

interface IConfirmResetProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  reset: () => void;
}

const ConfirmReset = ({ isOpen, setIsOpen, reset }: IConfirmResetProps) => {
  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dialog-container ${isOpen ? 'open' : ''}`}>
      <div className="dialog-backdrop" onClick={toggleDialog} />
      <div id="filterDialog" className="dialog-content">
        <div className="flex justify-end">
          <a onClick={toggleDialog} className="cursor-pointer">
            <CrossIcon color="black" />
          </a>
        </div>
        <div className="m-5">
          <div className="flex max-w-[600px] flex-col gap-2 text-center">
            <span className="font-Segoe text-xl font-bold text-blue">
              Are you sure you want to proceed with resetting the filters?
            </span>
            <span className="font-Segoe text-base text-gray">
              You are about to reset all applied filters to their default
              settings. This will clear any customizations you have made to the
              current view.
            </span>
          </div>
          <div className="mt-2 flex flex-row items-center justify-end gap-3">
            <IconBtn
              btnText="Cancel"
              fontSize="16px"
              onClick={toggleDialog}
              className="rounded-[10px] bg-blue hover:bg-skyBlue font-Segoe"
            />
            <ResetFilter
              fontSize="14px"
              reset={reset}
              className="h-[36px] bg-[#AE0000] text-white hover:bg-blue"
            ></ResetFilter>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmReset;
