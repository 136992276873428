import '../../App.css';

import { Modal } from '@mantine/core';

import IconBtn from '../Buttons/IconBtn';
import DeleteIcon from '../Icons/Delete';

interface IPopUp {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  reset: () => void;
  title?: string;
  description?: string;
  titleColor?: string;
  descriptionColor?: string;
  acceptButton?: JSX.Element;
}

const PopUp = ({
  isOpen,
  setIsOpen,
  reset,
  title = 'Are you sure you want to delete the selected lender?',
  description = 'This action cannot be undone, and all associated data and records will be permanently removed.',
  titleColor = 'font-Segoe text-xl font-bold text-blue',
  descriptionColor = 'font-Segoe text-base text-gray',
  acceptButton = (
    <IconBtn
      btnText="Delete"
      fontSize="16px"
      onClick={reset}
      icon={<DeleteIcon />}
      className="rounded-[10px] bg-[#AE0000] font-Segoe text-white hover:bg-blue"
    />
  ),
}: IPopUp) => {
  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Modal opened={isOpen} onClose={toggleDialog} centered size="auto">
      {/* <div className={`dialog-container ${isOpen ? 'open' : ''}`}> */}
      {/* <div className="dialog-backdrop" onClick={toggleDialog} /> */}
      <div id="filterDialog" className="">
        {/* <div className="flex justify-end">
            <a onClick={toggleDialog} className="cursor-pointer">
              <CrossIcon color="black" />
            </a>
          </div> */}
        <div className="m-5">
          <div className="flex max-w-[600px] flex-col gap-2 text-center">
            <span className={titleColor}>{title}</span>
            <span className={descriptionColor}>{description}</span>
          </div>
          <div className="mt-5 flex flex-row items-center justify-end gap-3">
            <IconBtn
              btnText="Cancel"
              fontSize="16px"
              onClick={toggleDialog}
              className="rounded-[10px] bg-blue hover:bg-skyBlue font-Segoe"
            />
            {acceptButton}
          </div>
        </div>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default PopUp;
