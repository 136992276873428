import { Card } from '@mantine/core';
import { NavLink } from 'react-router-dom';

import { usePermissions } from '../../hooks/usePermissions';
// import EducationIcon from '../Icons/Education';
// import EshopIcon from '../Icons/Eshop';
// import LoanStatusIcon from '../Icons/LoanStatus';
import LogsIcon from '../Icons/Logs';
// import MarketingIcon from '../Icons/Marketing';
// import PayrollIcon from '../Icons/Payroll';
import PhoneScrubIcon from '../Icons/PhoneScrub';
// import TutorialsIcon from '../Icons/Tutorials';
import UsersIcon from '../Icons/Users';
// import WelcomeIcon from '../Icons/Welcome';

interface NavigationCardProps {
  links: {
    link: string;
    color: string;
    text: string;
  }[];
}

export default function NavigationCard({ links }: NavigationCardProps) {
  const { hasAdminPermissions } = usePermissions();

  // const LendersIcon = () => (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     width="16"
  //     height="16"
  //     viewBox="0 0 16 16"
  //     fill="none"
  //   >
  //     <path
  //       d="M6.625 7C6.625 6.90054 6.66451 6.80516 6.73484 6.73484C6.80516 6.66451 6.90054 6.625 7 6.625H9C9.09946 6.625 9.19484 6.66451 9.26517 6.73484C9.33549 6.80516 9.375 6.90054 9.375 7C9.375 7.09946 9.33549 7.19484 9.26517 7.26516C9.19484 7.33549 9.09946 7.375 9 7.375H7C6.90054 7.375 6.80516 7.33549 6.73484 7.26516C6.66451 7.19484 6.625 7.09946 6.625 7ZM14.375 4.5V12.5C14.375 12.7321 14.2828 12.9546 14.1187 13.1187C13.9546 13.2828 13.7321 13.375 13.5 13.375H2.5C2.26794 13.375 2.04538 13.2828 1.88128 13.1187C1.71719 12.9546 1.625 12.7321 1.625 12.5V4.5C1.625 4.26794 1.71719 4.04538 1.88128 3.88128C2.04538 3.71719 2.26794 3.625 2.5 3.625H5.125V3C5.125 2.63533 5.26987 2.28559 5.52773 2.02773C5.78559 1.76987 6.13533 1.625 6.5 1.625H9.5C9.86467 1.625 10.2144 1.76987 10.4723 2.02773C10.7301 2.28559 10.875 2.63533 10.875 3V3.625H13.5C13.7321 3.625 13.9546 3.71719 14.1187 3.88128C14.2828 4.04538 14.375 4.26794 14.375 4.5ZM5.875 3.625H10.125V3C10.125 2.83424 10.0592 2.67527 9.94194 2.55806C9.82473 2.44085 9.66576 2.375 9.5 2.375H6.5C6.33424 2.375 6.17527 2.44085 6.05806 2.55806C5.94085 2.67527 5.875 2.83424 5.875 3V3.625ZM2.375 4.5V7.17438C4.09703 8.12614 6.03245 8.62527 8 8.625C9.96763 8.62532 11.9031 8.12597 13.625 7.17375V4.5C13.625 4.46685 13.6118 4.43505 13.5884 4.41161C13.5649 4.38817 13.5332 4.375 13.5 4.375H2.5C2.46685 4.375 2.43505 4.38817 2.41161 4.41161C2.38817 4.43505 2.375 4.46685 2.375 4.5ZM13.625 12.5V8.02312C11.883 8.91158 9.95544 9.37485 8 9.375C6.04459 9.37521 4.11695 8.91214 2.375 8.02375V12.5C2.375 12.5332 2.38817 12.5649 2.41161 12.5884C2.43505 12.6118 2.46685 12.625 2.5 12.625H13.5C13.5332 12.625 13.5649 12.6118 13.5884 12.5884C13.6118 12.5649 13.625 12.5332 13.625 12.5Z"
  //       fill="#809FB8"
  //     />
  //   </svg>
  // );
  return (
    <div style={{ width: 240, height: '100%' }}>
      <Card
        shadow="sm"
        style={{
          backgroundColor: '#F1F4FA',
          minHeight: '100vh',
          height: '100%',
        }}
      >
        <nav>
          {/* WELCOME */}
          {/* <div className="pb-2">
            <NavLink
              to={links[0]?.link || '/'}
              className={({ isActive }) => (isActive ? 'link-active' : 'link')}
            >
              <div className="flex items-center">
                <div className="pr-4">
                  <WelcomeIcon />
                </div>
                {links[0]?.text}
              </div>
            </NavLink>
          </div> */}
          {/* TUTORIALS */}
          {/* <div className="pb-2">
            <a
              href={links[6]?.link}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <div className="flex items-center">
                <div className="pr-4">
                  <TutorialsIcon />
                </div>
                {links[6]?.text}
              </div>
            </a>
          </div> */}
          {/* ESHOP */}
          {/* <div className="pb-2">
            <a
              href={links[7]?.link}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <div className="flex items-center">
                <div className="pr-4">
                  <EshopIcon />
                </div>
                {links[7]?.text}
              </div>
            </a>
          </div> */}
          {/* Marketing */}
          {/* <div className="pb-2">
            <a
              href={links[8]?.link}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <div className="flex items-center">
                <div className="pr-4">
                  <MarketingIcon />
                </div>
                {links[8]?.text}
              </div>
            </a>
          </div> */}
          {/* Education */}
          {/* <div className="pb-2">
            <a
              href={links[9]?.link}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <div className="flex items-center">
                <div className="pr-4">
                  <EducationIcon />
                </div>
                {links[9]?.text}
              </div>
            </a>
          </div> */}
          {/* PayRoll */}
          {/* <div className="pb-2">
            <a
              href={links[10]?.link}
              target="_blank"
              rel="noopener noreferrer"
              className="link"
            >
              <div className="flex items-center">
                <div className="pr-4">
                  <PayrollIcon />
                </div>
                {links[10]?.text}
              </div>
            </a>
          </div> */}
          {/* PHONE SCRUB */}
          <div className="pb-2">
            <NavLink
              to={links[1]?.link || '/'}
              className={({ isActive }) => (isActive ? 'link-active' : 'link')}
            >
              <div className="flex items-center">
                <div className="pr-4">
                  <PhoneScrubIcon />
                </div>
                {links[1]?.text}
              </div>
            </NavLink>
          </div>
          {/* Lenders */}
          {/* <div className="pb-2">
            <NavLink
              to={links[4]?.link || '/'}
              className={({ isActive }) => (isActive ? 'link-active' : 'link')}
            >
              <div className="flex items-center">
                <div className="pr-4">
                  <LendersIcon />
                </div>
                {links[4]?.text}
              </div>
            </NavLink>
          </div> */}
          {/* Loan Status */}
          {/* {hasLPPermissions() && (
            <div className="pb-2">
              <NavLink
                to={links[5]?.link || '/'}
                className={({ isActive }) =>
                  isActive ? 'link-active' : 'link'
                }
              >
                <div className="flex items-center">
                  <div className="pr-4">
                    <LoanStatusIcon />
                  </div>
                  {links[5]?.text}
                </div>
              </NavLink>
            </div>
          )} */}
          {/* USERS */}
          {hasAdminPermissions() && (
            <div className="pb-2">
              <NavLink
                to={links[2]?.link || '/'}
                className={({ isActive }) =>
                  isActive ? 'link-active' : 'link'
                }
              >
                <div className="flex items-center">
                  <div className="pr-4">
                    <UsersIcon />
                  </div>
                  {links[2]?.text}
                </div>
              </NavLink>
            </div>
          )}
          {/* LOGS */}
          {hasAdminPermissions() && (
            <div className="pb-2">
              <NavLink
                to={links[3]?.link || '/'}
                className={({ isActive }) =>
                  isActive ? 'link-active' : 'link'
                }
              >
                <div className="flex items-center">
                  <div className="pr-4">
                    <LogsIcon />
                  </div>
                  {links[3]?.text}
                </div>
              </NavLink>
            </div>
          )}
        </nav>
      </Card>
    </div>
  );
}
