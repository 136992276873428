import { useQuery } from '@apollo/client';
import { Input, Loader, MultiSelect, Select } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { atom, useRecoilState } from 'recoil';

import type { GetLoansRes, LoanPartial } from '@/types/loans';

import {
  CANCELED_STATUS,
  LOANS_PER_PAGE,
  LOANS_STATUS,
} from '../../config/constants';
import { GET_LOANS } from '../../graphql/queries';
import FilterDisplay from '../Buttons/FilterDisplay';
import ResetFilter from '../Buttons/ResetFilters';
import { LoanStatusCard } from '../Cards/LoanStatusCard';
import DownArrowIcon from '../Icons/DownArrow';
import SearchIcon from '../Icons/Search';
import SortByIcon from '../Icons/SortBy';
import ConfirmReset from '../Overlays/ConfirmReset';
import H1 from '../Texts/H1';
import H2 from '../Texts/H2';
import Paragraph from '../Texts/Paragraph';

type LoanFilterProps = {
  borrower_name: string;
  processor_name: string;
  lo_name: string;
  investor_name: string;
  loan_status: string[] | null;
  sortingOption: string | null;
};

const filtersDefault: LoanFilterProps = {
  borrower_name: '',
  processor_name: '',
  lo_name: '',
  investor_name: '',
  loan_status: [],
  sortingOption: '',
};

export const filtersState = atom({
  key: 'loanStatusFilters',
  default: filtersDefault,
});

const LOAN_STATUS_OPTIONS = [...LOANS_STATUS, CANCELED_STATUS].map(
  (status) => ({
    value: status.status.toString(),
    label: status.name,
  })
);

export const LoanStatus = () => {
  const [filters, setFilters] = useRecoilState(filtersState);
  const [isOpenReset, setIsOpenReset] = useState(false);
  const [data, setData] = useState<LoanPartial[]>([]);
  const [count, setCount] = useState(0);

  const { fetchMore, loading } = useQuery<GetLoansRes>(GET_LOANS, {
    variables: {
      ...filters,
      loan_status: filters.loan_status?.map((status) => parseInt(status, 10)),
      offset: 0,
      limit: LOANS_PER_PAGE,
    },
    onCompleted: (res) => {
      setData(res.getLoans.loans);
      setCount(res.getLoans.count);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    setData([]);
    setCount(0);
  }, [filters]);

  const resetFilters = () => {
    setFilters(filtersDefault);
  };

  const resetFilter = () => {
    resetFilters();
    if (isOpenReset) setIsOpenReset(false);
  };

  function isFilterActive() {
    const values = Object.values(filters);
    const keys = Object.keys(filters);
    return values.some((value, index) => {
      if (keys[index] === 'sortingOption') return false;
      if (Array.isArray(value)) return value.length > 0;
      return value !== null && value !== '' && value !== undefined;
    });
  }

  const deleteFilter = (element: any, type: keyof LoanFilterProps) => {
    setData([]);
    setCount(0);
    if (type === 'loan_status') {
      const filtered = filters.loan_status?.filter((status) => {
        return status !== element;
      });
      if (filtered?.length === 0) {
        setFilters((prevFilters) => ({ ...prevFilters, [type]: null }));
        return;
      }
      if (filtered)
        setFilters((prevFilters) => ({ ...prevFilters, [type]: filtered }));
      else setFilters((prevFilters) => ({ ...prevFilters, [type]: [] }));
    } else if (typeof element === 'boolean') {
      setFilters((prevFilters) => ({ ...prevFilters, [type]: false }));
    } else if (typeof element === 'number') {
      setFilters((prevFilters) => ({ ...prevFilters, [type]: null }));
    } else {
      setFilters((prevFilters) => ({ ...prevFilters, [type]: '' }));
    }
  };

  const filtersLabels = () =>
    Object.keys(filters).map((key) => {
      const filter = filters[key as keyof LoanFilterProps];
      if (filter) {
        if (typeof filter === 'object') {
          // From the status dropdown, display the label instead of the value
          return Object.values(filter).map((state, index) => {
            if (state) {
              return (
                <FilterDisplay
                  key={`${key}_${index}`} // Unique key
                  btnText={
                    LOAN_STATUS_OPTIONS.find((option) => option.value === state)
                      ?.label || ''
                  }
                  deleteFilter={() => {
                    deleteFilter(state, key as keyof LoanFilterProps);
                  }}
                />
              );
            }
            return <React.Fragment key={`${key}_${index}`} />;
          });
        }
        if (typeof filter === 'string' && key !== 'sortingOption') {
          return (
            <FilterDisplay
              key={key}
              btnText={filter}
              deleteFilter={() => {
                deleteFilter(filter, key as keyof LoanFilterProps);
              }}
            />
          );
        }
        if (typeof filter === 'number') {
          return (
            <FilterDisplay
              key={key}
              btnText={`${key}: ${filter}`}
              deleteFilter={() => {
                deleteFilter(filter, key as keyof LoanFilterProps);
              }}
            />
          );
        }
        if (typeof filter === 'boolean') {
          return (
            <FilterDisplay
              key={key}
              btnText={`${key}: Yes`}
              deleteFilter={() => {
                deleteFilter(filter, key as keyof LoanFilterProps);
              }}
            />
          );
        }
        return <React.Fragment key={key} />;
      }
      return <React.Fragment key={key} />;
    });

  const getMoreLoans = async () => {
    const a = await fetchMore({
      variables: {
        offset: data.length,
      },
    });
    setData([...data, ...a.data.getLoans.loans]);
  };

  return (
    <>
      <div className="pb-5 md:pb-0">
        <div className="flex flex-col justify-between md:flex-row">
          <div>
            <div className="flex justify-between">
              <div className="flex">
                <H2 text="Loans" fontSize="32px" fontWeight={400} />
                <div className="pl-2">
                  <H1 text="Status" fontSize="32px" />
                </div>
              </div>
            </div>
            <div className="pt-2">
              <Paragraph text="On this page, you can view the status of any loan processed on Arive" />
            </div>
          </div>
        </div>
        <div className="mt-5 flex justify-between xl:flex-row flex-col gap-y-5">
          <div className="hidden flex-row gap-3 md:flex flex-wrap">
            <Input
              placeholder="Borrower Lender"
              radius="lg"
              id="SearchLender"
              value={filters.borrower_name || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFilters({ ...filters, borrower_name: event.target.value })
              }
              rightSection={<SearchIcon />}
              styles={{
                input: {
                  border: '1px solid #27ABE2',
                },
              }}
            />
            <Input
              placeholder="Pocessor Lender"
              radius="lg"
              id="SearchLender"
              value={filters.processor_name || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFilters({ ...filters, processor_name: event.target.value })
              }
              rightSection={<SearchIcon />}
              styles={{
                input: {
                  border: '1px solid #27ABE2',
                },
              }}
            />
            <Input
              placeholder="Loan Officer Lender"
              radius="lg"
              id="SearchLender"
              value={filters.lo_name || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFilters({ ...filters, lo_name: event.target.value })
              }
              rightSection={<SearchIcon />}
              styles={{
                input: {
                  border: '1px solid #27ABE2',
                },
              }}
            />
            <Input
              placeholder="Investor Lender"
              radius="lg"
              id="SearchLender"
              value={filters.investor_name || ''}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFilters({ ...filters, investor_name: event.target.value })
              }
              rightSection={<SearchIcon />}
              styles={{
                input: {
                  border: '1px solid #27ABE2',
                },
              }}
            />
            <MultiSelect
              id="LoanStatusFilterMultiSelect"
              data={LOAN_STATUS_OPTIONS}
              value={filters.loan_status || []}
              onChange={(val) => {
                setFilters({
                  ...filters,
                  loan_status: val.length > 0 ? val : [],
                });
              }}
              radius="md"
              rightSection={<DownArrowIcon />}
              maxSelectedValues={1}
              placeholder={'Current Status'}
              styles={{
                input: {
                  border: '1px solid #27ABE2',
                  height: '36px !important',
                },
              }}
            />
          </div>
          <div className="mx-auto flex flex-row gap-5 md:mx-0">
            <Select
              data={[
                { value: 'sd', label: 'Status (DES)' },
                { value: 'sa', label: 'Status (ASC)' },
                { value: 'lu', label: 'Last Updated' },
              ]}
              size="xs"
              radius="md"
              placeholder={'Sort By'}
              icon={<SortByIcon></SortByIcon>}
              value={filters.sortingOption}
              onChange={(value) =>
                setFilters({ ...filters, sortingOption: value })
              }
              styles={() => ({
                defaultVariant: {
                  background: '#223C78',
                  border: 'none',
                },
                input: {
                  '&::placeholder': {
                    color: '#fff',
                    fontSize: '16px',
                  },
                  color: '#fff',
                  fontSize: '16px',
                  textAlign: 'center',
                  background: 'transparent',
                  border: 'none',
                },
                root: {
                  background: '#223C78',
                  borderRadius: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  alignContent: 'center',
                  width: '190px',
                  height: '36px',
                },
                rightSection: {
                  display: 'none',
                },
              })}
            />
          </div>
        </div>
        <div className="mt-3 hidden items-center justify-between md:flex">
          <div>
            {count !== undefined && count > 0 && (
              <span className="font-Segoe text-base text-gray" id="Results">
                {count} Results
              </span>
            )}
          </div>
          <div id="filtersList" className="flex flex-row items-center gap-3">
            {filtersLabels()}
            {isFilterActive() && (
              <ResetFilter
                fontSize="14px"
                reset={() => setIsOpenReset(true)}
              ></ResetFilter>
            )}
          </div>
        </div>
        <div id="LoanList" className="relative">
          <InfiniteScroll
            dataLength={data.length}
            next={getMoreLoans}
            hasMore={count > data.length}
            className="w-full py-5 flex flex-col gap-y-5 -pl-10 !overflow-visible"
            loader={
              <div className="pt-20 flex w-full items-center justify-center">
                <Loader color={'blue'} />
              </div>
            }
          >
            {data &&
              data.length > 0 &&
              data.map((loan) => <LoanStatusCard key={loan.id} {...loan} />)}
          </InfiniteScroll>
        </div>
        {loading && (
          <div className="pt-20 flex w-full items-center justify-center">
            <Loader color={'blue'} />
          </div>
        )}
        {!loading && data && data.length === 0 && (
          <div className="mt-20 flex flex-col items-center justify-center gap-2">
            <img width={110} height={110} src="/Images/search.png"></img>
            <span className="font-Segoe text-2xl text-blue">
              No Results Found
            </span>
            <span className="font-Segoe text-base text-blue">
              No Results found with your search criteria. Try again
            </span>
            <ResetFilter
              fontSize="14px"
              reset={resetFilter}
              icon={null}
            ></ResetFilter>
          </div>
        )}
        <ConfirmReset
          isOpen={isOpenReset}
          setIsOpen={setIsOpenReset}
          reset={resetFilter}
        ></ConfirmReset>
      </div>
    </>
  );
};
