const ResetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M4.80469 2.19544C4.92967 2.32045 4.99988 2.48999 4.99988 2.66677C4.99988 2.84354 4.92967 3.01308 4.80469 3.1381L3.60935 4.33343H8.83335C9.88819 4.33343 10.9193 4.64623 11.7964 5.23226C12.6735 5.8183 13.357 6.65125 13.7607 7.62579C14.1644 8.60033 14.27 9.67268 14.0642 10.7072C13.8584 11.7418 13.3505 12.6921 12.6046 13.438C11.8587 14.1839 10.9084 14.6918 9.87384 14.8976C8.83927 15.1034 7.76691 14.9978 6.79238 14.5941C5.81784 14.1905 4.98488 13.5069 4.39885 12.6298C3.81281 11.7527 3.50002 10.7216 3.50002 9.66677C3.50002 9.48996 3.57026 9.32039 3.69528 9.19536C3.82031 9.07034 3.98988 9.0001 4.16669 9.0001C4.3435 9.0001 4.51307 9.07034 4.63809 9.19536C4.76312 9.32039 4.83335 9.48996 4.83335 9.66677C4.83335 10.4579 5.06795 11.2313 5.50748 11.889C5.947 12.5468 6.57172 13.0595 7.30262 13.3623C8.03352 13.665 8.83779 13.7443 9.61372 13.5899C10.3896 13.4356 11.1024 13.0546 11.6618 12.4952C12.2212 11.9358 12.6022 11.2231 12.7565 10.4471C12.9108 9.67121 12.8316 8.86694 12.5289 8.13603C12.2261 7.40513 11.7134 6.78042 11.0556 6.34089C10.3978 5.90136 9.62448 5.66677 8.83335 5.66677H3.60935L4.80469 6.8621C4.92613 6.98784 4.99332 7.15624 4.9918 7.33104C4.99028 7.50583 4.92017 7.67304 4.79657 7.79665C4.67296 7.92025 4.50575 7.99037 4.33095 7.99188C4.15616 7.9934 3.98776 7.92621 3.86202 7.80477L1.52869 5.47143C1.40371 5.34642 1.3335 5.17688 1.3335 5.0001C1.3335 4.82333 1.40371 4.65379 1.52869 4.52877L3.86202 2.19544C3.98704 2.07045 4.15658 2.00024 4.33335 2.00024C4.51013 2.00024 4.67967 2.07045 4.80469 2.19544Z"
        fill="white"
      />
    </svg>
  );
};

export default ResetIcon;
