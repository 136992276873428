import '../../App.css';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Loader } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';

import { LOAN_TYPES } from '../../config/constants';
import { GET_LENDERS } from '../../graphql/queries';
import { usePermissions } from '../../hooks/usePermissions';
import { ROUTES } from '../../routes/config';
import FilterDisplay from '../Buttons/FilterDisplay';
import IconBtn from '../Buttons/IconBtn';
import ResetFilter from '../Buttons/ResetFilters';
import Lender from '../Cards/Lender';
import LenderMulti from '../Dropdowns/MultiSelectLender';
import SortByLender from '../Dropdowns/SortBy';
import FilterIcon from '../Icons/Filter';
import StatsIcon from '../Icons/Stats';
import LenderInput from '../Inputs/LenderInput';
import ConfirmReset from '../Overlays/ConfirmReset';
import MoreFilters from '../Overlays/MoreFilters';
import H1 from '../Texts/H1';
import H2 from '../Texts/H2';
import Paragraph from '../Texts/Paragraph';

export interface FilterType {
  lenderName: string | null;
  loanType: string[] | null;
  compType: string[] | null;
  epo_days: string[] | null;
  state: string[] | null;
  NoFico: boolean | null;
  FHAMinimum: number | null;
  VAMinimum: number | null;
  LockNShop: boolean | null;
  ManualUW: boolean | null;
  TDBUnderwrite: boolean | null;
  LoanSifter: boolean | null;
  LenderPrice: boolean | null;
  MinRating: number | null;
  sortingOption: string | null;
}

const filtersDefault: FilterType = {
  lenderName: '',
  loanType: null,
  compType: null,
  epo_days: null,
  state: null,
  NoFico: null,
  FHAMinimum: null,
  VAMinimum: null,
  LockNShop: null,
  ManualUW: null,
  TDBUnderwrite: null,
  LoanSifter: null,
  LenderPrice: null,
  MinRating: null,
  sortingOption: '',
};

export const filtersState = atom({
  key: 'lendersFilters',
  default: filtersDefault,
});

const LENDERS_FIRST_PAGE = (Math.floor(window.innerHeight / 310) - 1) * 4;
const LENDER_PER_PAGE = 8;

export default function Lenders() {
  const [filters, setFilters] = useRecoilState(filtersState);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenReset, setIsOpenReset] = useState(false);
  const [reset, setReset] = useState(false);
  const [lendersData, setLendersData] = useState<any[]>([]);
  const [areMoreLenders, setMoreLenders] = useState(true);
  const [count, setCount] = useState(undefined);
  const { hasAdminPermissions } = usePermissions();
  const navigate = useNavigate();

  const VARIABLES = {
    name: filters.lenderName || '',
    loanType: filters.loanType || null,
    compType: filters.compType || null,
    epo_days: filters.epo_days || null,
    state: filters.state || null,
    NoFico: filters.NoFico || null,
    FHAMinimum: Number(filters.FHAMinimum) || null,
    VAMinimum: Number(filters.VAMinimum) || null,
    LockNShop: filters.LockNShop || null,
    ManualUW: filters.ManualUW || null,
    TDBUnderwrite: filters.TDBUnderwrite || null,
    LoanSifter: filters.LoanSifter || null,
    LenderPrice: filters.LenderPrice || null,
    MinRating: filters.MinRating || null,
    sortingOption: filters.sortingOption || 'az',
    offset: 0,
    limit: LENDERS_FIRST_PAGE + 8, // first page load 8 more for big screens
  };
  const { loading } = useQuery(GET_LENDERS, {
    variables: { ...VARIABLES }, // offset 0 , limit: LENDERS_FIRST_PAGE + 8
    onCompleted: (data) => {
      if (data.getLenders.lenders.length === 0) setMoreLenders(false);
      setLendersData(data.getLenders.lenders);
      setCount(data.getLenders.count);
    },
    fetchPolicy: 'no-cache',
  });

  const [getMoreLenders] = useLazyQuery(GET_LENDERS, {
    onCompleted: (data) => {
      if (data.getLenders.lenders.length > 0)
        setLendersData([...lendersData, ...data.getLenders.lenders]);
      else setMoreLenders(false);
      setCount(data.getLenders.count);
    },
    fetchPolicy: 'no-cache',
  });

  const resetFilter = () => {
    setFilters(filtersDefault);
    setReset(true);
    if (isOpenReset) setIsOpenReset(false);
  };

  function isFilterActive() {
    const values = Object.values(filters);
    const keys = Object.keys(filters);
    return values.some((value, index) => {
      if (keys[index] === 'sortingOption') return false;
      if (Array.isArray(value)) return value.length > 0;
      return value !== null && value !== '' && value !== undefined;
    });
  }

  const deleteFilter = (element: any, type: keyof FilterType) => {
    if (type === 'state' || type === 'loanType' || type === 'compType') {
      const filtered = filters[type]?.filter((state) => {
        return state !== element;
      });
      if (filtered?.length === 0) {
        setFilters((prevFilters) => ({ ...prevFilters, [type]: null }));

        return;
      }
      setFilters((prevFilters) => ({ ...prevFilters, [type]: filtered }));
    }
    if (type === 'lenderName') {
      setFilters((prevFilters) => ({ ...prevFilters, [type]: '' }));
    }
    if (typeof element === 'boolean') {
      setFilters((prevFilters) => ({ ...prevFilters, [type]: false }));
    }
    if (typeof element === 'number') {
      setFilters((prevFilters) => ({ ...prevFilters, [type]: null }));
    }
  };

  const filtersLabels = () =>
    Object.keys(filters).map((key) => {
      const filter = filters[key as keyof FilterType];
      if (filter) {
        if (typeof filter === 'object') {
          return Object.values(filter).map((state, index) => {
            if (state) {
              return (
                <FilterDisplay
                  key={`${key}_${index}`} // Unique key
                  btnText={state}
                  deleteFilter={() => {
                    deleteFilter(state, key as keyof FilterType);
                  }}
                />
              );
            }
            return <React.Fragment key={`${key}_${index}`} />;
          });
        }
        if (typeof filter === 'string' && key !== 'sortingOption') {
          return (
            <FilterDisplay
              key={key}
              btnText={filter}
              deleteFilter={() => {
                deleteFilter(filter, key as keyof FilterType);
              }}
            />
          );
        }
        if (typeof filter === 'number') {
          return (
            <FilterDisplay
              key={key}
              btnText={`${key}: ${filter}`}
              deleteFilter={() => {
                deleteFilter(filter, key as keyof FilterType);
              }}
            />
          );
        }
        if (typeof filter === 'boolean') {
          return (
            <FilterDisplay
              key={key}
              btnText={`${key}: Yes`}
              deleteFilter={() => {
                deleteFilter(filter, key as keyof FilterType);
              }}
            />
          );
        }
        return <React.Fragment key={key} />;
      }
      return <React.Fragment key={key} />;
    });

  const moreLenders = () => {
    getMoreLenders({
      variables: {
        ...VARIABLES,
        offset: lendersData.length,
        limit: lendersData.length + LENDER_PER_PAGE,
      },
      // offset lendersData.length, limit: lendersData.length + LENDER_PER_PAGE
    });
  };

  useEffect(() => {
    setMoreLenders(true);
    setLendersData([]);
    setCount(undefined);
    getMoreLenders({
      variables: {
        ...VARIABLES,
        offset: 0,
        limit: LENDERS_FIRST_PAGE + 8,
      },
    });
  }, [filters]);

  return (
    <>
      <div className="pb-5 md:pb-0">
        <div className="flex flex-col justify-between md:flex-row">
          <div>
            <div className="flex justify-between">
              <div className="flex">
                <H2 text="Emortgage" fontSize="32px" fontWeight={400} />
                <div className="pl-2">
                  <H1 text="Lenders" fontSize="32px" />
                </div>
              </div>
            </div>
            <div className="pt-2">
              <Paragraph text="On this page, you can view, rate, and leave comments about our Lenders." />
            </div>
          </div>
          <div className="mt-5 flex flex-row items-center justify-center gap-5 md:mt-0">
            {hasAdminPermissions() && (
              <IconBtn
                id="NewLenderBtn"
                btnText="Add Lender"
                fontSize="16px"
                onClick={() => navigate(ROUTES.LENDER_NEW)}
              />
            )}
            {hasAdminPermissions() && (
              <IconBtn
                id="LenderTableBtn"
                btnText="Editing Table"
                fontSize="16px"
                onClick={() => navigate(ROUTES.LENDER_TABLE)}
              />
            )}
            <a href="https://emc.digitallending.com" target="_blank">
              <IconBtn
                btnText="Check Rates"
                fontSize="16px"
                icon={<StatsIcon />}
                className="bg-blue font-Segoe hover:bg-[#27abe2]"
              />
            </a>
          </div>
        </div>
        <div className="mt-5 flex justify-between xl:flex-row flex-col gap-y-5">
          <div className="hidden flex-row gap-3 md:flex flex-wrap">
            <LenderInput filters={filters} setFilters={setFilters} />
            <LenderMulti
              id="LoanTypeMultiSelect"
              setFilters={setFilters}
              filters={filters}
              data={LOAN_TYPES}
              filterKey="loanType"
              // creatable={true}
            />
          </div>
          <div className="mx-auto flex flex-row gap-5 md:mx-0">
            <a
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <IconBtn
                id="moreFiltersBtn"
                btnText="More Filters"
                fontSize="16px"
                icon={<FilterIcon />}
                className="h-[36px] rounded-[10px] bg-blue font-Segoe hover:bg-[#27abe2]"
              />
            </a>
            <SortByLender
              className="bg-blue font-Segoe items-center text-center"
              setFilters={setFilters}
              filters={filters}
            />
          </div>
        </div>
        <div className="mt-3 hidden items-center justify-between md:flex">
          <div>
            {count !== undefined && count > 0 && (
              <span className="font-Segoe text-base text-gray" id="Results">
                {count} Results
              </span>
            )}
          </div>
          <div id="filtersList" className="flex flex-row items-center gap-3">
            {filtersLabels()}
            {isFilterActive() && (
              <ResetFilter
                fontSize="14px"
                reset={() => setIsOpenReset(true)}
              ></ResetFilter>
            )}
          </div>
        </div>
        <div id="LenderList" className={`relative`}>
          <InfiniteScroll
            dataLength={lendersData.length} // This is important field to render the next data
            next={moreLenders}
            hasMore={areMoreLenders}
            className="w-full py-5 p-2 grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4"
            loader={
              <div className="pt-20 flex col-span-1 sm:grid-col-2 md:col-span-2 lg:col-span-2 xl:col-span-3 2xl:col-span-4 w-full items-center justify-center">
                <Loader color={'blue'} />
              </div>
            }
          >
            {lendersData &&
              lendersData.length > 0 &&
              lendersData.map((lender: any) => {
                return (
                  <Lender
                    key={lender.id}
                    name={lender.name}
                    hidden={!lender.featured}
                    image={lender.lender_picture}
                    stars={lender.average_review_score}
                    ratings={lender.total_votes}
                    description={lender.short_description}
                    email={lender.email}
                    phone={lender.phone}
                    website={lender.website}
                    id={lender.id}
                  ></Lender>
                );
              })}
          </InfiniteScroll>
        </div>
        {lendersData && lendersData.length === 0 && !loading && (
          <div className="mt-20 flex flex-col items-center justify-center gap-2">
            <img width={110} height={110} src="/Images/search.png"></img>
            <span className="font-Segoe text-2xl text-blue">
              No Results Found
            </span>
            <span className="font-Segoe text-base text-blue">
              No Results found with your search criteria. Try again
            </span>
            <ResetFilter
              fontSize="14px"
              reset={resetFilter}
              icon={null}
            ></ResetFilter>
          </div>
        )}
        <MoreFilters
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setFilters={setFilters}
          filters={filters}
          resetState={reset}
          setReset={setReset}
        />
        <ConfirmReset
          isOpen={isOpenReset}
          setIsOpen={setIsOpenReset}
          reset={resetFilter}
        ></ConfirmReset>
      </div>
    </>
  );
}
