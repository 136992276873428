import { gql } from '@apollo/client';

export const EDIT_PROFILE = gql`
  mutation editProfile(
    $id: ID!
    $phone: String
    $address: String
    $state: String
    $city: String
    $zipcode: String
  ) {
    editProfile(
      id: $id
      phone: $phone
      address: $address
      state: $state
      city: $city
      zipcode: $zipcode
    ) {
      status
      error
    }
  }
`;

export const EDIT_USER = gql`
  mutation editUser($id: ID!, $role: String!) {
    editUser(id: $id, role: $role) {
      status
      error
    }
  }
`;

export const COMPARE_SINGLE = gql`
  mutation singleScrub($input: String!, $type: String!) {
    compareSingle(input: $input, type: $type) {
      clean
      status
      phone
      name
    }
  }
`;

export const ADD_CONTACT = gql`
  mutation addContact($name: String!, $email: String!, $phone: String!) {
    addContact(name: $name, email: $email, phone: $phone) {
      id
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadFile($upload: Upload!) {
    singleUpload(file: $upload) {
      uuid
      preview
      ext
    }
  }
`;

export const COMPARE_FILE = gql`
  mutation compareFile(
    $id: ID!
    $phone: Int!
    $phone2: Int
    $phone3: Int
    $phone4: Int
    $first_name: Int
    $last_name: Int
    $email: Int
    $compareName: Boolean
    $states: [String]
    $federalDnc: Boolean
    $tcpa: Boolean
    $dncComplainers: Boolean
    $exportedColumns: [Int]
    $keepHeaderColumns: [Int]
    $outputFileFormat: String
    $groupByPerson: Boolean
  ) {
    compareFile(
      id: $id
      phone: $phone
      phone2: $phone2
      phone3: $phone3
      phone4: $phone4
      first_name: $first_name
      last_name: $last_name
      email: $email
      compareName: $compareName
      states: $states
      federalDnc: $federalDnc
      tcpa: $tcpa
      dncComplainers: $dncComplainers
      exportedColumns: $exportedColumns
      keepHeaderColumns: $keepHeaderColumns
      outputFileFormat: $outputFileFormat
      groupByPerson: $groupByPerson
    ) {
      ok
    }
  }
`;

export const ADD_CONTACTS_FILE = gql`
  mutation AddContacts($id: ID!, $phone: Int!, $name: Int, $email: Int) {
    addContacts(id: $id, phone: $phone, name: $name, email: $email) {
      addedCount
      duplicateCount
      invalidCount
      invalidNumbers {
        phone
        email
        name
      }
    }
  }
`;

export const NEW_REVIEW = gql`
  mutation newReview(
    $id: String!
    $rating: Int!
    $user_id: String!
    $message: String
    $user_picture: String
  ) {
    newReview(
      id: $id
      rating: $rating
      user_id: $user_id
      message: $message
      user_picture: $user_picture
    ) {
      status
      error
    }
  }
`;

export const UPLOAD_IMAGE = gql`
  mutation uploadImage($file: Upload!) {
    uploadImage(file: $file)
  }
`;

export const CREATE_EMPTY_LENDER = gql`
  mutation createEmptyLender {
    createEmptyLender {
      id
    }
  }
`;

export const UPDATE_LENDER = gql`
  mutation updateLender(
    $id: String!
    $name: String
    $lender_picture: String
    $short_description: String
    $more_about: String
    $featured: Boolean
    $website: String
    $states: [String]
    $loan_types: [String]
    $no_fico: Boolean
    $fha_minimum_fico: Int
    $va_minimum_fico: Int
    $lpc_points: Int
    $lpc_flat_fee: Int
    $bpc_conditions_unit: String
    $lender_price: Boolean
    $epo_days: Int
    $comp_types: [String]
    $bpc_conditions_value: Float
    $lock_n_shop: Boolean
    $manual_uw: Boolean
    $tdb_underwrite: Boolean
    $loan_sifter: Boolean
    $epo_policy: String
    $broker_id: String
    $revenue_notes: String
    $fha: Boolean
    $va: Boolean
    $fees: String
    $mortgagee_clause: String
    $cpl_clause: String
    $business_main_address: String
    $fha_id: Int
    $va_id: Int
    $manual_uw_fha_score: Int
  ) {
    updateLender(
      id: $id
      name: $name
      lender_picture: $lender_picture
      short_description: $short_description
      more_about: $more_about
      featured: $featured
      website: $website
      states: $states
      loan_types: $loan_types
      no_fico: $no_fico
      fha_minimum_fico: $fha_minimum_fico
      va_minimum_fico: $va_minimum_fico
      lpc_points: $lpc_points
      lpc_flat_fee: $lpc_flat_fee
      bpc_conditions_unit: $bpc_conditions_unit
      bpc_conditions_value: $bpc_conditions_value
      lock_n_shop: $lock_n_shop
      manual_uw: $manual_uw
      tdb_underwrite: $tdb_underwrite
      loan_sifter: $loan_sifter
      comp_types: $comp_types
      epo_days: $epo_days
      lender_price: $lender_price
      epo_policy: $epo_policy
      broker_id: $broker_id
      revenue_notes: $revenue_notes
      fha: $fha
      va: $va
      fees: $fees
      mortgagee_clause: $mortgagee_clause
      cpl_clause: $cpl_clause
      business_main_address: $business_main_address
      fha_id: $fha_id
      va_id: $va_id
      manual_uw_fha_score: $manual_uw_fha_score
    ) {
      status
      error
    }
  }
`;

export const UPDATE_LENDERS = gql`
  mutation updateLenders($lenders: [LenderTableBulk!]!) {
    updateLenders(lenders: $lenders) {
      status
      error
    }
  }
`;

export const UPDATE_OR_CREATE_ACCEXECUTOR = gql`
  mutation updateOrCreateAccExecutor(
    $id: String!
    $full_name: String!
    $phone: String!
    $email: String!
    $lender_id: String!
    $selected: Boolean
  ) {
    updateOrCreateAccExecutor(
      id: $id
      full_name: $full_name
      phone: $phone
      email: $email
      lender_id: $lender_id
      selected: $selected
    ) {
      status
      error
    }
  }
`;

export const DELETE_ACCEXECUTOR = gql`
  mutation deleteAccExecutor($id: String!) {
    deleteAccExecutor(id: $id) {
      status
      error
    }
  }
`;
export const DELETE_LENDER = gql`
  mutation deleteLender($id: String!) {
    deleteLender(id: $id) {
      status
      error
    }
  }
`;

export const CREATE_LENDER = gql`
  mutation createLender(
    $name: String
    $lender_picture: String
    $short_description: String
    $more_about: String
    $featured: Boolean
    $website: String
    $lender_price: Boolean
    $epo_days: Int
    $comp_types: [String]
    $states: [String]
    $loan_types: [String]
    $no_fico: Boolean
    $fha_minimum_fico: Int
    $va_minimum_fico: Int
    $lpc_points: Int
    $lpc_flat_fee: Int
    $bpc_conditions_unit: String
    $bpc_conditions_value: Float
    $lock_n_shop: Boolean
    $manual_uw: Boolean
    $tdb_underwrite: Boolean
    $loan_sifter: Boolean
    $epo_policy: String
    $broker_id: String
  ) {
    createLender(
      name: $name
      comp_types: $comp_types
      epo_days: $epo_days
      lender_price: $lender_price
      lender_picture: $lender_picture
      short_description: $short_description
      more_about: $more_about
      featured: $featured
      website: $website
      states: $states
      loan_types: $loan_types
      no_fico: $no_fico
      fha_minimum_fico: $fha_minimum_fico
      va_minimum_fico: $va_minimum_fico
      lpc_points: $lpc_points
      lpc_flat_fee: $lpc_flat_fee
      bpc_conditions_unit: $bpc_conditions_unit
      bpc_conditions_value: $bpc_conditions_value
      lock_n_shop: $lock_n_shop
      manual_uw: $manual_uw
      tdb_underwrite: $tdb_underwrite
      loan_sifter: $loan_sifter
      epo_policy: $epo_policy
      broker_id: $broker_id
    ) {
      id
    }
  }
`;

export const UPDATE_LOAN = gql`
  mutation updateLoan($id: String!, $text1: String, $text2: String) {
    updateLoan(id: $id, text1: $text1, text2: $text2) {
      status
      error
    }
  }
`;
