export function getTextFromHTML(html?: string) {
  if (!html) {
    return '';
  }
  const divContainer = document.createElement('div');
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || '';
}

export const getNewLender = (id: string) => ({
  id,
  account_executors: [],
  average_review_score: null,
  bpc_conditions_unit: '$',
  bpc_conditions_value: 0,
  broker_id: '',
  business_main_address: null,
  cpl_clause: null,
  email: null,
  epo_policy: '',
  featured: false,
  fees: null,
  fha: false,
  fha_minimum_fico: null,
  lender_picture: '/Images/default-lender.svg',
  loan_sifter: false,
  loan_types: [],
  lock_n_shop: false,
  lpc_flat_fee: 0,
  lpc_points: 0,
  manual_uw: false,
  more_about: null,
  mortgagee_clause: null,
  name: '',
  no_fico: false,
  phone: null,
  revenue_notes: null,
  short_description: null,
  states: [],
  tdb_underwrite: false,
  total_votes: null,
  va: false,
  va_minimum_fico: null,
  website: '',
});
