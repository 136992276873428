import '../../App.css';

import { useQuery } from '@apollo/client';
import { Pagination } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { USERS_PER_PAGE } from '../../config/constants';
import { GET_ALL_USERS, GET_USERS_COUNT } from '../../graphql/queries';
import { roles } from '../state/atoms';
import UsersTable from '../Tables/UsersTable';
import H1 from '../Texts/H1';
// import NavigationCard from "../Cards/NavigationCard";
import H2 from '../Texts/H2';
import Paragraph from '../Texts/Paragraph';

export default function Users() {
  const [users, setUsers] = useState([]);
  const [activePage, setPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const allRoles = useRecoilValue(roles);

  const { loading: loadingPagination } = useQuery(GET_USERS_COUNT, {
    onCompleted: (data) =>
      setPageTotal(Math.ceil(data.usersCount / USERS_PER_PAGE)),
  });

  const { refetch, loading: loadingUsers } = useQuery(GET_ALL_USERS, {
    notifyOnNetworkStatusChange: true,
    variables: { limit: USERS_PER_PAGE, offset: 0 },
    onCompleted: (data) => {
      setUsers(data.users);
    },
  });

  useEffect(() => {
    refetch({ offset: (activePage - 1) * USERS_PER_PAGE });
  }, [activePage]);

  const getRole = (role: string) => {
    if (roles) {
      const r = allRoles.find((ro: any) => ro.id === role);
      return r ? r.name : '-';
    }
    return '-';
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex">
          <H2 text="Our" fontSize="32px" fontWeight={400} />
          <div className="pl-2">
            <H1 text="Users" fontSize="32px" />
          </div>
        </div>
      </div>
      <div className="pt-2">
        <Paragraph text="On this page you can compare your phone numbers with our database to find any DNC/TCPA numbers" />
      </div>
      <div className="pt-4">
        <UsersTable
          titleOptions={[
            { text: 'Full Name', fontSize: '14px' },
            { text: 'Email', fontSize: '14px' },
            { text: 'Role', fontSize: '14px' },
          ]}
          elements={
            users.length === 0
              ? []
              : users.map((u: any) => ({
                  email: u.email,
                  name: `${u.first_name} ${u.last_name || ''}`,
                  role: getRole(u.role),
                  id: u.id,
                }))
          }
          loading={loadingUsers}
        />
      </div>
      {!loadingPagination && users.length > 0 && (
        <div className="flex justify-center pt-5" style={{}}>
          <Pagination
            page={activePage}
            onChange={setPage}
            total={pageTotal}
            color="gray"
            size="sm"
            radius="md"
          />
        </div>
      )}
    </>
  );
}
