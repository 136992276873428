import '../../App.css';

// import ProfileEditingTable from '../Tables/ProfileEditingTable';
import { useMutation, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from 'react-hook-form';

import { EDIT_PROFILE } from '../../graphql/mutations';
import { GET_USER_AUTH0 } from '../../graphql/queries';
import ProfileEditingForm from '../Forms/ProfileEditingForm';
import ProfileGrid from '../Grids/ProfileGrid';
import LoaderOverlay from '../Overlays/LoaderOverlay';
import H1 from '../Texts/H1';
import H2 from '../Texts/H2';

interface ProfileForm {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  state: string;
  zipcode: string;
  address: string;
  city: string;
}

export default function Profile() {
  const {
    reset,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileForm>();
  const { user } = useAuth0();
  const { loading } = useQuery(GET_USER_AUTH0, {
    variables: {
      id: user?.sub || '',
    },
    onCompleted: (data) => {
      reset(data.userAuth0);
    },
  });

  // eslint-disable-next-line no-empty-pattern
  const [editProfile, {}] = useMutation(EDIT_PROFILE);

  const onSubmit = (data: ProfileForm) => {
    editProfile({ variables: { ...data } });
  };

  const l = watch();

  return (
    <>
      <LoaderOverlay loading={loading} />
      <div className="flex justify-between">
        <div className="flex">
          <H2 text="Your" fontSize="32px" fontWeight={400} />
          <div className="pl-2">
            <H1 text="Profile" fontSize="32px" />
          </div>
        </div>
      </div>
      <div className="py-2">
        {/* <Paragraph text='Breve Explicación de la funcionalidad de esta sección'/> */}
      </div>
      <div className="pt-8">
        <ProfileGrid
          rowsInfo1={[
            { title: 'First name', text: l.first_name || '' },
            { title: 'Last name', text: l.last_name || '' },
            { title: 'Email', text: l.email || '' },
          ]}
        />
        <form onSubmit={handleSubmit(onSubmit)} className="pt-5">
          <ProfileEditingForm
            rowsInfo2={[
              {
                title: 'Phone Number',
                register: {
                  ...register('phone', {
                    required: true,
                    validate: (val) => val.length >= 9,
                  }),
                },
                value: l.phone || '-',
                error: errors.phone,
              },
              {
                title: 'State',
                register: { ...register('state') },
                value: l.state || '-',
                error: errors.state,
              },
              {
                title: 'Zipcode',
                register: {
                  ...register('zipcode', {
                    validate: (val) => val.length !== 5,
                  }),
                },
                value: l?.zipcode || '-',
                error: errors.zipcode,
              },
              {
                title: 'Address',
                register: { ...register('address') },
                value: l.address || '-',
                error: errors.address,
              },
              {
                title: 'City',
                register: { ...register('city') },
                value: l.city || '-',
                error: errors.city,
              },
            ]}
          />
        </form>
      </div>
    </>
  );
}
