export const USERS_PER_PAGE = 10;
export const SEARCH_MASS_LOGS_PER_PAGE = 10;
export const LOGS_PER_PAGE = 20;
export const LOANS_PER_PAGE = 10;
export const GET_FILE_PATH = process.env.REACT_APP_GET_FILE_API_PATH;
export const LOG_TYPE_PRETIFY = {
  search_mass: 'Bulk Search',
  search_single: '  Single Search',
  add_mass: '  Add Bulk Phones',
  add_single: '  Add Single Phone',
};

export const STATES = [
  { label: 'Alabama', value: 'Alabama' },
  { label: 'Alaska', value: 'Alaska' },
  { label: 'Arizona', value: 'Arizona' },
  { label: 'Arkansas', value: 'Arkansas' },
  { label: 'California', value: 'California' },
  { label: 'Colorado', value: 'Colorado' },
  { label: 'Connecticut', value: 'Connecticut' },
  { label: 'Delaware', value: 'Delaware' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Georgia', value: 'Georgia' },
  { label: 'Hawaii', value: 'Hawaii' },
  { label: 'Idaho', value: 'Idaho' },
  { label: 'Illinois', value: 'Illinois' },
  { label: 'Indiana', value: 'Indiana' },
  { label: 'Iowa', value: 'Iowa' },
  { label: 'Kansas', value: 'Kansas' },
  { label: 'Kentucky', value: 'Kentucky' },
  { label: 'Louisiana', value: 'Louisiana' },
  { label: 'Maine', value: 'Maine' },
  { label: 'Maryland', value: 'Maryland' },
  { label: 'Massachusetts', value: 'Massachusetts' },
  { label: 'Michigan', value: 'Michigan' },
  { label: 'Minnesota', value: 'Minnesota' },
  { label: 'Mississippi', value: 'Mississippi' },
  { label: 'Missouri', value: 'Missouri' },
  { label: 'Montana', value: 'Montana' },
  { label: 'Nebraska', value: 'Nebraska' },
  { label: 'Nevada', value: 'Nevada' },
  { label: 'New Hampshire', value: 'New Hampshire' },
  { label: 'New Jersey', value: 'New Jersey' },
  { label: 'New Mexico', value: 'New Mexico' },
  { label: 'New York', value: 'New York' },
  { label: 'North Carolina', value: 'North Carolina' },
  { label: 'North Dakota', value: 'North Dakota' },
  { label: 'Ohio', value: 'Ohio' },
  { label: 'Oklahoma', value: 'Oklahoma' },
  { label: 'Oregon', value: 'Oregon' },
  { label: 'Pennsylvania', value: 'Pennsylvania' },
  { label: 'Rhode Island', value: 'Rhode Island' },
  { label: 'South Carolina', value: 'South Carolina' },
  { label: 'South Dakota', value: 'South Dakota' },
  { label: 'Tennessee', value: 'Tennessee' },
  { label: 'Texas', value: 'Texas' },
  { label: 'Utah', value: 'Utah' },
  { label: 'Vermont', value: 'Vermont' },
  { label: 'Virginia', value: 'Virginia' },
  { label: 'Washington', value: 'Washington' },
  { label: 'West Virginia', value: 'West Virginia' },
  { label: 'Wisconsin', value: 'Wisconsin' },
  { label: 'Wyoming', value: 'Wyoming' },
];

export const LOAN_TYPES = [
  { label: '203K', value: '203K' },
  { label: '40 year terms', value: '40 year terms' },
  { label: 'Asset Depletion', value: 'Asset Depletion' },
  { label: 'Bank Statement/Non QM', value: 'Bank Statement/Non QM' },
  { label: 'Bridge Loan', value: 'Bridge Loan' },
  { label: "Church's", value: "Church's" },
  { label: 'Close in LLC', value: 'Close in LLC' },
  { label: 'Commercial', value: 'Commercial' },
  { label: 'Construction', value: 'Construction' },
  { label: 'DACA', value: 'DACA' },
  { label: 'Doctors Loans', value: 'Doctors Loans' },
  { label: 'DPA', value: 'DPA' },
  { label: 'DSCR', value: 'DSCR' },
  { label: 'Farm/Ranch', value: 'Farm/Ranch' },
  { label: 'FHA', value: 'FHA' },
  { label: 'FHA Spot Appls', value: 'FHA Spot Appls' },
  { label: 'Fix N Flip', value: 'Fix N Flip' },
  { label: 'Foreign Nat', value: 'Foreign Nat' },
  { label: 'Hard Money', value: 'Hard Money' },
  { label: 'HELOC', value: 'HELOC' },
  { label: 'Hobby Farm', value: 'Hobby Farm' },
  { label: 'I/O', value: 'I/O' },
  { label: 'Investor', value: 'Investor' },
  { label: 'ITIN', value: 'ITIN' },
  { label: 'Jumbo', value: 'Jumbo' },
  { label: 'Land/Lot Loan', value: 'Land/Lot Loan' },
  { label: 'Leasehold', value: 'Leasehold' },
  { label: 'Lock-N-Shop', value: 'Lock-N-Shop' },
  { label: 'Manual UW', value: 'Manual UW' },
  { label: 'Manufactured', value: 'Manufactured' },
  { label: 'Mixed Use', value: 'Mixed Use' },
  { label: 'NO FICO', value: 'NO FICO' },
  { label: 'Non-Warr Condo', value: 'Non-Warr Condo' },
  { label: 'Piggy Back', value: 'Piggy Back' },
  { label: 'Renovation', value: 'Renovation' },
  { label: 'Reverse', value: 'Reverse' },
  { label: 'SBA', value: 'SBA' },
  { label: 'Second Leins', value: 'Second Leins' },
  { label: 'Stated Loan', value: 'Stated Loan' },
  { label: 'Texas 50(a)(6)', value: 'Texas 50(a)(6)' },
  { label: 'USDA', value: 'USDA' },
  { label: 'VA', value: 'VA' },
];

export const LOANS_STATUS = [
  { name: 'Loan Setup', canceledWidth: 'min-w-[59.281px] w-full', status: 0 },
  { name: 'Disclosed', canceledWidth: 'min-w-[50.328px] w-full', status: 1 },
  {
    name: 'Submitted to UW',
    canceledWidth: 'min-w-[91.875px] w-full',
    status: 2,
  },
  {
    name: 'Approved with conditions',
    canceledWidth: '3xl:min-w-[136.156px] min-w-[113.469px]  w-full',
    status: 3,
  },
  {
    name: 'Re-Subtmittal',
    canceledWidth: 'min-w-[72.594px] w-full',
    status: 4,
  },
  {
    name: 'Clear to Close',
    canceledWidth: 'min-w-[73.203px] w-full',
    status: 5,
  },
  { name: 'Docs Out', canceledWidth: 'min-w-[49.281px] w-full', status: 6 },
  { name: 'Docs Signed', canceledWidth: 'min-w-[65.859px] w-full', status: 7 },
  { name: 'Loan Funded', canceledWidth: 'min-w-[68.563px] w-full', status: 8 },
];

export const CANCELED_STATUS = { name: 'Canceled', status: -1 };
