import { Button } from '@mantine/core';
import type { ReactNode } from 'react';

import ResetIcon from '../Icons/Reset';
import BtnText from '../Texts/BtnText';

interface ResetFilterProps {
  fontSize: string;
  className?: string;
  icon?: ReactNode;
  reset: () => void;
}

export default function ResetFilter({
  fontSize,
  className = 'bg-[#AE0000] text-white h-[31px] hover:bg-[#27abe2]',
  icon = <ResetIcon /> || null,
  reset,
}: ResetFilterProps) {
  return (
    <Button
      id="ResetButtonFilter"
      leftIcon={icon}
      className={className}
      radius="lg"
      size="md"
      onClick={reset}
    >
      <BtnText text="Reset Filters" fontSize={`${fontSize}`} />
    </Button>
  );
}
