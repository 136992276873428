interface IframeProps {
  title: string;
  iframeSrc: string;
}

export default function Iframe({ title, iframeSrc }: IframeProps) {
  return (
    <div>
      <p className="font-Segoe text-blue text-xl">{title}</p>
      <iframe
        src={iframeSrc}
        className="block w-full lg:h-[700px] h-[550px]"
      ></iframe>
    </div>
  );
}
