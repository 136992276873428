import { MultiSelect } from '@mantine/core';

import { STATES } from '../../config/constants';
import DownArrowIcon from '../Icons/DownArrow';

interface DataValue {
  label: string;
  value: string;
}

interface LenderMultiProps {
  className?: string;
  data?: DataValue[];
  placeholder?: string;
  info?: string[];
  setInfo?: React.Dispatch<React.SetStateAction<string[]>>;
  createable?: boolean;
  edit?: boolean;
  id?: string;
}

const dataValues = STATES;

export default function LenderMultiEdit({
  className = '!min-w-[215px]',
  data = dataValues,
  placeholder = 'Loan Types',
  info,
  setInfo,
  createable = false,
  edit = false,
  id = undefined,
}: LenderMultiProps) {
  return (
    <MultiSelect
      id={id}
      data={data}
      value={info || []}
      valueComponent={() => <></>}
      onChange={setInfo}
      radius="md"
      creatable={createable}
      searchable
      getCreateLabel={(query) => `+ Create ${query}`}
      rightSection={<DownArrowIcon />}
      maxSelectedValues={edit ? 999 : 1}
      placeholder={placeholder}
      className={className}
      styles={{
        input: {
          border: '1px solid #27ABE2',
          height: '36px !important',
        },
      }}
    />
  );
}
