import '../../App.css';

import { useMutation } from '@apollo/client';
import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';

import {
  DELETE_ACCEXECUTOR,
  UPDATE_OR_CREATE_ACCEXECUTOR,
} from '../../graphql/mutations';
import IconBtn from '../Buttons/IconBtn';
import DeleteIcon from '../Icons/Delete';
import PlusOpaqueIcon from '../Icons/PlusOpaque';
import SaveIcon from '../Icons/Save';
// import PopUp from '../Overlays/PopUp';
import { AccountExecutorsColumns } from '../Tables/columns/AccountExecutorsColumns';
import DetailTable from '../Tables/DetailTable';
import H1 from '../Texts/H1';

type AccountExecutor = {
  id: string;
  full_name: string;
  phone: string;
  email: string;
};

export type AccountExecutorsPopupData = {
  id: string;
  name: string;
  account_executors: AccountExecutor[];
};

type AccountExecutorsPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (new_acc_ex: any[]) => Promise<void>;
  data: AccountExecutorsPopupData | null;
};

const AccountExecutorsPopup = ({
  isOpen,
  onClose,
  onSave,
  data,
}: AccountExecutorsPopupProps) => {
  const [title, setTitle] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState<string | null>(null);
  const [updateOrCreateAccExecutor] = useMutation(UPDATE_OR_CREATE_ACCEXECUTOR);
  const [deleteAcExecutor, { loading: loadingDeleteAcc }] =
    useMutation(DELETE_ACCEXECUTOR);
  const [accountExecutors, setAccountExecutors] = useState<AccountExecutor[]>(
    data?.account_executors || []
  );
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setAccountExecutors(data?.account_executors || []);
  }, [data?.account_executors]);

  const handleClosePopup = () => {
    onClose();
  };

  const handleNewAccountExecutor = () => {
    const newAccountExecutor = {
      id: `new-lender${Math.random()}`,
      full_name: '',
      phone: '',
      email: '',
      lender_id: data?.id || '',
    };
    setAccountExecutors([...accountExecutors, newAccountExecutor]);
  };

  const handleSaveAccountExecutors = async () => {
    if (saving) return;
    setSaving(true);
    accountExecutors.forEach(async (accountExecutor) => {
      await updateOrCreateAccExecutor({
        variables: {
          ...accountExecutor,
        },
      });
    });
    await onSave(accountExecutors);
    setSaving(false);
    onClose();
  };

  const onClickDelete = (id: string) => {
    setIsOpenDelete(id);
  };

  const handleDelete = async () => {
    if (!isOpenDelete?.includes('new-lender'))
      await deleteAcExecutor({ variables: { id: isOpenDelete } });
    const newAccEx = [...accountExecutors.filter((a) => a.id !== isOpenDelete)];
    setAccountExecutors(newAccEx);
    setIsOpenDelete(null);
    await onSave(newAccEx);
  };

  useEffect(() => {
    if (data?.name) setTitle(`${data?.name} - Account Executives`);
  }, [data]);

  return (
    <>
      <Modal opened={isOpen} onClose={handleClosePopup} size="auto" centered>
        <div id="filterDialog" className="">
          <div className="m-5 w-[800px]">
            <div className="flex flex-col gap-2 w-full">
              <div className="flex flex-row justify-between">
                <H1 text={title} fontSize="24px" />
                <IconBtn
                  btnText="Save"
                  onClick={handleSaveAccountExecutors}
                  loading={saving}
                  id="SubmitBtn"
                  fontSize="16px"
                  icon={<SaveIcon />}
                  className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
                />
              </div>
              <DetailTable
                columns={AccountExecutorsColumns(onClickDelete)}
                data={accountExecutors}
                edit
              />
            </div>
            <div className="mt-5 flex flex-row justify-center gap-3">
              <IconBtn
                btnText="Add an Account Executive"
                fontSize="16px"
                icon={<PlusOpaqueIcon />}
                onClick={handleNewAccountExecutor}
                className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        opened={!!isOpenDelete}
        onClose={() => setIsOpenDelete(null)}
        size={'auto'}
        centered
      >
        <div id="filterDialog" className="">
          <div className="m-5">
            <div className="flex max-w-[600px] flex-col gap-2 text-center">
              <span className="font-Segoe text-xl font-bold text-blue">
                Are you sure you want to delete the selected account executor?
              </span>
              <span className="font-Segoe text-base text-gray">
                This action cannot be undone, and all associated data and
                records will be permanently removed.
              </span>
            </div>
            <div className="mt-5 flex flex-row items-center justify-end gap-3">
              <IconBtn
                btnText="Cancel"
                fontSize="16px"
                onClick={() => setIsOpenDelete(null)}
                className="rounded-[10px] bg-blue hover:bg-skyBlue font-Segoe"
              />
              <IconBtn
                btnText="Delete"
                fontSize="16px"
                onClick={handleDelete}
                icon={<DeleteIcon />}
                loading={loadingDeleteAcc}
                className="rounded-[10px] bg-[#AE0000] font-Segoe text-white hover:bg-blue"
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AccountExecutorsPopup;
