interface IEditOpaqueIcon {
  className?: string;
}

const EditOpaqueIcon = ({ className = 'fill-current' }: IEditOpaqueIcon) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0833 0.75C10.3928 0.75 10.6895 0.872916 10.9083 1.09171C11.1271 1.3105 11.25 1.60725 11.25 1.91667V10.0833C11.25 10.3928 11.1271 10.6895 10.9083 10.9083C10.6895 11.1271 10.3928 11.25 10.0833 11.25H1.91667C1.60725 11.25 1.3105 11.1271 1.09171 10.9083C0.872916 10.6895 0.75 10.3928 0.75 10.0833V1.91667C0.75 1.60725 0.872916 1.3105 1.09171 1.09171C1.3105 0.872916 1.60725 0.75 1.91667 0.75H10.0833ZM8.74167 4.45417C8.87 4.33167 8.87 4.1275 8.74167 4.005L7.995 3.25833C7.96602 3.22794 7.93117 3.20375 7.89257 3.18722C7.85397 3.17069 7.81241 3.16217 7.77042 3.16217C7.72842 3.16217 7.68687 3.17069 7.64826 3.18722C7.60966 3.20375 7.57481 3.22794 7.54583 3.25833L6.9625 3.84167L8.15833 5.0375L8.74167 4.45417ZM3.08333 7.715V8.91667H4.285L7.82 5.38167L6.61833 4.18L3.08333 7.715Z"
        className={className}
      />
    </svg>
  );
};

export default EditOpaqueIcon;
