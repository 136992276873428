export default (params: any) => {
  return (
    <a
      className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
      href={params.value}
      target="_blank"
    >
      {params.value}
    </a>
  );
};
