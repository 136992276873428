import { Tooltip } from '@mantine/core';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type { LoanPartial, LoanStatus } from '@/types/loans';

import { LOANS_STATUS } from '../../config/constants';

type StatusCardStepProps = {
  name: string;
  hover: boolean;
  first?: boolean;
  last?: boolean;
  date?: string;
  finished?: boolean;
  canceled?: boolean;
  active?: boolean;
  data?: LoanStatus;
  canceledWidth: string;
  skipped?: boolean;
  canceledData?: string;
  dayDiff?: number;
};

const StatusCardStep = ({
  name,
  hover,
  first,
  last,
  canceled,
  data,
  canceledWidth,
  skipped,
  canceledData,
  dayDiff,
}: StatusCardStepProps) => {
  const getBgColor = () => {
    if (canceled) return '!bg-[#AE0000]';
    if (data?.ended) return '!bg-[#55A166]';
    if (data && !data?.ended) return '!bg-blue';
    if (skipped) return 'bg-[#626262]';
    return '!bg-[#dff1ff] !text-transparent';
  };

  const getTextColor = () => {
    if (canceled) return '!text-[#AE0000]';
    if (data?.ended) return '!text-[#55A166]';
    if (data && !data?.ended) return '!text-blue';
    if (skipped) return 'text-[#626262]';
    return '!text-[#dff1ff]';
  };

  return (
    <Tooltip
      multiline
      width={100}
      label={`${dayDiff} day${dayDiff !== 1 ? 's' : ''}`}
      transition="fade"
      transitionDuration={200}
      radius="md"
      styles={{
        tooltip: {
          color: 'white !important',
          textAlign: 'center',
          marginLeft: '-10px',
          display: dayDiff !== undefined ? '' : 'none',
        },
      }}
    >
      <div className="flex flex-col relative mb-5 2xl:w-full">
        {/* <Avatar src={'/svg/info.svg'} size={16} /> */}
        <div
          className={`flex flex-row items-center ${
            !first && '-ml-5 3xl:-ml-4'
          }`}
        >
          {!first && (
            <div
              className={`${getBgColor()} h-[48px] flex items-center w-[24px] z-[1]`}
            >
              <div
                className={`rotate-180 2xl:flex-none !h-[48px] w-[24px] ${
                  hover ? '!text-[#E6E6E6]' : '!text-white'
                }`}
              >
                <svg viewBox="0 0 5 10">
                  <path
                    className="fill-current"
                    d="M5 0 V10 L0.8 5.8 Q0 5 0.8 4.2z"
                  />
                </svg>
              </div>
            </div>
          )}
          <div
            className={`px-2 2xl:w-full 2xl:text-center ${getBgColor()} text-[10px] 3xl:text-[11px] h-[48px] flex items-center text-white ${
              first && 'pl-5 rounded-l-md'
            } ${last && 'pr-5 rounded-r-md'}`}
          >
            <p className={`${canceled && 'hidden'} w-full`}>{name}</p>
            {canceled && (
              <p className={`${canceledWidth} text-center pr-2`}>Canceled</p>
            )}
          </div>
          {/* {!last && (
          <div className={`triangle right ${getBgColor()} z-[2]`}></div>
        )} */}
          {!last && (
            <div
              className={`rotate-180 2xl:flex-none !h-[48px] w-[24px] z-[2] ${getTextColor()}`}
            >
              <svg viewBox="0 0 5 10">
                <path
                  className="fill-current"
                  d="M5 0 V10 L0.8 5.8 Q0 5 0.8 4.2z"
                />
              </svg>
            </div>
          )}
        </div>
        <div>
          {data?.start_date && (
            <div
              className={`flex flex-row text gap-x-1 absolute -bottom-5 ${
                first ? 'left-0' : '-left-3'
              }`}
            >
              <img
                src="/svg/date-loan-status.svg"
                width={16}
                height={16}
                alt="lender icon"
              />
              <span className="text-blue 2xl:text-[12px] text-[10px] font-bold">
                {dayjs(data?.start_date).format('MM/DD/YY')}
              </span>
            </div>
          )}
          {skipped && (
            <div
              className={`flex flex-row text gap-x-1 absolute -bottom-5 -left-3`}
            >
              <span className="text-[#626262] 2xl:text-[12px] text-[10px] font-bold">
                Skipped
              </span>
            </div>
          )}
        </div>

        {canceledData && (
          <div
            className={`flex text-[#AE0000] flex-row text gap-x-1 absolute -bottom-5 ${
              first ? 'left-0' : '-left-3'
            }`}
          >
            <img
              src="/svg/date-loan-status-canceled.svg"
              width={16}
              height={16}
              alt="lender icon"
            />
            <span className="text-[#AE0000] 2xl:text-[12px] text-[10px] font-bold">
              {dayjs(canceledData).format('MM/DD/YY')}
            </span>
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export const LoanStatusCard = ({
  id,
  arive_id,
  borrower_names,
  processor_names,
  lo_name,
  investor_names,
  locked,
  statuses,
}: LoanPartial) => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const getCompletedDate = () => {
    return statuses.find((s) => s.status === 8)?.end_date;
  };
  return (
    <div
      onClick={() => navigate(`/loan-status/${id}`)}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={`flex flex-row w-full gap-x-2  3xl:gap-x-4 cursor-pointer rounded-md ${
        hover && 'bg-[#E6E6E6]'
      }`}
    >
      <div
        className={`flex flex-col text-[14px] 2xl:w-[200px] text-blue w-[160px] 3xl:max-w-none  bg-white rounded-md px-3 py-2 relative drop-shadow-[0_4px_20px_rgba(0,0,0,0.05)] ${
          hover && 'bg-[#D6D6D6]'
        }`}
      >
        <p className="text-[20px] font-SegoeBold">{arive_id}</p>
        <div className="font-SegoeBold">Borrower/s</div>
        <Tooltip
          multiline
          width={200}
          label={borrower_names.join(', ')}
          transition="fade"
          transitionDuration={200}
          radius="md"
          styles={{
            tooltip: {
              color: 'white !important',
              textAlign: 'center',
            },
          }}
        >
          <div className="">
            {borrower_names[0]}
            {borrower_names.length > 1 ? ` + ${borrower_names.length - 1}` : ''}
          </div>
        </Tooltip>
        <div className="flex flex-row gap-x-1">
          <Tooltip
            multiline
            width={200}
            label={lo_name}
            transition="fade"
            transitionDuration={200}
            radius="md"
            styles={{
              tooltip: {
                color: 'white !important',
                textAlign: 'center',
              },
            }}
          >
            <div className="flex flex-row gap-x-1">
              <img
                src="/svg/lender.svg"
                width={16}
                height={16}
                alt="lender icon"
              />

              <span className="loan-card-text">{lo_name}</span>
              {locked ? (
                <img
                  src="/svg/locked.svg"
                  width={16}
                  height={16}
                  alt="lender icon"
                />
              ) : (
                <img
                  src="/svg/unlocked.svg"
                  width={16}
                  height={16}
                  alt="lender icon"
                />
              )}
            </div>
          </Tooltip>
        </div>
        <Tooltip
          multiline
          width={200}
          label={investor_names.join(', ')}
          transition="fade"
          transitionDuration={200}
          radius="md"
          styles={{
            tooltip: {
              color: 'white !important',
              textAlign: 'center',
              display: investor_names.length > 0 ? '' : 'none',
            },
          }}
        >
          <div className="flex flex-row  gap-x-1">
            <img
              src="/svg/investors.svg"
              width={16}
              height={16}
              alt="investor icon"
            />
            <span className="loan-card-text">{investor_names.join(', ')}</span>
          </div>
        </Tooltip>
      </div>
      <div className="flex flex-col justify-center 2xl:w-full min-w-[950px] 2xl:pr-2">
        <div className="flex flex-row self-end gap-x-5">
          <Tooltip
            multiline
            width={200}
            label={processor_names.join(', ')}
            transition="fade"
            transitionDuration={200}
            radius="md"
            styles={{
              tooltip: {
                color: 'white !important',
                textAlign: 'center',
                display: investor_names.length > 0 ? '' : 'none',
              },
            }}
          >
            <div className="flex flex-col xl:self-end xl:text-right">
              <p className="text-[12px]">Processor/s</p>
              <p className="text-[14px] -mt-1 mb-2">
                {processor_names[0]}
                {processor_names.length > 1
                  ? ` + ${processor_names.length - 1}`
                  : ''}
              </p>{' '}
            </div>
          </Tooltip>
          {getCompletedDate() && (
            <div className="flex flex-col xl:self-end xl:text-right">
              <p className="text-[12px]">Completed</p>
              <div className="flex flex-row justify-center gap-x-2 -mt-1 mb-2">
                <img
                  src="/svg/date-loan-status-done.svg"
                  width={16}
                  height={16}
                  alt="lender icon"
                />
                <p className="text-[14px] text-[#55A166]">
                  {dayjs(getCompletedDate()).format('MM/DD/YY')}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-row items-center overflow-visible">
          {LOANS_STATUS.map((s, index) => {
            const maxStatus = Math.max(...statuses.map((st) => st.status));
            const canceledData = statuses.find((st) => st.status === -1);
            const canceled = canceledData && maxStatus === index;
            const showCanceledDate =
              canceledData && maxStatus + 1 === index && index !== 0;
            const prev = statuses.find(
              (st) => st.status === LOANS_STATUS[index - 1]?.status
            );
            const next = statuses.find(
              (st) => st.status === LOANS_STATUS[index + 1]?.status
            );
            const current = statuses.find((st) => st.status === s.status);
            return (
              <StatusCardStep
                key={s.name}
                first={index === 0}
                hover={hover}
                name={s.name}
                last={index === LOANS_STATUS.length - 1}
                data={statuses.find((st) => st.status === s.status)}
                canceled={canceled}
                canceledWidth={s.canceledWidth}
                skipped={!current && !!prev && !!next}
                canceledData={
                  showCanceledDate ? canceledData.start_date : undefined
                }
                dayDiff={
                  current?.end_date && current.start_date
                    ? dayjs(current?.end_date).diff(current?.start_date, 'days')
                    : undefined
                }
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
