import { useRecoilValue } from 'recoil';

import { userRole } from '../components/state/atoms';
import { PERMISSION } from '../config/permissions';

export const usePermissions = () => {
  const userAuthority = useRecoilValue(userRole) as string;
  // Admin
  // Loan Officer
  // Loan Processor
  const isAdmin = () => {
    return userAuthority === PERMISSION.ADMIN;
  };
  const hasAdminPermissions = () => isAdmin();

  const isLoanOfficer = () => {
    return userAuthority === PERMISSION.LOAN_OFFICER;
  };
  const isLoanProcessor = () => {
    return userAuthority === PERMISSION.LOAN_PROCESSOR;
  };

  const hasLOPermissions = () => hasAdminPermissions() || isLoanOfficer();

  const hasLPPermissions = () => hasAdminPermissions() || isLoanProcessor();

  return {
    isAdmin,
    isLoanOfficer,
    hasAdminPermissions,
    hasLOPermissions,
    hasLPPermissions,
  };
};
