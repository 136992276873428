interface CrossIconProps {
  color?: string;
}

const CrossIcon = ({ color = 'white' }: CrossIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M9 9.5L5.25 5.75M9 9.5L12.75 13.25M9 9.5L12.75 5.75M9 9.5L5.25 13.25"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossIcon;
