import { Input } from '@mantine/core';

import SearchIcon from '../Icons/Search';
import type { FilterType } from '../pages/Lenders';

interface LenderInputProps {
  className?: string;
  filters: FilterType;
  setFilters: React.Dispatch<React.SetStateAction<FilterType>>;
}

export default function LenderInput({
  className = 'min-w-[300px]',
  filters,
  setFilters,
}: LenderInputProps) {
  return (
    <Input
      placeholder="Search Lender"
      radius="lg"
      id="SearchLender"
      value={filters.lenderName || ''}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setFilters({ ...filters, lenderName: event.target.value })
      }
      className={className}
      rightSection={<SearchIcon />}
      styles={{
        input: {
          border: '1px solid #27ABE2',
        },
      }}
    />
  );
}
