import { LoadingOverlay } from '@mantine/core';

interface ILoader {
  loading: boolean;
}

const LoaderOverlay = ({ loading }: ILoader) => {
  return <LoadingOverlay visible={loading} />;
};

export default LoaderOverlay;
