import { Loader, Table } from '@mantine/core';

import H1 from '../Texts/H1';

interface TitleOptionsProps {
  text: string;
  fontSize: string;
  accessor: string;
}

interface GeneralTableProps {
  titleOptions: TitleOptionsProps[];
  elements: any[];
  loading: boolean;
}

export default function GeneralTable({
  titleOptions,
  elements,
  loading,
}: GeneralTableProps) {
  const rows =
    elements.length > 0
      ? elements.map((element, i) => {
          const cells = titleOptions.map((h) => {
            return <td key={element[h.accessor]}>{element[h.accessor]}</td>;
          });
          return (
            <tr
              className="cursor-pointer hover:bg-[#E8F5FF]"
              onClick={() => element.onclick()}
              key={`${i}-log-row`}
            >
              {cells}
            </tr>
          );
        })
      : null;

  return (
    <Table className="w-full">
      <thead className="relative">
        <tr style={{ backgroundColor: '#E8F5FF' }}>
          {titleOptions.map((t, i) => {
            return (
              <th key={i}>
                <H1 text={t.text} fontSize={t.fontSize} />
              </th>
            );
          })}
        </tr>
      </thead>
      {loading ? (
        <tbody>
          <tr>
            <td colSpan={100} className="text-center">
              <div className="flex w-full justify-center py-10 pr-36">
                <Loader />
              </div>
            </td>
          </tr>
        </tbody>
      ) : (
        <tbody>{rows}</tbody>
      )}
    </Table>
  );
}
