import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Button, Image, Select, Text } from '@mantine/core';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronDown } from 'tabler-icons-react';

import { ROUTES } from '../../routes/config';

const data = [
  {
    label: 'Profile',
    value: 'Profile',
    image: '/svg/profile.svg',
    textColor: '#2BACE2',
    link: ROUTES.PROFILE,
  },

  {
    label: 'Logout',
    value: 'Logout',
    image: '/svg/logout.svg',
    textColor: '#FD0303',
    link: ROUTES.LOGOUT,
  },
];

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  image: string;
  textColor: string;
  link: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, link, textColor }: ItemProps) => (
    <Button
      variant="subtle"
      color="#E8F5FF"
      style={{ height: '56px' }}
      component={Link}
      to={link}
    >
      <div className="flex items-center justify-start gap-4">
        <Avatar src={image} size={18} />
        <div>
          <Text style={{ color: textColor, fontFamily: '', fontSize: '16px' }}>
            {label}
          </Text>
        </div>
      </div>
    </Button>
  )
);

export default function ProfileDD() {
  const { user } = useAuth0();
  return (
    <Select
      placeholder={user?.name || 'Welcome'}
      rightSection={<ChevronDown size={14} />}
      rightSectionWidth={30}
      styles={{
        rightSection: { pointerEvents: 'none' },
        input: {
          borderColor: 'transparent',
          '::placeholder': {
            color: '#656565',
            fontWeight: 600,
            paddingLeft: '20px',
          },
          ':focus': {
            borderColor: 'transparent',
          },
          ':focus-within': {
            borderColor: 'transparent',
          },
        },
      }}
      itemComponent={SelectItem}
      data={data}
      icon={<Image radius={'xl'} src={user?.picture} />}
      radius="xl"
      style={{
        width: `${(user?.name || 'Welcome').replace(/ /g, '').length + 10}ch`,
      }}
    />
  );
}
