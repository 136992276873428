interface ISelectedExecutorIcon {
  selected?: boolean;
}

const SelectedExecutorIcon = ({ selected = false }: ISelectedExecutorIcon) => {
  if (selected) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="18"
        viewBox="0 0 14 18"
        fill="none"
      >
        <path
          d="M0.25 2.25C0.25 1.65326 0.487053 1.08097 0.90901 0.65901C1.33097 0.237053 1.90326 0 2.5 0L11.5 0C12.0967 0 12.669 0.237053 13.091 0.65901C13.5129 1.08097 13.75 1.65326 13.75 2.25V17.4375C13.7499 17.5392 13.7223 17.6391 13.67 17.7264C13.6177 17.8136 13.5427 17.8851 13.453 17.9331C13.3633 17.9811 13.2623 18.0039 13.1606 17.9991C13.059 17.9942 12.9606 17.9619 12.8759 17.9055L7 14.7386L1.12412 17.9055C1.03942 17.9619 0.941009 17.9942 0.839378 17.9991C0.737747 18.0039 0.6367 17.9811 0.546999 17.9331C0.457299 17.8851 0.382305 17.8136 0.330003 17.7264C0.277702 17.6391 0.250053 17.5392 0.25 17.4375V2.25Z"
          fill="#223C78"
        />
        <path
          d="M7 4.5C7.14918 4.5 7.29226 4.55926 7.39775 4.66475C7.50324 4.77024 7.5625 4.91332 7.5625 5.0625V6.75H9.25C9.39918 6.75 9.54226 6.80926 9.64775 6.91475C9.75324 7.02024 9.8125 7.16332 9.8125 7.3125C9.8125 7.46168 9.75324 7.60476 9.64775 7.71025C9.54226 7.81574 9.39918 7.875 9.25 7.875H7.5625V9.5625C7.5625 9.71168 7.50324 9.85476 7.39775 9.96025C7.29226 10.0657 7.14918 10.125 7 10.125C6.85082 10.125 6.70774 10.0657 6.60225 9.96025C6.49676 9.85476 6.4375 9.71168 6.4375 9.5625V7.875H4.75C4.60082 7.875 4.45774 7.81574 4.35225 7.71025C4.24676 7.60476 4.1875 7.46168 4.1875 7.3125C4.1875 7.16332 4.24676 7.02024 4.35225 6.91475C4.45774 6.80926 4.60082 6.75 4.75 6.75H6.4375V5.0625C6.4375 4.91332 6.49676 4.77024 6.60225 4.66475C6.70774 4.55926 6.85082 4.5 7 4.5Z"
          fill="white"
        />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
    >
      <path
        d="M0.25 2.25C0.25 1.65326 0.487053 1.08097 0.90901 0.65901C1.33097 0.237053 1.90326 0 2.5 0L11.5 0C12.0967 0 12.669 0.237053 13.091 0.65901C13.5129 1.08097 13.75 1.65326 13.75 2.25V17.4375C13.7499 17.5392 13.7223 17.6391 13.67 17.7264C13.6177 17.8136 13.5427 17.8851 13.453 17.9331C13.3633 17.9811 13.2623 18.0039 13.1606 17.9991C13.059 17.9942 12.9606 17.9619 12.8759 17.9055L7 14.7386L1.12412 17.9055C1.03942 17.9619 0.941009 17.9942 0.839378 17.9991C0.737747 18.0039 0.6367 17.9811 0.546999 17.9331C0.457299 17.8851 0.382305 17.8136 0.330003 17.7264C0.277702 17.6391 0.250053 17.5392 0.25 17.4375V2.25ZM2.5 1.125C2.20163 1.125 1.91548 1.24353 1.7045 1.4545C1.49353 1.66548 1.375 1.95163 1.375 2.25V16.3867L6.68837 13.5945C6.78069 13.5331 6.88911 13.5003 7 13.5003C7.11089 13.5003 7.21931 13.5331 7.31163 13.5945L12.625 16.3867V2.25C12.625 1.95163 12.5065 1.66548 12.2955 1.4545C12.0845 1.24353 11.7984 1.125 11.5 1.125H2.5Z"
        fill="#223C78"
      />
      <path
        d="M7 4.5C7.14918 4.5 7.29226 4.55926 7.39775 4.66475C7.50324 4.77024 7.5625 4.91332 7.5625 5.0625V6.75H9.25C9.39918 6.75 9.54226 6.80926 9.64775 6.91475C9.75324 7.02024 9.8125 7.16332 9.8125 7.3125C9.8125 7.46168 9.75324 7.60476 9.64775 7.71025C9.54226 7.81574 9.39918 7.875 9.25 7.875H7.5625V9.5625C7.5625 9.71168 7.50324 9.85476 7.39775 9.96025C7.29226 10.0657 7.14918 10.125 7 10.125C6.85082 10.125 6.70774 10.0657 6.60225 9.96025C6.49676 9.85476 6.4375 9.71168 6.4375 9.5625V7.875H4.75C4.60082 7.875 4.45774 7.81574 4.35225 7.71025C4.24676 7.60476 4.1875 7.46168 4.1875 7.3125C4.1875 7.16332 4.24676 7.02024 4.35225 6.91475C4.45774 6.80926 4.60082 6.75 4.75 6.75H6.4375V5.0625C6.4375 4.91332 6.49676 4.77024 6.60225 4.66475C6.70774 4.55926 6.85082 4.5 7 4.5Z"
        fill="#223C78"
      />
    </svg>
  );
};

export default SelectedExecutorIcon;
