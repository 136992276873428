import { Avatar, MantineProvider, Tooltip } from '@mantine/core';

interface InfoTooltipProps {
  text: string;
}

export default function InfoTooltip({ text }: InfoTooltipProps) {
  return (
    <MantineProvider
      theme={{
        colors: {
          'light-blue': [
            '#FCFEFF',
            '#F7FCFF',
            '#F2FAFF',
            '#EDF8FF',
            '#E8F6FF',
            '#E4F4FE',
            '#DFF2FE',
            '#DBF0FE',
            '#D7EEFD',
            '#D3ECFC',
          ],
        },
      }}
    >
      <Tooltip
        multiline
        width={200}
        label={text}
        transition="fade"
        color={'light-blue'}
        transitionDuration={200}
        radius="md"
        position="bottom-start"
        styles={{
          tooltip: {
            color: '#656565',
          },
        }}
      >
        <Avatar src={'/svg/info.svg'} size={16} />
      </Tooltip>
    </MantineProvider>
  );
}
