import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';

import { GET_FILE_PATH } from '../../config/constants';
import {
  CREATE_EMPTY_LENDER,
  DELETE_LENDER,
  UPDATE_LENDERS,
} from '../../graphql/mutations';
import { EXPORT_LENDERS_TABLE, GET_LENDERS_TABLE } from '../../graphql/queries';
import { useDownloadDocument } from '../../hooks/useDownloadDocument';
import { getNewLender } from '../../utils/formatting';
import IconBtn from '../Buttons/IconBtn';
import DeleteIcon from '../Icons/Delete';
import EditIcon from '../Icons/Edit';
import SaveIcon from '../Icons/Save';
import PopUp from '../Overlays/PopUp';
import type { AccountExecutorsPopupData } from '../Popups/AccountExecutorsPopup';
import AccountExecutorsPopup from '../Popups/AccountExecutorsPopup';
import type { CompTypesPopupData } from '../Popups/CompTypesPopup';
import CompTypesPopup from '../Popups/CompTypesPopup';
import type { DescriptionPopupData } from '../Popups/DescriptionPopup';
import DescriptionPopup from '../Popups/DescriptionPopup';
import type { EpoDaysPopupData } from '../Popups/EpoDaysPopup';
import EpoDaysPopup from '../Popups/EpoDaysPopup';
import type { LoanTypesPopupData } from '../Popups/LoanTypesPopup';
import LoanTypesPopup from '../Popups/LoanTypesPopup';
import type { ProfilePicturePopupData } from '../Popups/ProfilePicturePopup';
import ProfilePicturePopup from '../Popups/ProfilePicturePopup';
import type { StatesPopupData } from '../Popups/StatesPopup';
import StatesPopup from '../Popups/StatesPopup';
import { getLendersColumns } from '../Tables/columns/LendersColumns';
import DetailTable from '../Tables/DetailTable';
import H1 from '../Texts/H1';

export default function LendersTable() {
  const [updateLenders] = useMutation(UPDATE_LENDERS);
  const [deleteLenderMutation, { loading: deleteLenderLoading }] =
    useMutation(DELETE_LENDER);
  const [createLender, { loading: loadingCreate }] =
    useMutation(CREATE_EMPTY_LENDER);
  const [saving, setSaving] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState<string | null>(null);
  const [lendersData, setLendersData] = useState<any[]>([]);
  const [lendersDataUpdated, setLendersUpdated] = useState<any[]>([]);
  const [edit, setEdit] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [accountPopupData, setAccountPopupData] =
    useState<null | AccountExecutorsPopupData>(null);
  const [loanTypesPopupData, setLoanTypesPopupData] =
    useState<null | LoanTypesPopupData>(null);
  const [statesPopupData, setStatesPopupData] =
    useState<null | StatesPopupData>(null);
  const [commentsPopupData, setCommentsPopupData] =
    useState<null | DescriptionPopupData>(null);
  const [descriptionPopupData, setDescriptionPopupData] =
    useState<null | DescriptionPopupData>(null);
  const [picturePopupData, setPicturePopupData] =
    useState<null | ProfilePicturePopupData>(null);
  const [compTypesData, setCompTypesData] = useState<null | CompTypesPopupData>(
    null
  );
  const [epoDaysData, setEpoDaysData] = useState<null | EpoDaysPopupData>(null);
  const { loading, refetch } = useQuery(GET_LENDERS_TABLE, {
    onCompleted: (data) => {
      setLendersData(
        data.getLendersTable.map((l: any, i: number) => ({
          ...l,
          index: i + 1,
        }))
      );
    },
    fetchPolicy: 'no-cache',
  });
  const [getExport] = useLazyQuery(EXPORT_LENDERS_TABLE, {
    fetchPolicy: 'no-cache',
  });
  const [setDownlaodUrl, setFileName, triggerDownload] = useDownloadDocument(
    () => setExportLoading(false)
  );
  const handleAccountExecutor = (props: any) => {
    setAccountPopupData(props.data);
  };
  const handleLoanTypes = (props: any) => {
    setLoanTypesPopupData(props.data);
  };
  const handleStates = (props: any) => {
    setStatesPopupData(props.data);
  };
  const handleComments = (props: any) => {
    setCommentsPopupData(props.data);
  };
  const handleDescription = (props: any) => {
    setDescriptionPopupData(props.data);
  };
  const handlePicture = (props: any) => {
    setPicturePopupData(props.data);
  };
  const handleCompTypes = (props: any) => {
    setCompTypesData(props.data);
  };
  const handleEpoDays = (props: any) => {
    setEpoDaysData(props.data);
  };

  const handleSave = async () => {
    if (saving) return;
    setSaving(true);
    const petitions = lendersDataUpdated.map(
      // eslint-disable-next-line unused-imports/no-unused-vars
      ({ index, __typename, account_executors, timestamp, ...rest }) => rest
    );
    await updateLenders({ variables: { lenders: petitions } });
    await refetch();
    setSaving(false);
    setEdit(false);
    setLendersUpdated([]);
  };

  const handleDelete = (id: string) => {
    setIsOpenDelete(id);
  };

  const deleteLender = async () => {
    if (!isOpenDelete?.includes('new-lender'))
      await deleteLenderMutation({ variables: { id: isOpenDelete } });
    const newLenders = [...lendersData.filter((l) => l.id !== isOpenDelete)];
    setLendersData(newLenders);
    setIsOpenDelete(null);
  };

  return (
    <div className="pb-5 md:pb-0">
      <div className="flex flex-col justify-between md:flex-row">
        <H1 text="Lenders Table" fontSize="32px" />
        <div className="mt-5 flex flex-row items-center justify-center gap-5 md:mt-0">
          <IconBtn
            btnText="Add Lender"
            fontSize="16px"
            id="AddLender"
            onClick={async () => {
              await createLender({
                onCompleted: (data) => {
                  setLendersData([
                    ...lendersData,
                    { ...getNewLender(data.createEmptyLender.id) },
                  ]);
                },
              });
            }}
            className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
            disabled={!edit}
            loading={loadingCreate}
          />
          <IconBtn
            btnText="Export"
            fontSize="16px"
            id="ExportLenders"
            onClick={async () => {
              setExportLoading(true);
              const res = await getExport();
              const url = GET_FILE_PATH + res.data.exportLendersTable.key;
              setFileName('lenders.xlsx');
              setDownlaodUrl(url);
              triggerDownload();
            }}
            className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
            disabled={edit}
            loading={exportLoading}
          />
          <IconBtn
            btnText="Edit"
            fontSize="16px"
            id="EditLender"
            onClick={() => {
              setEdit(!edit);
            }}
            icon={<EditIcon />}
            className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
            disabled={edit}
          />
          <IconBtn
            btnText="Save"
            onClick={handleSave}
            loading={saving}
            id="SubmitBtn"
            fontSize="16px"
            icon={<SaveIcon />}
            className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
            disabled={!edit}
          />
        </div>
      </div>
      <DetailTable
        columns={getLendersColumns(
          handleAccountExecutor,
          handleLoanTypes,
          handleStates,
          handleComments,
          handleDescription,
          handlePicture,
          handleDelete,
          handleCompTypes,
          handleEpoDays
        )}
        data={lendersData}
        edit={edit}
        loading={loading}
        lenders
        setLendersUpdated={setLendersUpdated}
      />
      <AccountExecutorsPopup
        isOpen={accountPopupData !== null}
        onClose={() => {
          setAccountPopupData(null);
        }}
        onSave={async (new_acc_ex: any[]) => {
          const newLenderData = lendersData.map((l) => {
            if (l.id !== accountPopupData?.id) return l;
            return {
              ...l,
              account_executors: [...new_acc_ex],
            };
          });
          setLendersData(newLenderData);
        }}
        data={accountPopupData}
      />
      <LoanTypesPopup
        isOpen={loanTypesPopupData !== null}
        onClose={() => {
          setLoanTypesPopupData(null);
        }}
        onSave={async () => {
          await refetch();
          setLoanTypesPopupData(null);
        }}
        data={loanTypesPopupData}
      />
      <StatesPopup
        isOpen={statesPopupData !== null}
        onClose={() => {
          setStatesPopupData(null);
        }}
        onSave={async () => {
          await refetch();
          setStatesPopupData(null);
        }}
        data={statesPopupData}
      />
      <CompTypesPopup
        isOpen={compTypesData !== null}
        onClose={() => {
          setCompTypesData(null);
        }}
        onSave={async () => {
          await refetch();
          setCompTypesData(null);
        }}
        data={compTypesData}
      />
      <EpoDaysPopup
        isOpen={epoDaysData !== null}
        onClose={() => {
          setEpoDaysData(null);
        }}
        onSave={async () => {
          await refetch();
          setEpoDaysData(null);
        }}
        data={epoDaysData}
      />
      <DescriptionPopup
        isOpen={descriptionPopupData !== null}
        onClose={() => {
          setDescriptionPopupData(null);
        }}
        onSave={async () => {
          await refetch();
          setDescriptionPopupData(null);
        }}
        data={descriptionPopupData}
        title="Description"
        field="short_description"
      />
      <DescriptionPopup
        isOpen={commentsPopupData !== null}
        onClose={() => {
          setCommentsPopupData(null);
        }}
        onSave={async () => {
          await refetch();
          setCommentsPopupData(null);
        }}
        data={commentsPopupData}
        title="Comments"
        field="more_about"
      />
      <ProfilePicturePopup
        isOpen={picturePopupData !== null}
        onClose={() => {
          setPicturePopupData(null);
        }}
        onSave={async () => {
          await refetch();
          setPicturePopupData(null);
        }}
        data={picturePopupData}
      />
      <PopUp
        isOpen={!!isOpenDelete}
        setIsOpen={() => setIsOpenDelete(null)} // we only want this function inside the component to close the popup
        reset={deleteLender}
        title="Are you sure you want to delete the selected lender?"
        acceptButton={
          <IconBtn
            btnText="Delete"
            fontSize="16px"
            onClick={deleteLender}
            loading={deleteLenderLoading}
            icon={<DeleteIcon />}
            className="rounded-[10px] bg-[#AE0000] font-Segoe text-white hover:bg-blue"
          />
        }
      ></PopUp>
    </div>
  );
}
