import { Box, Checkbox } from '@mantine/core';

import H1 from '../Texts/H1';

interface BoxCBProps {
  text: string;
  width: string;
  checked?: boolean;
  disabled?: boolean;
  onClick: (val: boolean) => void;
}

export default function BoxCB({
  text,
  width,
  checked,
  disabled,
  onClick,
}: BoxCBProps) {
  return (
    <Box
      onClick={() => onClick(!checked)}
      sx={(theme) => ({
        border: `1px solid`,
        color: '#223C78',
        textAlign: 'left',
        padding: theme.spacing.sm,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
        width,
        maxWidth: width,
      })}
      style={{ width: `${width} !important` }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <H1 text={text} fontSize="16px" />
        <Checkbox
          onClick={() => onClick(!checked)}
          style={{ color: '#223C78' }}
          checked={checked}
          disabled={disabled}
        />
      </div>
    </Box>
  );
}
