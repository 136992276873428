import '../../App.css';

import { useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';

import { GET_USER_AUTH0 } from '../../graphql/queries';
import Iframe from '../Cards/Iframe';

// import React from 'react';

export default function Welcome() {
  const { user } = useAuth0();
  const [name, setName] = useState('');
  useQuery(GET_USER_AUTH0, {
    variables: {
      id: user?.sub || '',
    },
    onCompleted: (data) => {
      setName(`${data.userAuth0.first_name} ${data.userAuth0.last_name}`);
    },
  });

  return (
    <>
      <div className="w-full h-full">
        <span className="font-Segoe text-blue text-3xl">Welcome</span>
        <span className="font-Segoe text-blue text-3xl font-bold"> {name}</span>

        <div className="flex lg:flex-row md:gap-20 mt-10 flex-col">
          {/* <Iframe
            title={'Mortgage News'}
            iframeSrc="https://housingbrief.com/widget/mbs/simple/55258933becf1e1f30df6459?sn=true&amp;sc=true&amp;rs=&amp;c=336699&amp;w=298&amp;bgc=ffffff&amp;tc=333333"
          ></Iframe> */}
          <Iframe
            title={'Mortgage Rates'}
            iframeSrc="https://housingbrief.com/widget/mortgage-rates/tabbed/55258933becf1e1f30df6459?sn=true&sc=true&rs=mnd|fm|mba&c=336699&w=348&bgc=ffffff&tc=333333"
          ></Iframe>
          <Iframe
            title={'Treasury / MBS'}
            iframeSrc="https://housingbrief.com/widget/mbs/simple/55258933becf1e1f30df6459?sn=true&amp;sc=true&amp;rs=&amp;c=336699&amp;w=298&amp;bgc=ffffff&amp;tc=333333"
          ></Iframe>
        </div>
      </div>
    </>
  );
}
