import { MultiSelect } from '@mantine/core';
import { useEffect, useState } from 'react';

import { STATES } from '../../config/constants';
import DownArrowIcon from '../Icons/DownArrow';
import type { FilterType } from '../pages/Lenders';

interface DataValue {
  label: string;
  value: string;
}

interface LenderMultiProps {
  className?: string;
  data?: DataValue[];
  placeholder?: string;
  setFilters?: React.Dispatch<React.SetStateAction<FilterType>>; // Update the type here
  filters?: FilterType;
  filterKey: keyof FilterType;
  id?: string;
  creatable?: boolean;
}

const dataValues = STATES;

export default function LenderMulti({
  className = '!min-w-[215px]',
  data = dataValues,
  placeholder = 'Loan Types',
  setFilters = () => {},
  filters = {
    lenderName: null,
    loanType: null,
    epo_days: null,
    compType: null,
    state: null,
    NoFico: null,
    FHAMinimum: null,
    VAMinimum: null,
    LockNShop: null,
    ManualUW: null,
    TDBUnderwrite: null,
    LoanSifter: null,
    LenderPrice: null,
    MinRating: null,
    sortingOption: null,
  },
  id = '',
  filterKey,
  creatable = false,
}: LenderMultiProps) {
  const [value, setValue] = useState<string[]>([]);

  useEffect(() => {
    setValue(filters[filterKey] as string[]);
  }, [filters]);

  const handleMultiSelectChange = (selectedValues: string[]) => {
    if (selectedValues.length === 0) {
      setFilters({ ...filters, [filterKey]: null });
      return;
    }
    setFilters({ ...filters, [filterKey]: selectedValues });
  };

  return (
    <MultiSelect
      id={id}
      data={data}
      value={value || []}
      onChange={handleMultiSelectChange}
      radius="md"
      creatable={creatable}
      rightSection={<DownArrowIcon />}
      maxSelectedValues={1}
      placeholder={placeholder}
      className={className}
      styles={{
        input: {
          border: '1px solid #27ABE2',
          height: '36px !important',
        },
      }}
    />
  );
}
