import { Text } from '@mantine/core';

interface H1Props {
  text: string;
  fontSize: string;
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent';
  id?: string | undefined;
  className?: string;
}

export default function H1({
  text,
  fontSize,
  textAlign,
  id = undefined,
  className = '',
}: H1Props) {
  return (
    <>
      <Text
        id={id}
        weight={700}
        style={{
          color: '#223C78',
          fontFamily: '',
          fontSize,
          textAlign: textAlign || 'left',
        }}
        className={className}
      >
        {text}
      </Text>
    </>
  );
}
