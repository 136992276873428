import '../../App.css';

import { useMutation } from '@apollo/client';
import { Checkbox, Input, Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { LOAN_TYPES } from '../../config/constants';
import { UPDATE_LENDER } from '../../graphql/mutations';
import IconBtn from '../Buttons/IconBtn';
import SaveIcon from '../Icons/Save';
import H1 from '../Texts/H1';

export type LoanTypesPopupData = {
  id: string;
  name: string;
  loan_types: string[];
  fha_id: number;
  va_id: number;
  fha_minimum_fico: number;
  va_minimum_fico: number;
  manual_uw_fha_score: number;
};

type LoanTypesPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  data: LoanTypesPopupData | null;
};

const mapToObject = (loanTypes: string[]) => {
  const loanTypesObject: { [key: string]: boolean } = {};
  loanTypes.forEach((loanType) => {
    loanTypesObject[loanType] = true;
  });
  return loanTypesObject;
};

const mapToArray = (loanTypes: { [key: string]: boolean }) => {
  const loanTypesArray: string[] = [];
  Object.keys(loanTypes).forEach((key) => {
    if (loanTypes[key]) loanTypesArray.push(key);
  });
  return loanTypesArray;
};

const LoanTypesPopup = ({
  isOpen,
  onClose,
  onSave,
  data,
}: LoanTypesPopupProps) => {
  const [title, setTitle] = useState('');
  const [updateLender] = useMutation(UPDATE_LENDER);
  const [loanTypes, setLoanTypes] = useState<{ [key: string]: boolean }>(
    mapToObject(data?.loan_types || [])
  );
  const { register, reset, watch } = useForm<LoanTypesPopupData>();
  const l = watch();
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setLoanTypes(mapToObject(data?.loan_types || []));
  }, [data?.loan_types]);

  useEffect(() => {
    if (data) reset(data);
  }, [data]);

  const handleClosePopup = () => {
    onClose();
  };

  const handleSaveLoanTypes = async () => {
    if (saving) return;
    setSaving(true);
    await updateLender({
      variables: {
        id: data?.id,
        loan_types: mapToArray(loanTypes),
        fha_id: l.fha_id ? Number(l.fha_id) : null,
        va_id: l.va_id ? Number(l.va_id) : null,
        fha_minimum_fico: l.fha_minimum_fico
          ? Number(l.fha_minimum_fico)
          : null,
        va_minimum_fico: l.va_minimum_fico ? Number(l.va_minimum_fico) : null,
        manual_uw_fha_score: l.manual_uw_fha_score
          ? Number(l.manual_uw_fha_score)
          : null,
      },
    });
    await onSave();
    setSaving(false);
  };

  useEffect(() => {
    if (data?.name) setTitle(`${data?.name} - Loan Types`);
  }, [data?.name]);

  return (
    <Modal opened={isOpen} onClose={handleClosePopup} centered size="auto">
      <div id="filterDialog" className="">
        <div className="m-5 w-[900px]">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-between">
              <H1 text={title} fontSize="24px" />
              <IconBtn
                btnText="Save"
                onClick={handleSaveLoanTypes}
                loading={saving}
                id="SubmitBtn"
                fontSize="16px"
                icon={<SaveIcon />}
                className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
              />
            </div>
            <div className="grid grid-rows-[repeat(11,minmax(0,1fr))] grid-flow-col gap-4 h-full w-full">
              {LOAN_TYPES.map((loanType) => (
                <Checkbox
                  key={loanType.value}
                  label={loanType.label}
                  checked={loanTypes[loanType.value]}
                  onChange={() => {
                    setLoanTypes({
                      ...loanTypes,
                      [loanType.value]: !loanTypes[loanType.value],
                    });
                  }}
                  styles={{
                    label: {
                      color: '#223C78',
                      fontWeight: 'bold',
                    },
                  }}
                />
              ))}
            </div>
            <H1
              text="Complementary Information"
              fontSize="24px"
              className="mt-4"
            />
            <div className="grid grid-cols-3 gap-4">
              <div className="flex flex-row place-items-baseline gap-4">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  FHA ID
                </span>
                <Input
                  variant="unstyled"
                  placeholder="Value"
                  id="FHAMId"
                  size="xs"
                  {...register('fha_id')}
                  styles={{
                    wrapper: {
                      width: '70px',
                    },
                    input: {
                      borderBottom: '1px solid #DFF1FF !important',
                      height: '14px !important',
                      minHeight: '14px !important',
                      color: '#626262',
                      '::placeholder': {
                        color: '#626262',
                      },
                    },
                  }}
                />
              </div>
              <div className="flex flex-row place-items-baseline gap-4">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  FHA Minimum FICO
                </span>
                <Input
                  variant="unstyled"
                  placeholder="Value"
                  id="FHAMinimumValue"
                  size="xs"
                  {...register('fha_minimum_fico')}
                  styles={{
                    wrapper: {
                      width: '70px',
                    },
                    input: {
                      borderBottom: '1px solid #DFF1FF !important',
                      height: '14px !important',
                      minHeight: '14px !important',
                      color: '#626262',
                      '::placeholder': {
                        color: '#626262',
                      },
                    },
                  }}
                />
              </div>
              <div className="flex flex-row place-items-baseline gap-4">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  Manual UW FHA - Score
                </span>
                <Input
                  variant="unstyled"
                  placeholder="Value"
                  id="ManualUWFHAScore"
                  size="xs"
                  {...register('manual_uw_fha_score')}
                  styles={{
                    wrapper: {
                      width: '70px',
                    },
                    input: {
                      borderBottom: '1px solid #DFF1FF !important',
                      height: '14px !important',
                      minHeight: '14px !important',
                      color: '#626262',
                      '::placeholder': {
                        color: '#626262',
                      },
                    },
                  }}
                />
              </div>
              <div className="flex flex-row place-items-baseline gap-4">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  VA ID
                </span>
                <Input
                  variant="unstyled"
                  placeholder="Value"
                  id="VAId"
                  size="xs"
                  {...register('va_id')}
                  styles={{
                    wrapper: {
                      width: '70px',
                    },
                    input: {
                      borderBottom: '1px solid #DFF1FF !important',
                      height: '14px !important',
                      minHeight: '14px !important',
                      color: '#626262',
                      '::placeholder': {
                        color: '#626262',
                      },
                    },
                  }}
                />
              </div>
              <div className="flex flex-row place-items-baseline gap-4">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  VA Minimum ID
                </span>
                <Input
                  variant="unstyled"
                  placeholder="Value"
                  id="VAMinimumValue"
                  size="xs"
                  {...register('va_minimum_fico')}
                  styles={{
                    wrapper: {
                      width: '70px',
                    },
                    input: {
                      borderBottom: '1px solid #DFF1FF !important',
                      height: '14px !important',
                      minHeight: '14px !important',
                      color: '#626262',
                      '::placeholder': {
                        color: '#626262',
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LoanTypesPopup;
