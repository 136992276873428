// components/Rating.tsx
import '../../App.css';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface RatingProps {
  rating: number;
}
interface StarProps {
  halfFilled?: boolean;
  unfilled?: boolean;
  id?: string;
}

const Star: React.FC<StarProps> = ({ halfFilled, unfilled, id }) => {
  if (unfilled) {
    return (
      <span id={id} className="yellow-star">
        <FontAwesomeIcon icon={faStarRegular as IconProp} />
      </span>
    );
  }

  const starIcon = halfFilled ? faStarHalfAlt : faStar;

  return (
    <span id={id} className="yellow-star">
      <FontAwesomeIcon icon={starIcon as IconProp} />
    </span>
  );
};

const Rating: React.FC<RatingProps> = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const decimal = rating - fullStars;
  const hasHalfStar = decimal >= 0.5;

  const renderStar = (index: number) => {
    if (index < fullStars) {
      return <Star id={`Star${index}`} />;
    }
    if (index === fullStars && hasHalfStar) {
      return <Star halfFilled id={`Star${index}`} />;
    }
    return <Star unfilled id={`Star${index}`} />;
  };

  return (
    <div id={`rated${rating}`} className="flex items-center">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="h-6 w-6">
          {renderStar(index)}
        </div>
      ))}
    </div>
  );
};

export default Rating;
