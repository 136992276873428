import { Avatar, Button, Tooltip } from '@mantine/core';
import { useState } from 'react';

import { GET_FILE_PATH } from '../../config/constants';
import { useDownloadDocument } from '../../hooks/useDownloadDocument';

interface TextBtnProps {
  btnText: string;
  s3key: string;
  ext?: string;
  file_name: string;
  iconColor: string;
}

export default function DownloadBtn({
  btnText,
  s3key,
  ext,
  iconColor,
  file_name,
}: TextBtnProps) {
  const [loading, setLoading] = useState(false);
  const [setDownlaodUrl, setFileName, triggerDownload] = useDownloadDocument(
    () => setLoading(false)
  );

  const downloadDoc = () => {
    setLoading(true);
    const url = GET_FILE_PATH + s3key;
    setFileName(`${file_name}.${ext || 'csv'}`);
    setDownlaodUrl(url);
    triggerDownload();
  };

  const textColors = () => {
    if (iconColor === 'red') return '#AA0000';
    if (iconColor === 'yellow') return '#F0CA42';
    if (iconColor === 'green') return '#287702';
    return '';
  };

  return (
    <Tooltip
      label={ext || 'csv'}
      styles={{
        tooltip: { color: 'white !important' },
      }}
    >
      <Button
        radius="xl"
        rightIcon={
          <Avatar
            src={`/svg/download_${iconColor}.svg`}
            size={12}
            className="ml-0 mt-0.5"
          />
        }
        styles={() => ({
          root: {
            backgroundColor: 'white',
            '&:hover': {
              backgroundColor: 'white',
            },
            color: textColors(),
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          },
        })}
        loading={loading}
        loaderProps={{
          color: 'blue',
        }}
        onClick={downloadDoc}
      >
        {btnText}
      </Button>
    </Tooltip>
  );
}
