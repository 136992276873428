import { Input } from '@mantine/core';
import { useEffect, useState } from 'react';
import type { UseFormRegister, UseFormSetValue } from 'react-hook-form';

import LenderBtn from '../Buttons/LenderBtn';
import DeleteIcon from '../Icons/Delete';
import EmailIcon from '../Icons/Email';
import SelectedExecutorIcon from '../Icons/SelectedExecutor';
import PopUp from '../Overlays/PopUp';

interface Executor {
  id: string;
  full_name: string;
  phone: string;
  email: string;
  selected: boolean;
}

interface LenderProps {
  edit?: boolean;
  setAccExecutors?: (accExecutors: any) => void;
  accExecutors?: any;
  setDeletedExecutors?: (deletedExecutors: any) => void;
  deletedExecutors?: any;
  id?: string;
  register?: UseFormRegister<any> | (() => void);
  setValue?: UseFormSetValue<any> | (() => void);
  executor: Executor;
  errors?: any;
}

export default function AccExecutor({
  edit = false,
  setAccExecutors = () => {},
  accExecutors = [],
  setDeletedExecutors = () => {},
  deletedExecutors = [],
  register = () => {},
  setValue = () => {},
  executor = {
    id: 'new',
    full_name: 'John Doe',
    phone: 'none',
    email: 'none',
    selected: false,
  },
  errors = {},
}: LenderProps) {
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { id, full_name, phone, email } = executor;
  const handleDelete = () => {
    const deleted = accExecutors.find(
      (accExecutor: any) => accExecutor.id === id
    );
    if (deleted) {
      const newAccExecutors = accExecutors.filter(
        (accExecutor: any) => accExecutor.id !== id
      );
      setAccExecutors(newAccExecutors);
      setDeletedExecutors([...deletedExecutors, deleted]);
    }
  };

  // const handleSelected = () => {
  //   if (accExecutors.length === 1) {
  //     return;
  //   }

  //   const newAccExecutors = accExecutors.map((accExecutor: any) => {
  //     if (accExecutor.id === id) {
  //       if (accExecutor.selected) {
  //         return { ...accExecutor, selected: true };
  //       }
  //       return { ...accExecutor, selected: !accExecutor.selected };
  //     }

  //     return { ...accExecutor, selected: false };
  //   });
  //   setAccExecutors(newAccExecutors);
  // };

  const handleChangeValue = (value: any, field: string) => {
    const newAccExecutors = accExecutors.map((accExecutor: any) => {
      if (accExecutor.id === id) {
        return { ...accExecutor, [field]: value };
      }
      return accExecutor;
    });
    setAccExecutors(newAccExecutors);
  };

  useEffect(() => {
    setValue(`${id}-full_name`, full_name);
    setValue(`${id}-phone`, phone === 'none' ? null : phone);
    setValue(`${id}-email`, email === 'none' ? null : email);
  }, [id]);
  return (
    <div>
      <div className="flex flex-col md:grid md:grid-cols-3 gap-2">
        <div className="flex flex-row items-center gap-3">
          {edit && (
            <button type="button" disabled>
              <SelectedExecutorIcon />
            </button>
          )}
          <div className="flex flex-col gap-2">
            <span className="font-SegoeBold text-blue text-base font-bold">
              Full Name *
            </span>
            {!edit && (
              <span
                id="AccExecutorName"
                className="font-Segoe text-gray text-sm"
              >
                {full_name}
              </span>
            )}
            {edit && (
              <Input
                variant="unstyled"
                placeholder={full_name}
                size="xs"
                id="Acc_name"
                {...register(`${id}-full_name`, {
                  required: true,
                  value: full_name,
                })}
                onChange={(value: any) => {
                  handleChangeValue(value.target.value, 'full_name');
                  setValue(`${id}-full_name`, value.target.value);
                }}
                styles={{
                  input: {
                    height: '16px !important',
                    minHeight: '16px !important',
                    color: '#626262',
                    fontSize: '14px',
                    '::placeholder': {
                      color: '#626262',
                      fontSize: '14px',
                    },
                  },
                  wrapper: {
                    borderBottom: `1px solid ${
                      errors[`${id}-full_name`] ||
                      (errors.new0 &&
                        errors.new0[`${id.replace('new0.', '')}-full_name`])
                        ? '#ff0000'
                        : '#DFF1FF'
                    }`,
                  },
                }}
              />
            )}
            {/* {errors[`${id}-full_name`]?.type === 'required' && (
              <span className="font-Segoe text-sm text-[#DC2626]">
                * Required
              </span>
            )} */}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <span className="font-SegoeBold text-blue text-base font-bold">
            Phone Number
          </span>
          {!edit && (
            <LenderBtn
              btnText={phone || '-'}
              disabled={!phone}
              id="AccExecutorPhone"
              fontSize="11px"
              url={phone ? `tel:${phone}` : ''}
            />
          )}
          {edit && (
            <Input
              variant="unstyled"
              placeholder={phone}
              className="max-w-[190px]"
              id="Acc_phone"
              size="xs"
              {...register(`${id}-phone`, {
                pattern: {
                  value:
                    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g,
                  message: 'Invalid phone number format',
                },
              })}
              onChange={(value: any) => {
                handleChangeValue(value.target.value, 'phone');
                setValue(`${id}-phone`, value.target.value);
              }}
              styles={{
                input: {
                  height: '16px !important',
                  minHeight: '16px !important',
                  color: '#223C78',
                  fontSize: '14px',
                  '::placeholder': {
                    color: '#626262',
                    fontSize: '14px',
                  },
                },
                wrapper: {
                  borderBottom: `1px solid ${
                    errors[`${id}-phone`] ? '#ff0000' : '#DFF1FF'
                  }`,
                },
              }}
            />
          )}
          {/* {errors[`${id}-phone`]?.type === 'pattern' && (
            <span className="font-Segoe text-sm text-[#DC2626]">
              Phone not valid
            </span>
          )} */}
        </div>
        <div className="flex flex-row items-center gap-3">
          <div className="flex w-full flex-col gap-2">
            <span className="font-SegoeBold text-blue text-base font-bold">
              Email
            </span>
            {!edit && (
              <LenderBtn
                btnText={email || '-'}
                disabled={!email}
                id="AccExecutorEmail"
                fontSize="11px"
                icon={<EmailIcon />}
                url={email ? `mailto:${email}` : ''}
              />
            )}
            {edit && (
              <Input
                variant="unstyled"
                placeholder={email}
                id="Acc_email"
                size="xs"
                {...register(`${id}-email`, {
                  value: email === 'none' ? null : email,
                  pattern: {
                    value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                    message: 'Invalid Email format',
                  },
                })}
                onChange={(value: any) => {
                  handleChangeValue(value.target.value, 'email');
                  setValue(`${id}-email`, value.target.value);
                }}
                styles={{
                  input: {
                    height: '16px !important',
                    minHeight: '16px !important',
                    color: '#626262',
                    fontSize: '14px',
                    '::placeholder': {
                      color: '#626262',
                      fontSize: '14px',
                    },
                  },
                  wrapper: {
                    borderBottom: `1px solid ${
                      errors[`${id}-email`] ? '#ff0000' : '#DFF1FF'
                    }`,
                  },
                }}
              />
            )}
            {/* {errors[`${id}-email`]?.type === 'pattern' && (
              <span className="font-Segoe text-sm text-[#DC2626]">
                Email not valid
              </span>
            )} */}
          </div>
          {edit && (
            <div className="flex h-6 w-6 items-center justify-center rounded-full bg-[#AE0000] text-white">
              <button type="button" onClick={() => setIsOpen(true)}>
                <DeleteIcon small={true}></DeleteIcon>
              </button>
            </div>
          )}
        </div>
      </div>
      <PopUp
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        reset={handleDelete}
        title="Are you sure you want to delete the selected account executor?"
      ></PopUp>
    </div>
  );
}
