import type { ICellRendererParams } from 'ag-grid-community';

import DeleteIcon from '../../Icons/Delete';

export default (props: ICellRendererParams) => {
  const { onClick } = props as any;
  return (
    <span className="flex w-full h-full justify-center items-center">
      <button type="button" onClick={() => onClick(props.data.id)}>
        <div className="flex h-6 w-6 items-center justify-center rounded-full bg-[#AE0000] text-white">
          <DeleteIcon small={true}></DeleteIcon>
        </div>
      </button>
    </span>
  );
};
