import { Avatar, Button } from '@mantine/core';
import { Link } from 'react-router-dom';

interface LeftTextBtnProps {
  btnText: string;
  link: string;
}

export default function LeftTextBtn({ btnText, link }: LeftTextBtnProps) {
  return (
    <Button
      leftIcon={<Avatar src={'/svg/text-arrow.svg'} size={20} />}
      variant="white"
      styles={() => ({
        root: {
          '&:hover': {
            color: '#223C78',
            fill: '#223C78',
          },
          color: '#27ABE2',
          fill: '#223C78',
        },
      })}
      component={Link}
      to={link}
    >
      {btnText}
    </Button>
  );
}
