import '../../App.css';

import { useLazyQuery, useQuery } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';

import {
  GET_ACCEXECUTORS_BYID,
  GET_LENDERS_BYID,
  GET_REVIEWS_BYID,
  GET_USER_AUTH0,
} from '../../graphql/queries';
import { ROUTES } from '../../routes/config';
import FilterDisplay from '../Buttons/FilterDisplay';
import IconBtn from '../Buttons/IconBtn';
import LeftTextBtn from '../Buttons/LeftTextBtn';
import AccExecutor from '../Cards/AccExecutor';
import Review from '../Cards/Review';
import EditIcon from '../Icons/Edit';
import ReviewIcon from '../Icons/Review';
import Rating from '../Icons/Stars';
import LoaderOverlay from '../Overlays/LoaderOverlay';
import NewReview from '../Overlays/NewReview';
import H1 from '../Texts/H1';

dayjs.extend(advancedFormat);

interface IReview {
  id: string;
  rating: number;
  message: string;
  user_id: string;
  timestamp: string;
  user_full_name: string;
  user_picture: string;
}
interface SingleLenderProps {
  hasAdmin: boolean;
}

interface UserData {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address: string;
  state: string;
  city: string;
  zipcode: string;
}
interface Lender {
  id: string;
  name: string;
  lender_picture: string;
  short_description: string;
  featured: string;
  website: string;
  states: string[];
  loan_types: string[];
  no_fico: boolean;
  fha_minimum_fico: number;
  va_minimum_fico: number;
  lpc_points: number;
  lpc_flat_fee: number;
  bpc_conditions_unit: string;
  bpc_conditions_value: number;
  lock_n_shop: boolean;
  manual_uw: boolean;
  tdb_underwrite: boolean;
  loan_sifter: boolean;
  lender_price: boolean;
  average_review_score: number;
  total_votes: number;
  phone: string;
  email: string;
  more_about: string;
  epo_policy: string;
  broker_id: string;
  timestamp: string;
  revenue_notes: string;
  comp_types: string[];
  epo_days: number;
}

const REVIEWS_PER_PAGE = 5;

export default function SingleLender({ hasAdmin }: SingleLenderProps) {
  const { id } = useParams();
  const { user } = useAuth0();
  const [lenderData, setLenderData] = useState<Lender>();
  const [userData, setUserData] = useState<UserData>();
  const [reviewToShow, setReviewToShow] = useState<any>(REVIEWS_PER_PAGE);
  const [otherReviews, setOtherReviews] = useState<any>([]);
  const [areMoreReviews, setMoreReviews] = useState<any>(true);
  const [myReviews, setMyReviews] = useState<any>([]);
  const [accExecutors, setAccExecutors] = useState<any>([]);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const lender = useQuery(GET_LENDERS_BYID, {
    variables: { id },
    onCompleted: (data) => {
      setLenderData(data?.getLendersById);
      setMyReviews(data?.getLendersById.myRatings);
    },
    fetchPolicy: 'no-cache',
  });

  useQuery(GET_USER_AUTH0, {
    variables: {
      id: user?.sub || '',
    },
    onCompleted: (data) => setUserData(data?.userAuth0),
  });

  useQuery(GET_ACCEXECUTORS_BYID, {
    variables: {
      id,
    },
    onCompleted: (data) => setAccExecutors(data?.getAccExecutorsById),
    fetchPolicy: 'no-cache',
  });

  const userId = userData?.id;
  useQuery(GET_REVIEWS_BYID, {
    variables: { id, userId: userId || '', offset: 0, limit: REVIEWS_PER_PAGE },
    onCompleted: (data) => {
      if (data.getReviewsById.length > 0) setOtherReviews(data?.getReviewsById);
      else setMoreReviews(false);
    },
    fetchPolicy: 'no-cache',
  });

  const [getMoreReviews] = useLazyQuery(GET_REVIEWS_BYID, {
    variables: { id, userId: userId || '', offset: 0, limit: REVIEWS_PER_PAGE },
    onCompleted: (data) => {
      if (data.getReviewsById.length > 0)
        setOtherReviews([...otherReviews, ...data.getReviewsById]);
      else setMoreReviews(false);
    },
    fetchPolicy: 'no-cache',
  });

  const moreReviews = () => {
    getMoreReviews({
      variables: {
        id,
        userId: userId || '',
        offset: otherReviews.length,
        limit: otherReviews.length + REVIEWS_PER_PAGE,
      },
    });
    setReviewToShow(reviewToShow + 5);
  };

  return (
    <>
      <div
        className="flex flex-col overflow-y-auto pr-5 md:pr-0"
        id="scrollableDiv"
      >
        <div className="ml-[-20px] py-2">
          <LeftTextBtn btnText="Lenders" link="/lenders" />
        </div>
        {lenderData && (
          <div className="flex md:justify-between w-full">
            <div className="flex flex-col gap-3 md:flex-row w-full md:w-fit">
              <H1 text={`${lenderData.name}`} id="LenderName" fontSize="32px" />
              {hasAdmin && (
                <div className="flex items-center justify-between md:justify-normal">
                  <IconBtn
                    btnText="Edit"
                    fontSize="16px"
                    id="EditLender"
                    onClick={() =>
                      navigate(ROUTES.LENDER_EDIT.replace(':id', id || ''))
                    }
                    icon={<EditIcon />}
                    className="h-6 bg-[#DFF1FF] font-Segoe text-[#223C78] hover:bg-[#223C78] hover:text-white"
                  />
                  <div className="flex items-center justify-center md:hidden">
                    <span className="font-SegoeBold text-base font-bold text-blue">
                      Last Updated:&nbsp;
                    </span>
                    <span className="font-Segoe text-sm text-gray">
                      {dayjs(lenderData?.timestamp).format('MMMM Do, YYYY')}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="hidden items-center justify-center md:flex">
              <span className="font-SegoeBold text-base font-bold text-blue">
                Last Updated:&nbsp;
              </span>
              <span className="font-Segoe text-sm text-gray">
                {dayjs(lenderData?.timestamp).format('MMMM Do, YYYY')}
              </span>
            </div>
          </div>
        )}
        <div className="grid w-full grid-cols-1 gap-5 pl-2 md:grid-cols-2 md:pl-0 pb-10">
          <div className="flex h-full w-full flex-col">
            <div className="mb-10 flex h-full w-full items-center justify-center mt-5">
              <img
                alt=""
                id="LenderImage"
                src={lenderData?.lender_picture}
                className="!max-h-[250px] !max-w-[500px] w-[inherit] object-cover"
              ></img>
            </div>
            {lenderData?.short_description !== null && (
              <span
                className="font-Segoe text-base text-gray"
                id="ShortDescription"
                dangerouslySetInnerHTML={{
                  __html: `${lenderData?.short_description}`,
                }}
              ></span>
            )}

            <div className="mt-10 flex flex-row justify-between">
              <span className="font-SegoeBold text-2xl text-blue">
                Reviews and Comments
              </span>
              <IconBtn
                btnText="Add Review"
                fontSize="16px"
                id="AddReview"
                icon={<ReviewIcon />}
                onClick={() => setIsOpen(true)}
                className="rounded-[10px] bg-blue font-Segoe text-white hover:bg-[#27abe2]"
              />
            </div>
            <div className="flex flex-row gap-2">
              <span className="font-Segoe text-lg text-gray">
                {Math.round(lenderData?.average_review_score || 0)}
              </span>
              <div className="flex flex-row items-center justify-center">
                <Rating rating={lenderData?.average_review_score || 0} />
                <div>
                  <span className="font-Segoe text-[10px] text-gray">
                    ({lenderData?.total_votes})
                  </span>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <span className="font-SegoeBold text-2xl text-blue">
                My Reviews
              </span>
              {myReviews && myReviews.length > 0 && (
                <div id="MyReviews" className="mt-5">
                  {myReviews.map((review: IReview) => {
                    return (
                      <Review
                        key={review.id}
                        author={review.user_full_name}
                        commentary={review.message}
                        rating={review.rating}
                        timestamp={review.timestamp}
                        picture={review.user_picture}
                      ></Review>
                    );
                  })}
                </div>
              )}
              {myReviews && myReviews.length === 0 && (
                <div>
                  <div className={`separator mx-auto my-3 w-full`}></div>
                  <div className="flex items-center justify-center">
                    <span className="font-Segoe text-base text-blue">
                      You have no reviews yet
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="mt-5">
              <span className="font-SegoeBold text-2xl text-blue">
                Other Reviews
              </span>
              {/* <div className="no-scrollbar flex max-h-96 flex-col overflow-y-auto"> */}
              {otherReviews && otherReviews.length === 0 && (
                <div>
                  <div className={`separator mx-auto my-3 w-full`}></div>
                  <div className="flex items-center justify-center">
                    <span className="font-Segoe text-base text-blue">
                      This lender has no reviews yet
                    </span>
                  </div>
                </div>
              )}
              <InfiniteScroll
                dataLength={otherReviews?.slice(0, reviewToShow).length || 0} // This is important field to render the next data
                next={moreReviews}
                hasMore={areMoreReviews}
                className="mt-5 pr-5"
                loader={<h4>Loading...</h4>}
              >
                {otherReviews?.slice(0, reviewToShow).map((review: IReview) => {
                  return (
                    <Review
                      key={review.id}
                      author={review.user_full_name}
                      commentary={review.message}
                      rating={review.rating}
                      timestamp={review.timestamp}
                    ></Review>
                  );
                })}
              </InfiniteScroll>
              {/* </div> */}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <span className="font-SegoeBold text-2xl font-bold text-blue">
              Lender Information
            </span>
            {lenderData?.broker_id && (
              <div className="flex flex-col gap-2">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  Broker ID
                </span>
                <span
                  className="font-Segoe text-sm text-gray"
                  id="LenderBrokerId"
                >
                  {lenderData?.broker_id}
                </span>
              </div>
            )}
            <div className="flex flex-col gap-2">
              <span className="font-SegoeBold text-base font-bold text-blue">
                Lender Website
              </span>
              <a
                className="font-Segoe text-sm text-gray"
                target="_blank"
                id="Website"
                href={lenderData?.website}
              >
                {lenderData?.website}
              </a>
            </div>
            {lenderData?.states?.length !== 0 && (
              <div className="flex flex-col gap-2">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  States
                </span>
                <span
                  className="font-Segoe text-sm text-gray"
                  id="LenderStates"
                >
                  {lenderData?.states !== null && lenderData?.states.join(', ')}
                </span>
              </div>
            )}
            {accExecutors?.length > 0 && (
              <div className="flex flex-col gap-2">
                <span className="font-SegoeBold text-2xl font-bold text-blue">
                  Account Executives
                </span>
                <div className="flex flex-col gap-2">
                  {accExecutors?.map((accExecutor: any) => {
                    return (
                      <AccExecutor
                        key={accExecutor.id}
                        executor={accExecutor}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            <div className="flex flex-col gap-2">
              <span className="font-SegoeBold text-2xl font-bold text-blue">
                Loans Information
              </span>
              {lenderData?.loan_types?.length !== 0 && (
                <>
                  <span className="font-SegoeBold text-base font-bold text-blue">
                    Loan Types
                  </span>
                  <span
                    className="font-Segoe text-sm text-gray"
                    id="LenderLoanTypes"
                  >
                    {lenderData?.loan_types !== null &&
                      lenderData?.loan_types.join(', ')}
                  </span>
                </>
              )}
            </div>
            <div className="flex flex-col gap-2">
              {(lenderData?.fha_minimum_fico !== null ||
                lenderData?.va_minimum_fico !== null ||
                lenderData?.no_fico) && (
                <span className="font-SegoeBold text-base font-bold text-blue">
                  Fico Score Range
                </span>
              )}
              <div className="flex flex-col gap-3 md:flex-row">
                {lenderData?.no_fico && (
                  <FilterDisplay
                    btnText="No FICO"
                    id="FilterNoFico"
                    icon={<></>}
                    className="filterNotButton h-[31px] cursor-auto !bg-[#223C78] font-Segoe text-white"
                  ></FilterDisplay>
                )}

                {lenderData?.fha_minimum_fico !== null &&
                  lenderData?.va_minimum_fico !== undefined &&
                  lenderData?.fha_minimum_fico >= 0 && (
                    <FilterDisplay
                      btnText={`FHA Minimun: ${lenderData?.fha_minimum_fico}`}
                      icon={<></>}
                      id="FilterFHAMinimum"
                      className="filterNotButton h-[31px] cursor-auto !bg-[#223C78] font-Segoe text-white"
                    ></FilterDisplay>
                  )}
                {lenderData?.va_minimum_fico !== null &&
                  lenderData?.va_minimum_fico !== undefined &&
                  lenderData?.va_minimum_fico >= 0 && (
                    <FilterDisplay
                      btnText={`VA Minimun: ${lenderData?.va_minimum_fico}`}
                      id="FilterVAMinimum"
                      icon={<></>}
                      className="filterNotButton h-[31px] cursor-auto !bg-[#223C78] font-Segoe text-white"
                    ></FilterDisplay>
                  )}
              </div>
              <div className="flex flex-col gap-2">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  Revenue
                </span>
                <div className="grid grid-cols-2">
                  {/* <div className="flex flex-col gap-2">
                    <span className="font-SegoeBold text-base font-bold text-blue">
                      LPC
                    </span>
                    <span
                      className="font-Segoe text-sm text-gray"
                      id="LenderLPC"
                    >
                      % {lenderData?.lpc_points} + $ {lenderData?.lpc_flat_fee}
                    </span>
                  </div> */}
                  {/* <div className="flex flex-col gap-2">
                    <span className="font-SegoeBold text-base font-bold text-blue">
                      BPC
                    </span>
                    <span
                      className="font-Segoe text-sm text-gray"
                      id="LenderBPC"
                    >
                      {lenderData?.bpc_conditions_unit}{' '}
                      {lenderData?.bpc_conditions_value}
                    </span>
                  </div> */}
                  <span
                    className="font-Segoe text-sm text-gray"
                    id="ReveneuNotes"
                  >
                    {lenderData?.revenue_notes}
                  </span>
                </div>
              </div>
              {lenderData?.comp_types?.length !== 0 && (
                <div className="flex flex-col gap-2">
                  <span className="font-SegoeBold text-base font-bold text-blue">
                    Comp Types
                  </span>
                  <div className="grid grid-cols-2">
                    <span
                      className="font-Segoe text-sm text-gray"
                      id="CompTypes"
                    >
                      {lenderData?.comp_types?.join(', ')}
                    </span>
                  </div>
                </div>
              )}
              {lenderData?.epo_days && (
                <div className="flex flex-col gap-2">
                  <span className="font-SegoeBold text-base font-bold text-blue">
                    EPO Days
                  </span>
                  <div className="grid grid-cols-2">
                    <span className="font-Segoe text-sm text-gray" id="EpoDays">
                      {lenderData?.epo_days}
                    </span>
                  </div>
                </div>
              )}
              {lenderData?.epo_policy && (
                <div className="flex flex-col gap-2">
                  <span className="font-SegoeBold text-base font-bold text-blue">
                    EPO Policy
                  </span>
                  <span
                    className="font-Segoe text-sm text-gray"
                    id="LenderEpoPolicy"
                  >
                    {lenderData?.epo_policy}
                  </span>
                </div>
              )}
              {(lenderData?.lock_n_shop ||
                lenderData?.manual_uw ||
                lenderData?.tdb_underwrite ||
                lenderData?.loan_sifter ||
                lenderData?.lender_price) && (
                <div className="flex flex-col">
                  <span className="font-SegoeBold text-base font-bold text-blue">
                    Other information
                  </span>
                  <ul className="list-inside list-disc">
                    {lenderData?.lock_n_shop && (
                      <li
                        className="font-SegoeBold text-base font-bold text-gray"
                        id="LenderLockNShop"
                      >
                        Lock - N - Shop
                      </li>
                    )}
                    {/* {lenderData?.manual_uw && (
                      <li
                        className="font-SegoeBold text-base font-bold text-gray"
                        id="LenderManualUW"
                      >
                        Manual UW
                      </li>
                    )} */}
                    {lenderData?.tdb_underwrite && (
                      <li
                        className="font-SegoeBold text-base font-bold text-gray"
                        id="LenderTDB"
                      >
                        TBD Underwrite
                      </li>
                    )}
                    {lenderData?.loan_sifter && (
                      <li
                        className="font-SegoeBold text-base font-bold text-gray"
                        id="LenderLoanSifter"
                      >
                        Loan Sifter
                      </li>
                    )}
                    {lenderData?.lender_price && (
                      <li
                        className="font-SegoeBold text-base font-bold text-gray"
                        id="LenderPrice"
                      >
                        Lender Price
                      </li>
                    )}
                  </ul>
                </div>
              )}
              {lenderData?.more_about &&
                lenderData?.more_about !== '<p><br></p>' && (
                  <div className="flex flex-col gap-2 pb-5 md:pb-0">
                    <span className="font-SegoeBold text-2xl font-bold text-blue">
                      More About {lenderData?.name}
                    </span>
                    <span
                      className="font-Segoe text-base text-gray"
                      id="MoreAbout"
                      dangerouslySetInnerHTML={{
                        __html: `${lenderData?.more_about}`,
                      }}
                    ></span>
                  </div>
                )}
            </div>
          </div>
        </div>
        <LoaderOverlay loading={lender.loading} />
        <NewReview
          lender_id={id || ''}
          user_id={userData?.id || ''}
          user_name={`${userData?.first_name} ${userData?.last_name}` || ''}
          lenderName={lenderData?.name || ''}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          myReviews={myReviews}
          setMyReviews={setMyReviews}
        />
      </div>
    </>
  );
}
