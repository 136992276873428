import { SimpleGrid, Text } from '@mantine/core';

import H2 from '../Texts/H2';

interface RowsInfoProps {
  title: string;
  text: string;
}

interface ProfileGridProps {
  rowsInfo1: RowsInfoProps[];
}

export default function ProfileGrid({ rowsInfo1 }: ProfileGridProps) {
  return (
    <div className="flex flex-row gap-4">
      <div className="w-2/4 lg:w-1/4">
        <H2 text="Basic Information" fontSize="20px" fontWeight={600} />
      </div>
      <div className="hidden lg:block">
        <SimpleGrid cols={3}>
          {rowsInfo1.map((r, i) => {
            return (
              <div className="pr-3" style={{ width: '100%' }} key={i}>
                <div className="pb-2">
                  <H2 text={r.title} fontSize="16px" fontWeight={600} />
                </div>
                <div className="pb-5">
                  <Text
                    style={{
                      color: '#737588',
                      fontFamily: '',
                      fontSize: '16px',
                    }}
                  >
                    {' '}
                    {r.text}{' '}
                  </Text>
                </div>
              </div>
            );
          })}
        </SimpleGrid>
      </div>
      <div className="block lg:hidden">
        <SimpleGrid cols={1}>
          {rowsInfo1.map((r, i) => {
            return (
              <div style={{ width: '100%' }} key={i}>
                <div className="pb-2">
                  <H2 text={r.title} fontSize="16px" fontWeight={600} />
                </div>
                <div className="pb-5">
                  <Text
                    style={{
                      color: '#737588',
                      fontFamily: '',
                      fontSize: '16px',
                    }}
                  >
                    {' '}
                    {r.text}{' '}
                  </Text>
                </div>
              </div>
            );
          })}
        </SimpleGrid>
      </div>
    </div>
  );
}
