import { Checkbox } from '@mantine/core';

interface GeneralCBProps {
  setChecked?: (checked: boolean) => void;
  disabled?: boolean;
}

export default function GeneralCB({ setChecked, disabled }: GeneralCBProps) {
  return (
    <Checkbox
      color={'red'}
      onChange={(event) =>
        setChecked && setChecked(event.currentTarget.checked)
      }
      disabled={disabled}
    />
  );
}
