import Rating from '../Icons/Stars';

interface LenderProps {
  author?: string;
  picture?: string;
  rating?: number;
  timestamp?: string;
  commentary?: string;
}

function formatTimestamp(dateString: string) {
  // Convert string to Date object
  const date = new Date(dateString);

  // Define month names
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Get month, day, and year
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  // Function to get the day suffix
  function getDaySuffix(whichDay: number) {
    if (whichDay >= 11 && day <= 13) {
      return 'th';
    }
    switch (whichDay % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  }

  // Get day suffix
  const daySuffix = getDaySuffix(day);

  // Formatted date string
  return `${month} ${day}${daySuffix}, ${year}`;
}

export default function Review({
  author = 'John Doe',
  picture = 'https://i1.wp.com/cdn.auth0.com/avatars/jo.png?ssl=1',
  rating = 4,
  timestamp = 'August 1st, 2023',
  commentary,
}: LenderProps) {
  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-2">
          <img
            src={picture}
            className="h-7 w-7 rounded-full object-cover"
          ></img>
          <span className="font-Segoe text-black">{author}</span>
        </div>
        <span className="font-Segoe text-base italic text-gray">
          {formatTimestamp(timestamp)}
        </span>
      </div>
      {commentary && (
        <div className="my-2">
          <span
            id="ReviewCommentary"
            className="font-Segoe text-base text-gray"
          >
            {commentary}
          </span>
        </div>
      )}
      <div className="mt-2 flex flex-row gap-3">
        <span id="rated" className="font-Segoe text-base italic text-gray">
          - rated {rating} Stars
        </span>
        <Rating rating={rating} />
      </div>
      <div className={`separator mx-auto my-3 w-full`}></div>
    </div>
  );
}
