import { Button } from '@mantine/core';

import BtnText from '../Texts/BtnText';

interface IconBtnProps {
  btnText: string;
  fontSize: string;
  className?: string;
  icon?: JSX.Element | null;
  onClick?: (() => any) | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
  id?: string;
  loading?: boolean | undefined;
  disabled?: boolean;
}

export default function IconBtn({
  btnText,
  fontSize,
  className = 'bg-[#DFF1FF] text-[#223C78] hover:bg-[#223C78] hover:text-white font-Segoe',
  icon = null,
  type = 'button',
  onClick = undefined,
  id = '',
  loading = false,
  disabled = false,
}: IconBtnProps) {
  return (
    <Button
      id={id}
      leftIcon={icon}
      className={className}
      onClick={onClick}
      loading={loading}
      loaderProps={{
        color: 'blue',
      }}
      type={type}
      disabled={disabled}
    >
      <BtnText text={`${btnText}`} fontSize={`${fontSize}`} weight={400} />
    </Button>
  );
}
