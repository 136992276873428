import { Text } from '@mantine/core';

interface LinkProps {
  text: string;
  fontSize: string;
}

export default function Link({ text, fontSize }: LinkProps) {
  return (
    <>
      <Text
        weight={400}
        underline
        style={{ color: '#2BACE2', fontFamily: '', fontSize }}
      >
        {text}
      </Text>
    </>
  );
}
