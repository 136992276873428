const ExternalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
    >
      <path
        d="M12.7083 8.125V11.875C12.7083 12.2065 12.5766 12.5245 12.3422 12.7589C12.1078 12.9933 11.7898 13.125 11.4583 13.125H3.95831C3.62679 13.125 3.30885 12.9933 3.07443 12.7589C2.84001 12.5245 2.70831 12.2065 2.70831 11.875V4.375C2.70831 4.04348 2.84001 3.72554 3.07443 3.49112C3.30885 3.2567 3.62679 3.125 3.95831 3.125H7.70831V4.375H3.95831V11.875H11.4583V8.125H12.7083ZM8.95831 1.875V3.125H11.8246L6.95394 7.99563L7.83769 8.87937L12.7083 4.00875V6.875H13.9583V1.875H8.95831Z"
        className="fill-current"
      />
    </svg>
  );
};

export default ExternalIcon;
