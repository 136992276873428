import { Text } from '@mantine/core';

interface H2Props {
  text: string;
  fontSize: string;
  fontWeight: number;
}

export default function H2({ text, fontSize, fontWeight }: H2Props) {
  return (
    <>
      <Text
        weight={fontWeight}
        style={{ color: '#223C78', fontFamily: '', fontSize }}
      >
        {text}
      </Text>
    </>
  );
}
