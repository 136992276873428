import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query allUsers($limit: Int!, $offset: Int!) {
    users(limit: $limit, offset: $offset) {
      email
      first_name
      last_name
      role
      id
    }
  }
`;

export const GET_USER_AUTH0 = gql`
  query user($id: ID!) {
    userAuth0(id: $id) {
      id
      first_name
      last_name
      email
      phone
      address
      state
      city
      zipcode
    }
  }
`;

export const GET_USER = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      first_name
      last_name
      email
      role
    }
  }
`;

export const GET_USER_ROLE = gql`
  query user($id: ID!) {
    userRole(id: $id)
  }
`;

export const GET_SEARCH_MASS_LOGS = gql`
  query Logs($limit: Int!, $offset: Int) {
    searchMassLogs(limit: $limit, offset: $offset) {
      id
      user_id
      user
      message {
        badNumbers
        cleanNumbers
        totalCount
        badCount
        cleanCount
        duplicateCount
        duration
        filename
        states
        scrub
        invalidCount
        invalidNumbers
        failed
        outputFileFormat
      }
      timestamp
      type
    }
  }
`;

export const GET_LOGS = gql`
  query Logs($limit: Int!, $offset: Int) {
    logs(limit: $limit, offset: $offset) {
      id
      user_id
      user
      message
      timestamp
      type
      sublogs {
        information
        timestamp
      }
    }
  }
`;

export const GET_SEARCH_MASS_LOGS_COUNT = gql`
  query Count {
    searchMassLogsCount
  }
`;

export const GET_LOGS_COUNT = gql`
  query Count {
    logsCount
  }
`;

export const GET_USERS_COUNT = gql`
  query Count {
    usersCount
  }
`;

export const GET_ALL_ROLES = gql`
  query roles {
    getAllRoles {
      name
      id
    }
  }
`;

export const GET_LENDERS_TABLE = gql`
  query GetLendersTable {
    getLendersTable {
      id
      name
      lender_picture
      lender_price
      epo_days
      comp_types
      short_description
      more_about
      featured
      website
      states
      loan_types
      no_fico
      fha_minimum_fico
      va_minimum_fico
      lpc_points
      lpc_flat_fee
      bpc_conditions_unit
      bpc_conditions_value
      lock_n_shop
      manual_uw
      tdb_underwrite
      loan_sifter
      average_review_score
      total_votes
      phone
      email
      epo_policy
      broker_id
      revenue_notes
      fha
      va
      fees
      cpl_clause
      mortgagee_clause
      business_main_address
      timestamp
      va_id
      fha_id
      manual_uw_fha_score
      account_executors {
        id
        full_name
        phone
        email
        lender_id
      }
    }
  }
`;

export const EXPORT_LENDERS_TABLE = gql`
  query ExportLendersTable {
    exportLendersTable {
      key
    }
  }
`;

export const GET_LENDERS = gql`
  query GetLenders(
    $name: String
    $loanType: [String]
    $epo_days: Int
    $compType: [String]
    $state: [String]
    $NoFico: Boolean
    $FHAMinimum: Int
    $VAMinimum: Int
    $LockNShop: Boolean
    $ManualUW: Boolean
    $TDBUnderwrite: Boolean
    $LoanSifter: Boolean
    $LenderPrice: Boolean
    $MinRating: Float
    $sortingOption: String
    $offset: Int!
    $limit: Int!
  ) {
    getLenders(
      name: $name
      loanType: $loanType
      epo_days: $epo_days
      compType: $compType
      state: $state
      NoFico: $NoFico
      FHAMinimum: $FHAMinimum
      VAMinimum: $VAMinimum
      LockNShop: $LockNShop
      ManualUW: $ManualUW
      TDBUnderwrite: $TDBUnderwrite
      LoanSifter: $LoanSifter
      LenderPrice: $LenderPrice
      MinRating: $MinRating
      sortingOption: $sortingOption
      limit: $limit
      offset: $offset
    ) {
      lenders {
        id
        name
        lender_picture
        short_description
        more_about
        featured
        website
        states
        loan_types
        no_fico
        fha_minimum_fico
        va_minimum_fico
        lpc_points
        lpc_flat_fee
        bpc_conditions_unit
        bpc_conditions_value
        lock_n_shop
        manual_uw
        tdb_underwrite
        loan_sifter
        average_review_score
        total_votes
        phone
        email
        epo_policy
        broker_id
        revenue_notes
        fha
        va
        cpl_clause
        mortgagee_clause
      }
      count
    }
  }
`;

export const GET_LENDERS_BYID = gql`
  query getLendersById($id: String!) {
    getLendersById(id: $id) {
      id
      name
      lender_picture
      short_description
      more_about
      featured
      website
      states
      loan_types
      no_fico
      fha_minimum_fico
      va_minimum_fico
      lpc_points
      lpc_flat_fee
      bpc_conditions_unit
      bpc_conditions_value
      lock_n_shop
      manual_uw
      tdb_underwrite
      loan_sifter
      average_review_score
      total_votes
      phone
      email
      lender_price
      epo_days
      comp_types
      epo_policy
      broker_id
      timestamp
      revenue_notes
      fha_id
      va_id
      manual_uw_fha_score
      myRatings {
        id
        rating
        message
        user_id
        timestamp
        user_full_name
        user_picture
      }
    }
  }
`;

export const GET_REVIEWS_BYID = gql`
  query getReviewsById(
    $id: String!
    $userId: String!
    $limit: Int!
    $offset: Int!
  ) {
    getReviewsById(id: $id, userId: $userId, limit: $limit, offset: $offset) {
      id
      rating
      message
      user_id
      timestamp
      user_full_name
      user_picture
    }
  }
`;

export const GET_ACCEXECUTORS_BYID = gql`
  query getAccExecutorsById($id: String!) {
    getAccExecutorsById(id: $id) {
      id
      full_name
      phone
      email
      lender_id
      selected
    }
  }
`;

export const GET_LOANS = gql`
  query getLoans(
    $processor_name: String
    $lo_name: String
    $borrower_name: String
    $investor_name: String
    $loan_status: [Int]
    $offset: Int
    $limit: Int
    $sortingOption: String
  ) {
    getLoans(
      processor_name: $processor_name
      lo_name: $lo_name
      borrower_name: $borrower_name
      investor_name: $investor_name
      loan_status: $loan_status
      offset: $offset
      limit: $limit
      sortingOption: $sortingOption
    ) {
      loans {
        id
        arive_id
        borrower_names
        processor_names
        lo_name
        investor_names
        locked
        statuses {
          id
          loan_id
          status
          start_date
          end_date
          ended
        }
      }
      count
    }
  }
`;

export const GET_LOAN_BY_ID = gql`
  query getLoanById($id: String!) {
    getLoanById(id: $id) {
      id
      arive_id
      loan_type
      loan_amount
      ltv
      note_rate
      mortgage_type
      property_address
      property_type
      arive_url
      text1
      text2
    }
  }
`;

export const GET_EPO_DAYS = gql`
  query GetEpoDays {
    getEpoDays
  }
`;
