import '../../App.css';

import { useMutation } from '@apollo/client';
import { Avatar, Button, Group, Select, Text, TextInput } from '@mantine/core';
import { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ChevronDown } from 'tabler-icons-react';

import { COMPARE_SINGLE } from '../../graphql/mutations';
import { ROUTES } from '../../routes/config';
import FilledBtn from '../Buttons/FilledBtn';
import LeftTextBtn from '../Buttons/LeftTextBtn';
import SingleScrubForm from '../Forms/SingleScrubForm';
import SignleScrubTable from '../Tables/SignleScrubTable';
import H1 from '../Texts/H1';
import H2 from '../Texts/H2';
import Paragraph from '../Texts/Paragraph';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
}

interface IForm {
  input: string;
  type: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div>
          <Text style={{ color: '#656565', fontFamily: '', fontSize: '16px' }}>
            {label}
          </Text>
        </div>
      </Group>
    </div>
  )
);

export default function SingleScrub() {
  const {
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { errors },
  } = useForm<IForm>();
  const [searched, setSearched] = useState('');
  const rightSection = (
    <Button
      leftIcon={<Avatar src={'/svg/search.svg'} size={12} />}
      variant="white"
    ></Button>
  );

  const [scrub, { data, loading }] = useMutation(COMPARE_SINGLE);

  useEffect(() => reset({ input: '', type: 'all' }), []);

  const onSearch = (dataSearch: any) => {
    setSearched(dataSearch.input);
    scrub({ variables: { input: dataSearch.input, type: dataSearch.type } });
  };

  const getPhoneError = (type?: string) => {
    switch (type) {
      case 'required':
        return 'Phone field is required';
      case 'minLength':
      case 'maxLength':
        return 'Provide a 10-digit phone';
      case 'validate':
        return '10 digits for a phone search or 4 letters of a name search required';
      default:
        return null;
    }
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="flex">
          <H2 text="Phone " fontSize="32px" fontWeight={400} />
          <div className="pl-2">
            <H1 text="Scrub" fontSize="32px" />
          </div>
        </div>
      </div>
      <div className="pt-2">
        <Paragraph text="You can search single phone number" />
      </div>
      <div className="ml-[-20px] py-2">
        <LeftTextBtn link={ROUTES.PHONE_SCRUB} btnText="Back to List" />
      </div>
      {/* <div className='pb-4'>
                                <Paragraph text='To search a phone number you need to input at least 7 digits of the phone number or 4 letters of a name.'/>
                            </div> */}
      <form style={{ width: '70%' }} onSubmit={handleSubmit(onSearch)}>
        <Group position="left" spacing="sm" grow align="flex-start">
          {/* <SearchInput width='242px'/> */}
          <TextInput
            placeholder="Search phone/name"
            radius="xl"
            error={getPhoneError(errors?.input?.type || '')}
            style={{ width: '242px', color: '#223C78' }}
            rightSection={rightSection}
            {...register('input', {
              required: true,
              validate: (val) => {
                if (/^[a-z]+$/i.test(val)) return val.length >= 4;
                return val.length >= 10;
              },
            })}
          />
          <Select
            placeholder="Pick one..."
            itemComponent={SelectItem}
            defaultValue={'all'}
            data={[
              { label: 'All statuses', value: 'all' },
              { label: 'TCPA', value: 'tcpa' },
              { label: 'Federal DNC', value: 'dnc_fed' },
              { label: 'DNC Complainers', value: 'dnc' },
            ]}
            maxDropdownHeight={400}
            radius="xl"
            rightSection={<ChevronDown size={14} />}
            rightSectionWidth={30}
            styles={{
              rightSection: { pointerEvents: 'none' },
              item: {
                '&[data-selected]': {
                  background: '#e8f5ff',
                },
                '&[data-selected]:hover': {
                  background: '#e8f5ff',
                },
              },
            }}
            onChange={(val) => setValue('type', val || 'all')}
          />
          <FilledBtn
            width="167px"
            height="36px"
            bgColor="#223C78"
            hoverColor="#2BACE2"
            btnText="Search"
            fontSize="16px"
            loading={loading}
            type={loading ? 'button' : 'submit'}
          />
        </Group>
        <div className="pt-4">
          {data && data.compareSingle && (
            <SignleScrubTable
              input={searched}
              titleOptions={[
                { text: 'Name', fontSize: '14px' },
                { text: 'Phone', fontSize: '14px' },
                { text: 'Clean', fontSize: '14px' },
                { text: 'Status', fontSize: '14px' },
              ]}
              elements={[...data.compareSingle]}
            />
          )}
        </div>
      </form>
      <div>
        <SingleScrubForm />
      </div>
    </>
  );
}
