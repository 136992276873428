interface IPlusOpaqueIcon {
  className?: string;
}

const PlusOpaqueIcon = ({ className = 'fill-current' }: IPlusOpaqueIcon) => {
  return (
    <svg
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.625 0.75H0.875C0.642936 0.75 0.420376 0.842187 0.256282 1.00628C0.0921872 1.17038 0 1.39294 0 1.625V10.375C0 10.6071 0.0921872 10.8296 0.256282 10.9937C0.420376 11.1578 0.642936 11.25 0.875 11.25H9.625C9.85706 11.25 10.0796 11.1578 10.2437 10.9937C10.4078 10.8296 10.5 10.6071 10.5 10.375V1.625C10.5 1.39294 10.4078 1.17038 10.2437 1.00628C10.0796 0.842187 9.85706 0.75 9.625 0.75ZM8.3125 6.4375H5.6875V9.0625C5.6875 9.17853 5.64141 9.28981 5.55936 9.37186C5.47731 9.45391 5.36603 9.5 5.25 9.5C5.13397 9.5 5.02269 9.45391 4.94064 9.37186C4.85859 9.28981 4.8125 9.17853 4.8125 9.0625V6.4375H2.1875C2.07147 6.4375 1.96019 6.39141 1.87814 6.30936C1.79609 6.22731 1.75 6.11603 1.75 6C1.75 5.88397 1.79609 5.77269 1.87814 5.69064C1.96019 5.60859 2.07147 5.5625 2.1875 5.5625H4.8125V2.9375C4.8125 2.82147 4.85859 2.71019 4.94064 2.62814C5.02269 2.54609 5.13397 2.5 5.25 2.5C5.36603 2.5 5.47731 2.54609 5.55936 2.62814C5.64141 2.71019 5.6875 2.82147 5.6875 2.9375V5.5625H8.3125C8.42853 5.5625 8.53981 5.60859 8.62186 5.69064C8.70391 5.77269 8.75 5.88397 8.75 6C8.75 6.11603 8.70391 6.22731 8.62186 6.30936C8.53981 6.39141 8.42853 6.4375 8.3125 6.4375Z"
        className={className}
      />
    </svg>
  );
};

export default PlusOpaqueIcon;
