import { Text } from '@mantine/core';

interface ParagraphProps {
  text: string;
}

export default function Paragraph({ text }: ParagraphProps) {
  return (
    <>
      <Text style={{ color: '#626262', fontFamily: '', fontSize: '16px' }}>
        {text}
      </Text>
    </>
  );
}
