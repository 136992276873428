import type { ColDef } from 'ag-grid-community';

import deleteCellRenderer from '../cellRenderers/deleteCellRenderer';

type AccountExecutorsColumnsType = (onDeleteClick: any) => ColDef[];

export const AccountExecutorsColumns: AccountExecutorsColumnsType = (
  onDeleteClick
) => [
  {
    headerName: 'Account Executive Name',
    field: 'full_name',
    editable: true,
    minWidth: 200,
  },
  {
    headerName: 'Phone',
    field: 'phone',
    editable: true,
    minWidth: 200,
  },
  {
    headerName: 'Email',
    field: 'email',
    editable: true,
    minWidth: 320,
  },
  {
    headerName: 'Delete',
    cellRenderer: deleteCellRenderer,
    cellRendererParams: {
      onClick: onDeleteClick,
    },
    valueGetter: (params: any) => {
      return params;
    },
    maxWidth: 80,
  },
];
