const UsersIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
    >
      <path
        d="M13.9816 10.1005V9.23807C13.9726 8.88655 13.8264 8.553 13.5749 8.31068C13.3235 8.06836 12.9874 7.93708 12.6406 7.94568H10.3196C9.57951 7.94568 7.82849 7.08387 9.0631 7.08387H12.6406C13.219 7.06966 13.7793 7.2888 14.1983 7.69312C14.6173 8.09745 14.8608 8.65385 14.8751 9.24003V10.1018C14.869 10.2177 14.8192 10.3268 14.7361 10.4066C14.653 10.4865 14.5429 10.531 14.4284 10.531C14.3138 10.531 14.2037 10.4865 14.1206 10.4066C14.0375 10.3268 13.9877 10.2177 13.9816 10.1018V10.1005ZM8.6189 4.19919C8.6189 3.7469 8.75124 3.30476 8.99918 2.92869C9.24713 2.55262 9.59954 2.25951 10.0119 2.08643C10.4242 1.91334 10.8779 1.86806 11.3156 1.9563C11.7533 2.04453 12.1554 2.26233 12.4709 2.58215C12.7865 2.90197 13.0014 3.30945 13.0885 3.75305C13.1756 4.19665 13.1309 4.65646 12.9601 5.07432C12.7893 5.49219 12.5001 5.84934 12.129 6.10062C11.7579 6.3519 11.3217 6.48603 10.8754 6.48603C10.2769 6.48603 9.70297 6.24509 9.2798 5.81623C8.85663 5.38736 8.6189 4.8057 8.6189 4.19919ZM9.52149 4.19919C9.52149 4.47057 9.60089 4.73585 9.74966 4.96149C9.89842 5.18713 10.1099 5.36299 10.3573 5.46685C10.6047 5.5707 10.8769 5.59787 11.1395 5.54493C11.4021 5.49198 11.6434 5.3613 11.8327 5.16941C12.0221 4.97752 12.151 4.73304 12.2032 4.46687C12.2555 4.20071 12.2287 3.92483 12.1262 3.67411C12.0237 3.42339 11.8502 3.2091 11.6276 3.05833C11.4049 2.90756 11.1431 2.82709 10.8754 2.82709C10.5163 2.82709 10.1719 2.97165 9.91803 3.22897C9.66413 3.48629 9.52149 3.83529 9.52149 4.19919Z"
        fill="#809FB8"
      />
      <path
        d="M9.17196 10.0083V8.9629C9.16132 8.53651 8.98409 8.13184 8.67921 7.8378C8.37433 7.54377 7.96675 7.38443 7.54601 7.39478H3.20777C2.78697 7.38442 2.37932 7.54375 2.07433 7.83776C1.76935 8.13178 1.59198 8.53645 1.58117 8.9629V10.0083C1.57763 10.1505 1.51852 10.2854 1.41685 10.3834C1.31518 10.4814 1.17926 10.5345 1.03898 10.531C0.899244 10.5336 0.764156 10.4802 0.663183 10.3822C0.562211 10.2843 0.503551 10.1499 0.5 10.0083L0.5 8.9629C0.517739 8.25263 0.812825 7.57852 1.32049 7.08853C1.82815 6.59854 2.50691 6.3327 3.20777 6.34937H7.54665C7.8938 6.34068 8.23924 6.40141 8.56322 6.52807C8.88721 6.65473 9.18339 6.84484 9.43485 7.08755C9.68632 7.33026 9.88812 7.6208 10.0287 7.94258C10.1694 8.26436 10.2461 8.61107 10.2544 8.9629V10.0083C10.2462 10.1483 10.1855 10.2798 10.0848 10.3759C9.98405 10.4721 9.85091 10.5256 9.71255 10.5256C9.57418 10.5256 9.44104 10.4721 9.34032 10.3759C9.23961 10.2798 9.17892 10.1483 9.17067 10.0083H9.17196ZM2.66751 2.69043C2.66751 2.17353 2.81875 1.66823 3.10212 1.23843C3.38548 0.808642 3.78824 0.47366 4.25946 0.275848C4.73068 0.0780367 5.2492 0.0262802 5.74944 0.127124C6.24968 0.227967 6.70919 0.476881 7.06984 0.84239C7.4305 1.2079 7.67611 1.67358 7.77561 2.18056C7.87512 2.68753 7.82405 3.21303 7.62886 3.69059C7.43368 4.16814 7.10314 4.57632 6.67906 4.8635C6.25497 5.15068 5.75638 5.30396 5.24634 5.30396C4.56239 5.30396 3.90645 5.02861 3.42283 4.53847C2.9392 4.04834 2.66751 3.38358 2.66751 2.69043ZM3.69904 2.69043C3.69904 3.00058 3.78978 3.30376 3.9598 3.56163C4.12982 3.81951 4.37148 4.02049 4.65421 4.13918C4.93694 4.25787 5.24805 4.28892 5.5482 4.22842C5.84834 4.16791 6.12405 4.01856 6.34044 3.79926C6.55683 3.57995 6.7042 3.30054 6.7639 2.99636C6.8236 2.69217 6.79296 2.37688 6.67585 2.09034C6.55874 1.8038 6.36042 1.5589 6.10597 1.38659C5.85152 1.21428 5.55236 1.12232 5.24634 1.12232C4.83608 1.12249 4.44268 1.28778 4.15265 1.58184C3.86261 1.8759 3.69904 2.27465 3.69904 2.69043Z"
        fill="#809FB8"
      />
    </svg>
  );
};

export default UsersIcon;
