/* eslint-disable */

export const removeLogs = (function () {
  const savedConsole = console;
  /**
   * @param {boolean} debugOn
   * @param {boolean} suppressAll
   */
  return function (debugOn: boolean, suppressAll: boolean) {
    const suppress = suppressAll || false;
    if (debugOn === false) {
      // supress the default console functionality
      // @ts-ignore
      console = {};
      console.log = () => {};
      // supress all type of consoles
      if (suppress) {
        console.info = () => {};
        console.warn = () => {};
        console.error = () => {};
      } else {
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    } else {
      console = savedConsole;
    }
  };
})();
