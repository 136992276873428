import '../../App.css';

import { useMutation } from '@apollo/client';
import { Modal } from '@mantine/core';
import RichTextEditor from '@mantine/rte';
import { useEffect, useState } from 'react';

import { UPDATE_LENDER } from '../../graphql/mutations';
import IconBtn from '../Buttons/IconBtn';
import SaveIcon from '../Icons/Save';
import H1 from '../Texts/H1';

export type DescriptionPopupData = {
  id: string;
  name: string;
  [key: string]: string;
};

type DescriptionPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  data: DescriptionPopupData | null;
  title: string;
  field: string;
};

const DescriptionPopup = ({
  isOpen,
  onClose,
  onSave,
  data,
  title,
  field,
}: DescriptionPopupProps) => {
  const [titleDisplay, setTitle] = useState('');
  const [updateLender] = useMutation(UPDATE_LENDER);
  const [description, setDescription] = useState<string>(data?.[field] || '');
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setDescription(data?.[field] || '');
  }, [data?.[field]]);

  const handleClosePopup = () => {
    onClose();
  };

  const handleSaveDescription = async () => {
    if (saving) return;
    setSaving(true);
    await updateLender({
      variables: {
        id: data?.id,
        [field]: description,
      },
    });
    await onSave();
    setSaving(false);
  };

  useEffect(() => {
    if (data?.name && title) setTitle(`${data?.name} - ${title}`);
  }, [data?.name, title]);

  return (
    <Modal opened={isOpen} onClose={handleClosePopup} size="auto" centered>
      <div id="filterDialog" className="">
        <div className="m-5 w-[800px]">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-between">
              <H1 text={titleDisplay} fontSize="24px" />
              <IconBtn
                btnText="Save"
                onClick={handleSaveDescription}
                loading={saving}
                id="SubmitBtn"
                fontSize="16px"
                icon={<SaveIcon />}
                className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
              />
            </div>
            <RichTextEditor
              defaultValue={description}
              value={description}
              className="briefDescription"
              onChange={(value) => {
                setDescription(value);
              }}
              styles={{
                root: {
                  display: 'flex',
                  flexDirection: 'column-reverse',
                  borderColor: '#DFF1FF',
                  borderRadius: '10px',
                },
              }}
              controls={[
                [
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  'unorderedList',
                  'orderedList',
                ],
              ]}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DescriptionPopup;
