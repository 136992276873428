import { Select } from '@mantine/core';

import SortByIcon from '../Icons/SortBy';
import type { FilterType } from '../pages/Lenders';

interface DataValue {
  label: string;
  value: string;
}

interface SortByLenderProps {
  className?: string;
  data?: DataValue[];
  placeholder?: string;
  setFilters: React.Dispatch<React.SetStateAction<FilterType>>; // Update the type here
  filters: FilterType;
}

const dataValues = [
  { value: 'az', label: 'Alphabetical (A-Z)' },
  { value: 'za', label: 'Alphabetical (Z-A)' },
  { value: 'pda', label: 'Rate (ASC)' },
  { value: 'pdd', label: 'Rate (DES)' },
  { value: 'mvda', label: 'Votes (ASC)' },
  { value: 'mvdd', label: 'Votes (DES)' },
];

export default function SortByLender({
  data = dataValues,
  className = '',
  placeholder = 'Sort By',
  setFilters,
  filters,
}: SortByLenderProps) {
  return (
    <Select
      data={data}
      size="xs"
      radius="md"
      placeholder={placeholder}
      icon={<SortByIcon></SortByIcon>}
      className={className}
      value={filters.sortingOption}
      onChange={(value) => setFilters({ ...filters, sortingOption: value })}
      styles={() => ({
        defaultVariant: {
          background: '#223C78',
          border: 'none',
        },
        input: {
          '&::placeholder': {
            color: '#fff',
            fontSize: '16px',
          },
          color: '#fff',
          fontSize: '16px',
          textAlign: 'center',
          background: 'transparent',
          border: 'none',
        },
        root: {
          background: '#223C78',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          alignContent: 'center',
          width: '190px',
          height: '36px',
        },
        rightSection: {
          display: 'none',
        },
      })}
    />
  );
}
