interface IPlusIcon {
  className?: string;
}

const PlusIcon = ({ className = 'fill-current' }: IPlusIcon) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M12.6666 7.83342H8.66663V3.83341C8.66663 3.6566 8.59639 3.48703 8.47136 3.36201C8.34634 3.23699 8.17677 3.16675 7.99996 3.16675C7.82315 3.16675 7.65358 3.23699 7.52855 3.36201C7.40353 3.48703 7.33329 3.6566 7.33329 3.83341V7.83342H3.33329C3.15648 7.83342 2.98691 7.90365 2.86189 8.02868C2.73686 8.1537 2.66663 8.32327 2.66663 8.50008C2.66663 8.67689 2.73686 8.84646 2.86189 8.97149C2.98691 9.09651 3.15648 9.16675 3.33329 9.16675H7.33329V13.1667C7.33329 13.3436 7.40353 13.5131 7.52855 13.6382C7.65358 13.7632 7.82315 13.8334 7.99996 13.8334C8.17677 13.8334 8.34634 13.7632 8.47136 13.6382C8.59639 13.5131 8.66663 13.3436 8.66663 13.1667V9.16675H12.6666C12.8434 9.16675 13.013 9.09651 13.138 8.97149C13.2631 8.84646 13.3333 8.67689 13.3333 8.50008C13.3333 8.32327 13.2631 8.1537 13.138 8.02868C13.013 7.90365 12.8434 7.83342 12.6666 7.83342Z"
        className={className}
      />
    </svg>
  );
};

export default PlusIcon;
