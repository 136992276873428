const SaveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2 4.14276C2 3.68052 2.18362 3.23722 2.51047 2.91037C2.83732 2.58352 3.28062 2.3999 3.74286 2.3999H11.0446C11.5068 2.4 11.95 2.58369 12.2768 2.91056L13.6893 4.32314C14.0162 4.64992 14.1999 5.09315 14.2 5.55535V12.857C14.2 13.3193 14.0164 13.7626 13.6895 14.0894C13.3627 14.4163 12.9194 14.5999 12.4571 14.5999H3.74286C3.28062 14.5999 2.83732 14.4163 2.51047 14.0894C2.18362 13.7626 2 13.3193 2 12.857V4.14276ZM3.74286 3.27133C3.51174 3.27133 3.29009 3.36314 3.12666 3.52657C2.96324 3.68999 2.87143 3.91164 2.87143 4.14276V12.857C2.87143 13.0882 2.96324 13.3098 3.12666 13.4732C3.29009 13.6367 3.51174 13.7285 3.74286 13.7285V9.80704C3.74286 9.46037 3.88057 9.12789 4.12571 8.88276C4.37085 8.63762 4.70332 8.4999 5.05 8.4999H11.15C11.4967 8.4999 11.8292 8.63762 12.0743 8.88276C12.3194 9.12789 12.4571 9.46037 12.4571 9.80704V13.7285C12.6883 13.7285 12.9099 13.6367 13.0733 13.4732C13.2368 13.3098 13.3286 13.0882 13.3286 12.857V5.55535C13.3285 5.32425 13.2367 5.10263 13.0732 4.93925L11.6607 3.52666C11.4973 3.36322 11.2757 3.27138 11.0446 3.27133H10.7143V5.4499C10.7143 5.79658 10.5766 6.12906 10.3314 6.37419C10.0863 6.61933 9.75382 6.75705 9.40714 6.75705H5.92143C5.57475 6.75705 5.24228 6.61933 4.99714 6.37419C4.752 6.12906 4.61429 5.79658 4.61429 5.4499V3.27133H3.74286ZM5.48571 3.27133V5.4499C5.48571 5.56546 5.53162 5.67629 5.61333 5.758C5.69504 5.83971 5.80587 5.88562 5.92143 5.88562H9.40714C9.5227 5.88562 9.63353 5.83971 9.71524 5.758C9.79695 5.67629 9.84286 5.56546 9.84286 5.4499V3.27133H5.48571ZM11.5857 13.7285V9.80704C11.5857 9.69149 11.5398 9.58066 11.4581 9.49895C11.3764 9.41724 11.2656 9.37133 11.15 9.37133H5.05C4.93444 9.37133 4.82362 9.41724 4.7419 9.49895C4.66019 9.58066 4.61429 9.69149 4.61429 9.80704V13.7285H11.5857Z"
        className="fill-current"
      />
    </svg>
  );
};

export default SaveIcon;
