const ReviewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M8 4.60962L8.956 6.46112L11 6.75812L9.5 8.10962L10 10.1096L8 8.98462L6 10.1096L6.5 8.10962L5 6.75812L7.1 6.46112L8 4.60962Z"
        className="fill-current"
      />
      <path
        d="M8.868 15.6096L8 15.1096L10 11.6096H13C13.1314 11.6098 13.2615 11.5841 13.3829 11.5339C13.5043 11.4837 13.6146 11.4101 13.7075 11.3172C13.8004 11.2243 13.8741 11.114 13.9243 10.9925C13.9745 10.8711 14.0002 10.741 14 10.6096V4.60962C14.0002 4.47824 13.9745 4.34812 13.9243 4.2267C13.8741 4.10529 13.8004 3.99497 13.7075 3.90207C13.6146 3.80917 13.5043 3.73552 13.3829 3.68534C13.2615 3.63515 13.1314 3.60942 13 3.60962H3C2.86862 3.60942 2.7385 3.63515 2.61708 3.68534C2.49567 3.73552 2.38535 3.80917 2.29245 3.90207C2.19956 3.99497 2.1259 4.10529 2.07572 4.2267C2.02553 4.34812 1.9998 4.47824 2 4.60962V10.6096C1.9998 10.741 2.02553 10.8711 2.07572 10.9925C2.1259 11.114 2.19956 11.2243 2.29245 11.3172C2.38535 11.4101 2.49567 11.4837 2.61708 11.5339C2.7385 11.5841 2.86862 11.6098 3 11.6096H7.5V12.6096H3C2.46957 12.6096 1.96086 12.3989 1.58579 12.0238C1.21071 11.6488 1 11.1401 1 10.6096V4.60962C0.999934 4.34696 1.05162 4.08686 1.15211 3.84417C1.25259 3.60149 1.39991 3.38099 1.58564 3.19526C1.77137 3.00953 1.99187 2.86221 2.23456 2.76173C2.47724 2.66124 2.73734 2.60955 3 2.60962H13C13.2627 2.60955 13.5228 2.66124 13.7654 2.76173C14.0081 2.86221 14.2286 3.00953 14.4144 3.19526C14.6001 3.38099 14.7474 3.60149 14.8479 3.84417C14.9484 4.08686 15.0001 4.34696 15 4.60962V10.6096C15 11.1401 14.7893 11.6488 14.4142 12.0238C14.0391 12.3989 13.5304 12.6096 13 12.6096H10.5825L8.868 15.6096Z"
        className="fill-current"
      />
    </svg>
  );
};

export default ReviewIcon;
