import { useMutation } from '@apollo/client';
import { Box, Button, Loader, SimpleGrid, TextInput } from '@mantine/core';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { ADD_CONTACT } from '../../graphql/mutations';

interface ScrubForm {
  name: string;
  phone: string;
  email: string;
}

export default function SingleScrubForm() {
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ScrubForm>();
  const [addContact, { loading }] = useMutation(ADD_CONTACT);
  const onSubmit = (data: ScrubForm) => {
    setSuccess(false);
    addContact({
      variables: { ...data },
      onCompleted: () => {
        reset();
        setSuccess(true);
      },
    });
  };
  const getPhoneError = (type?: string) => {
    switch (type) {
      case 'required':
        return 'Phone field is required';
      case 'minLength':
      case 'maxLength':
        return 'Provide a 10-digit phone';
      case 'validate':
        return 'Invalid phone number';
      default:
        return null;
    }
  };
  return (
    <div className="pt-5">
      <Button
        className="mb-5 bg-skyBlue"
        onClick={() => setShow(!show)}
        type="submit"
        radius={'lg'}
      >
        <div className="flex items-center gap-3">
          Add phone number to internal DNC
          <img src="/svg/plus.svg" alt="" />
        </div>
      </Button>

      {show && (
        <div>
          <Box sx={{ maxWidth: 1045 }}>
            <p className="pb-2">
              Exclude prefixes, parentheses, dashes, and spaces from phone
              number search
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <SimpleGrid cols={4} spacing="lg">
                {/* <div><TextInput placeholder="Phone number" radius="md"/></div>
                            <div><FormDD/></div> */}
                <div>
                  <TextInput
                    {...register('name')}
                    placeholder="Name"
                    radius="md"
                  />
                </div>
                <div>
                  <TextInput
                    {...register('phone', {
                      required: true,
                      minLength: 10,
                      maxLength: 10,
                      validate: (val) => /^[0-9]+$/.test(val),
                    })}
                    error={getPhoneError(errors?.phone?.type)}
                    placeholder="Phone Number"
                    radius="md"
                  />
                </div>
                <div>
                  <TextInput
                    {...register('email')}
                    placeholder="Email"
                    radius="md"
                  />
                </div>
                <Button
                  className="bg-skyBlue"
                  type="submit"
                  radius={'lg'}
                  style={{ width: '167px' }}
                >
                  {loading ? (
                    <Loader size={'sm'} sx={() => ({ stroke: 'white' })} />
                  ) : (
                    'Add'
                  )}
                </Button>
              </SimpleGrid>
              {success && (
                <p className="pt-2 text-sm text-green">
                  Contact added succesfully
                </p>
              )}
            </form>
          </Box>
        </div>
      )}
    </div>
  );
}
