import '../../App.css';

import { useMutation } from '@apollo/client';
import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useFilePicker } from 'use-file-picker';

import { UPDATE_LENDER, UPLOAD_IMAGE } from '../../graphql/mutations';
import IconBtn from '../Buttons/IconBtn';
import SaveIcon from '../Icons/Save';
import H1 from '../Texts/H1';

export type ProfilePicturePopupData = {
  id: string;
  name: string;
  lender_picture: string;
};

type ProfilePicturePopupProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  data: ProfilePicturePopupData | null;
};

const ProfilePicturePopup = ({
  isOpen,
  onClose,
  onSave,
  data,
}: ProfilePicturePopupProps) => {
  const [title, setTitle] = useState('');
  const [updateLender] = useMutation(UPDATE_LENDER);
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const [picture, setPicture] = useState<string>(data?.lender_picture || '');
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setPicture(data?.lender_picture || '');
  }, [data?.lender_picture]);

  const handleClosePopup = () => {
    onClose();
  };

  const handleSavePicture = async () => {
    if (saving) return;
    setSaving(true);
    await updateLender({
      variables: {
        id: data?.id,
        lender_picture: picture,
      },
    });
    await onSave();
    setSaving(false);
  };

  const [openFileSelector] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    onFilesSuccessfulySelected: async ({ plainFiles }) => {
      const url = await uploadImage({ variables: { file: plainFiles[0] } });
      setPicture(url.data.uploadImage);
    },
  });

  useEffect(() => {
    if (data?.name) setTitle(`${data?.name} - Profile Picture`);
  }, [data?.name]);

  return (
    <Modal opened={isOpen} onClose={handleClosePopup} centered size="auto">
      <div id="filterDialog" className="">
        <div className="m-5 w-[800px]">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-between">
              <H1 text={title} fontSize="24px" />
              <IconBtn
                btnText="Save"
                onClick={handleSavePicture}
                loading={saving}
                id="SubmitBtn"
                fontSize="16px"
                icon={<SaveIcon />}
                className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
              />
            </div>
            <div className="mb-10 flex items-center">
              <div className="relative flex rounded-lg border border-[#DFF1FF]">
                <img
                  alt=""
                  id="LenderImage"
                  src={picture}
                  className="!max-h-[800px] object-cover"
                />
                <a
                  id="uploadImageBtn"
                  className="absolute bottom-2 right-2 cursor-pointer"
                  onClick={openFileSelector}
                >
                  <img src="/Images/edit-lender.svg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProfilePicturePopup;
