import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

import LoaderOverlay from '../Overlays/LoaderOverlay';

export const Logout = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    logout({ returnTo: process.env.REACT_APP_PUBLIC_URL });
  }, []);

  return <LoaderOverlay loading={true} />;
};
