const DownArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_452_652)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.8833 13.3833C10.6489 13.6174 10.3312 13.7489 9.99998 13.7489C9.66873 13.7489 9.35102 13.6174 9.11664 13.3833L4.40164 8.66999C4.16726 8.43549 4.03563 8.11749 4.03571 7.78594C4.03578 7.4544 4.16757 7.13646 4.40206 6.90207C4.63656 6.66769 4.95456 6.53605 5.28611 6.53613C5.61765 6.53621 5.93559 6.66799 6.16998 6.90249L9.99998 10.7325L13.83 6.90249C14.0656 6.67468 14.3813 6.54853 14.7091 6.55123C15.0368 6.55392 15.3504 6.68523 15.5823 6.91688C15.8141 7.14854 15.9457 7.46199 15.9487 7.78974C15.9517 8.11748 15.8259 8.4333 15.5983 8.66916L10.8841 13.3842L10.8833 13.3833Z"
          fill="#809FB8"
        />
      </g>
      <defs>
        <clipPath id="clip0_452_652">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DownArrowIcon;
