import { Button } from '@mantine/core';

import CallIcon from '../Icons/Call';
import BtnText from '../Texts/BtnText';

interface LenderBtnProps {
  btnText: string;
  fontSize: string;
  className?: string;
  icon?: JSX.Element;
  url?: string;
  disabled?: boolean;
  id?: string;
}

export default function LenderBtn({
  btnText,
  fontSize,
  url = '/',
  className = `!bg-lightBlue hover:!bg-blue hover:text-white text-blue h-[34px] !font-Segoe !font-normal items-center px-5 w-full ${
    url === '/' ? 'hidden' : ''
  }`,
  icon = <CallIcon />,
  disabled = false,
  id = '',
}: LenderBtnProps) {
  return (
    <Button
      leftIcon={icon}
      id={id}
      className={className}
      radius="lg"
      size="sm"
      disabled={disabled}
      onClick={(e: any) => {
        e.preventDefault();
        window.open(url);
      }}
    >
      <BtnText text={`${btnText}`} fontSize={`${fontSize}`} weight={600} />
    </Button>
  );
}
