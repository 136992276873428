import { Modal } from '@mantine/core';

import type { AddResult } from '../pages/MassScrubStep2';
import GeneralTable from '../Tables/GeneralTable';
import H2 from '../Texts/H2';

interface ILogModal {
  opened: boolean;
  close: () => void;
  res: AddResult | null;
}

const AddPhonesModal = ({ opened, close, res }: ILogModal) => {
  return (
    <Modal
      opened={opened}
      title={<H2 text="Add Phone Results" fontSize="32px" fontWeight={300} />}
      size="xl"
      onClose={() => {
        close();
      }}
      centered
    >
      <div className="pb-5">
        <p>
          Total Phones on File:{' '}
          {res ? res.addedCount + res.invalidCount + res.duplicateCount : 0}
        </p>
        <p>Added Phones: {res?.addedCount}</p>
        <p>Duplicate Phones: {res?.duplicateCount}</p>
        <p>Invalid Phones: {res?.invalidCount}</p>
      </div>
      {res?.invalidNumbers && res?.invalidNumbers?.length > 0 && (
        <GeneralTable
          titleOptions={[
            { text: 'Name', fontSize: '14px', accessor: 'name' },
            { text: 'Phone', fontSize: '14px', accessor: 'phone' },
            { text: 'Email', fontSize: '14px', accessor: 'email' },
          ]}
          loading={false}
          elements={res?.invalidNumbers || []}
        />
      )}
    </Modal>
  );
};

export default AddPhonesModal;
