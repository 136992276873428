import { Button } from '@mantine/core';
import type { ICellRendererParams } from 'ag-grid-community';

export default (props: ICellRendererParams) => {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const { onClick, icon } = props as any;
  return (
    <span className="flex w-full justify-between items-center">
      <span className="flex-initial truncate">{cellValue}</span>
      &nbsp;
      <Button
        className="font-Segoe text-blue hover:bg-blue hover:text-white"
        // @ts-ignore: Incorrect type definition
        size="compact-xs"
        onClick={() => onClick(props)}
      >
        {icon}
      </Button>
    </span>
  );
};
