import '../../App.css';

import { useMutation, useQuery } from '@apollo/client';
import { Checkbox, Input, MultiSelect, TextInput } from '@mantine/core';
import { RichTextEditor } from '@mantine/rte';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useFilePicker } from 'use-file-picker';

import { LOAN_TYPES } from '../../config/constants';
import {
  DELETE_ACCEXECUTOR,
  DELETE_LENDER,
  UPDATE_LENDER,
  UPDATE_OR_CREATE_ACCEXECUTOR,
  UPLOAD_IMAGE,
} from '../../graphql/mutations';
import {
  GET_ACCEXECUTORS_BYID,
  GET_EPO_DAYS,
  GET_LENDERS_BYID,
} from '../../graphql/queries';
import { usePermissions } from '../../hooks/usePermissions';
import { ROUTES } from '../../routes/config';
import FilterDisplay from '../Buttons/FilterDisplay';
import IconBtn from '../Buttons/IconBtn';
import LeftTextBtnClick from '../Buttons/LeftTextBtnClick';
import AccExecutor from '../Cards/AccExecutor';
import LenderMultiEdit from '../Dropdowns/MultiSelectLenderEdit';
import DeleteIcon from '../Icons/Delete';
import DownArrowIcon from '../Icons/DownArrow';
import FeaturedIcon from '../Icons/Featured';
import PlusIcon from '../Icons/Plus';
import SaveIcon from '../Icons/Save';
import LoaderOverlay from '../Overlays/LoaderOverlay';
import PopUp from '../Overlays/PopUp';

interface Lender {
  id: string;
  name: string;
  lender_picture: string;
  short_description: string;
  epo_days: string[] | null | string;
  comp_types: string[];
  lender_price: boolean;
  featured: boolean;
  website: string;
  states: [string];
  loan_types: [string];
  no_fico: boolean;
  fha_minimum_fico: number;
  va_minimum_fico: number;
  lpc_points: number;
  lpc_flat_fee: number;
  bpc_conditions_unit: string;
  bpc_conditions_value: number;
  lock_n_shop: boolean;
  manual_uw: boolean;
  tdb_underwrite: boolean;
  loan_sifter: boolean;
  average_review_score: number;
  total_votes: number;
  phone: string;
  email: string;
  more_about: string;
}

type FormData = {
  name: string;
  rating: number;
  featured: boolean;
  description: string;
  website: string;
  more_about: string;
  FHAMinimum: boolean;
  fha_minimum_fico: number | null;
  VAMinimum: boolean;
  va_minimum_fico: number | null;
  no_fico: boolean;
  lpc_points: number;
  lpc_flat_fee: number;
  bpc_conditions_unit: string;
  bpc_conditions_value: number;
  lock_n_shop: boolean;
  manual_uw: boolean;
  tdb_underwrite: boolean;
  loan_sifter: boolean;
  epo_policy: string;
  broker_id: string;
  revenue_notes: string;
  lender_price: boolean;
  epo_days: string[] | null | string;
  comp_types: string[];
};

export default function EditLender() {
  const { hasAdminPermissions } = usePermissions();

  const { id } = useParams();
  const [lenderData, setLenderData] = useState<Lender>();
  const [saving, setSaving] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [accExecutors, setAccExecutors] = useState<any>([]);
  const [epoDayData, setepoDay] = useState([{ value: '0', label: '0' }]);
  const [deletedExecutors, setDeletedExecutors] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [epoDayState, setepoDayState] = useState<any>([]);
  const [loanTypes, setLoanTypes] = useState<any>([]);
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const [updateLender] = useMutation(UPDATE_LENDER);
  const [deleteAccExecutor] = useMutation(DELETE_ACCEXECUTOR);
  const [deleteLender, { loading: deleteLenderLoading }] =
    useMutation(DELETE_LENDER);
  const [updateOrCreateAccExecutor] = useMutation(UPDATE_OR_CREATE_ACCEXECUTOR);
  const [isOpen, setIsOpen] = useState(false);
  const [discard, setDiscard] = useState(false);

  const [newUrl, setNewUrl] = useState<string>(
    lenderData?.lender_picture || ''
  );
  const navigate = useNavigate();

  const [openFileSelector] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    onFilesSuccessfulySelected: async ({ plainFiles }) => {
      const url = await uploadImage({ variables: { file: plainFiles[0] } });
      setNewUrl(url.data.uploadImage);
    },
  });

  useQuery(GET_EPO_DAYS, {
    onCompleted: (data) => {
      const epoData = Object.values(data.getEpoDays);
      // @ts-ignore
      const epoDB = [];
      epoData.forEach((item: any) => {
        if (item !== null) {
          epoDB.push({ value: String(item), label: String(item) });
        }
      });
      // @ts-ignore
      setepoDay(epoDB);
    },
    fetchPolicy: 'no-cache',
  });

  const lender = useQuery(GET_LENDERS_BYID, {
    variables: { id },
    onCompleted: (data) => setLenderData(data?.getLendersById),
    fetchPolicy: 'no-cache',
  });

  const executors = useQuery(GET_ACCEXECUTORS_BYID, {
    variables: {
      id,
    },
    onCompleted: (data) => setAccExecutors(data?.getAccExecutorsById),
    fetchPolicy: 'no-cache',
  });

  const deleteAccExecutors = async (executor: any) => {
    await deleteAccExecutor({
      variables: { id: executor.id },
    });
  };

  const deleteLenderClick = async () => {
    if (deleting) return;
    setDeleting(true);
    await deleteLender({
      variables: { id },
    });
    setIsOpen(false);
    navigate(ROUTES.LENDERS);
  };

  const returnBack = async () => {
    navigate(ROUTES.LENDERS);
  };

  const onSubmit = async (data: FormData) => {
    if (saving) return;
    try {
      const updatedLenderData = {
        id,
        name: data.name || lenderData?.name,
        lender_picture: newUrl || lenderData?.lender_picture,
        short_description: data.description,
        more_about: data.more_about,
        featured: data.featured,
        website: data.website || lenderData?.website,
        states: states || lenderData?.states,
        loan_types: loanTypes || lenderData?.loan_types,
        no_fico: data.no_fico,
        fha_minimum_fico: data.FHAMinimum
          ? Number(data.fha_minimum_fico)
          : null,
        va_minimum_fico: data.VAMinimum ? Number(data.va_minimum_fico) : null,
        lpc_points: Number(data.lpc_points) || lenderData?.lpc_points,
        lpc_flat_fee: Number(data.lpc_flat_fee) || lenderData?.lpc_flat_fee,
        bpc_conditions_unit:
          data.bpc_conditions_unit || lenderData?.bpc_conditions_unit,
        bpc_conditions_value:
          Number(data.bpc_conditions_value) || lenderData?.bpc_conditions_value,
        lock_n_shop: data.lock_n_shop,
        manual_uw: data.manual_uw,
        tdb_underwrite: data.tdb_underwrite,
        loan_sifter: data.loan_sifter,
        epo_policy: data.epo_policy,
        broker_id: data.broker_id,
        revenue_notes: data.revenue_notes,
        lender_price: data.lender_price,
        epo_days: data.epo_days?.length === 0 ? null : Number(data.epo_days),
        comp_types: data.comp_types,
      };
      setSaving(true);
      updateLender({
        variables: updatedLenderData,
      }).then(async () => {
        accExecutors.forEach(async (executor: any) => {
          await updateOrCreateAccExecutor({
            variables: {
              id: executor.id,
              full_name: executor.full_name,
              phone: executor.phone,
              email: executor.email,
              lender_id: executor.lender_id,
              selected: executor.selected,
            },
          });
        });

        deletedExecutors.forEach(async (executor: any) => {
          await deleteAccExecutors(executor);
        });
        // eslint-disable-next-line no-new, no-promise-executor-return, @typescript-eslint/no-implied-eval
        await new Promise((resolve) => setTimeout(resolve, 1500));
        navigate(ROUTES.LENDER.replace(':id', id || ''));
      });

      // console.log('Lender data updated successfully');
    } catch (error) {
      // console.error('Error updating lender data', error);
      navigate(ROUTES.LENDERS);
    }
  };

  const createNewAccExecutor = () => {
    const newAccExecutor = {
      id: `new${Math.random().toString(36)}`,
      full_name: '',
      phone: '',
      email: '',
      selected: accExecutors.length === 0,
      lender_id: id,
    };
    setAccExecutors([...accExecutors, newAccExecutor]);
  };

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      featured: lenderData?.featured,
      description: lenderData?.short_description,
      more_about: lenderData?.more_about,
    },
  });

  const descriptionRef = useRef<any>();
  const moreAboutRef = useRef<any>();

  useEffect(() => {
    if (lenderData) {
      setNewUrl(lenderData?.lender_picture || '');
      setStates(lenderData?.states || []);
      setLoanTypes(lenderData?.loan_types || []);
      reset({ ...lenderData });
      setepoDayState([String(lenderData?.epo_days)]);
      setValue('epo_days', [String(lenderData?.epo_days)]);
      if (
        lenderData?.fha_minimum_fico !== null &&
        lenderData?.fha_minimum_fico > -1
      ) {
        setValue('FHAMinimum', true);
      }
      if (
        lenderData?.va_minimum_fico !== null &&
        lenderData?.va_minimum_fico > -1
      ) {
        setValue('VAMinimum', true);
      }
      descriptionRef.current.editor.clipboard.dangerouslyPasteHTML(
        lenderData.short_description
      );
      moreAboutRef.current.editor.clipboard.dangerouslyPasteHTML(
        lenderData.more_about
      );
    }
  }, [lenderData]);

  const featured = watch('featured');
  const FHAMinimum = watch('FHAMinimum');
  const VAMinimum = watch('VAMinimum');
  // const LPCFlatFee = watch('lpc_flat_fee');
  const description = watch('description');
  const moreAbout = watch('more_about');
  const compTypes = watch('comp_types');

  // const BpcConditionsUnit = watch('bpc_conditions_unit');

  return (
    <>
      <div className="ml-[-20px] py-2">
        <LeftTextBtnClick btnText="Lenders" onClick={() => setDiscard(true)} />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="pb-10">
        {lenderData && (
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="flex flex-row gap-3">
              <TextInput
                placeholder={lenderData?.name}
                variant="unstyled"
                id="LenderNameEdit"
                radius="xs"
                {...register('name')}
                size="xl"
                styles={{
                  input: {
                    '&::placeholder': {
                      color: '#223C78',
                      fontSize: '32px',
                      fontFamily: 'Segoe UI',
                      fontWeight: 'bold',
                    },
                    width: '260px',
                    color: '#223C78',
                    fontSize: '32px',
                    fontFamily: 'Segoe UI',
                    fontWeight: 'bold',
                  },
                }}
              />

              <div className={`flex items-center`}>
                <button
                  type="button"
                  id="featuredBtn"
                  onClick={() => setValue('featured', !featured)}
                >
                  <FeaturedIcon
                    className={`${featured ? 'fill-[#223C78]' : 'fill-none'}`}
                  ></FeaturedIcon>
                </button>
              </div>
            </div>
            <div className="mb-5 flex items-center justify-center gap-3 md:mb-0">
              <IconBtn
                btnText="Save"
                type="submit"
                loading={saving}
                id="SubmitBtn"
                fontSize="16px"
                icon={<SaveIcon />}
                className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
              />
              <IconBtn
                btnText="Delete"
                loading={deleting}
                fontSize="16px"
                id="DeleteBtn"
                onClick={() => setIsOpen(true)}
                icon={<DeleteIcon />}
                className="bg-[#AE0000] font-Segoe text-white hover:bg-blue hover:text-white"
              />
            </div>
          </div>
        )}
        <div className="grid w-full grid-cols-1 gap-5 pl-2 md:grid-cols-2 md:pl-0">
          <div className="flex h-full w-full flex-col ">
            <div className="mb-10 flex items-center">
              <div className="relative flex rounded-lg border border-[#DFF1FF]">
                <img
                  alt=""
                  id="LenderImage"
                  src={newUrl}
                  className="!max-h-[84px] !max-w-[343px] object-cover"
                />

                <a
                  id="uploadImageBtn"
                  className="absolute bottom-2 right-2 cursor-pointer"
                  onClick={openFileSelector}
                >
                  <img src="/Images/edit-lender.svg" />
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-SegoeBold text-base font-bold text-blue">
                Brief Description
              </span>
              <RichTextEditor
                defaultValue={description}
                value={description}
                ref={descriptionRef}
                className={'briefDescription'}
                onChange={(value) => {
                  setValue('description', value);
                }}
                styles={{
                  root: {
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    borderColor: '#DFF1FF',
                    borderRadius: '10px',
                  },
                }}
                controls={[
                  [
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'unorderedList',
                    'orderedList',
                  ],
                ]}
              />
            </div>
            <div className="mt-2 flex flex-col gap-2">
              <span className="font-SegoeBold text-base font-bold text-blue">
                Website
              </span>
              <TextInput
                variant="unstyled"
                id="LenderWebEdit"
                {...register('website', {
                  required: true,
                  pattern:
                    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
                  onChange: (e) => {
                    if (
                      e.target.value.length > 0 &&
                      !e.target.value.startsWith('https://')
                    ) {
                      if (e.target.value === 'https:/')
                        setValue('website', `https://`);
                      else setValue('website', `https://${e.target.value}`);
                    }
                  },
                })}
                styles={{
                  root: {
                    borderBottom: '1px solid #DFF1FF',
                  },
                }}
              />
              {errors.website?.type === 'required' && (
                <span className="font-Segoe text-sm text-[#DC2626]">
                  * Required
                </span>
              )}
              {errors.website?.type === 'pattern' && (
                <span className="font-Segoe text-sm text-[#DC2626]">
                  URL not valid
                </span>
              )}
            </div>
            <div className="mt-2 flex flex-col gap-2">
              <span className="font-SegoeBold text-2xl font-bold text-blue">
                More About {lenderData?.name}
              </span>
              <RichTextEditor
                ref={moreAboutRef}
                value={moreAbout}
                onChange={(value) => {
                  setValue('more_about', value);
                }}
                className={'moreAbout'}
                styles={{
                  root: {
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    borderColor: '#DFF1FF',
                    borderRadius: '10px',
                    height: '200px',
                    maxHeight: '200px',
                  },
                }}
                controls={[
                  [
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'unorderedList',
                    'orderedList',
                  ],
                ]}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <span className="font-SegoeBold text-2xl font-bold text-blue mb-3">
              Lender Information
            </span>
            <div className="pb-3">
              <span className="font-SegoeBold text-base font-bold text-blue">
                Broker ID
              </span>
              <TextInput
                variant="unstyled"
                id="BrokerIdNew"
                {...register('broker_id')}
                styles={{
                  root: {
                    borderBottom: '1px solid #DFF1FF',
                  },
                }}
              />
            </div>
            <div className="flex flex-col">
              <LenderMultiEdit
                placeholder="States"
                id="LenderStatesEdit"
                info={states}
                setInfo={setStates}
                edit={true}
              ></LenderMultiEdit>
              <div className="flex flex-row gap-2 mt-2 flex-wrap">
                {states?.length > 0 &&
                  states?.map((state: any) => {
                    return (
                      <FilterDisplay
                        key={state}
                        btnText={state}
                        deleteFilter={() => {
                          setStates(
                            states.filter((item: any) => item !== state)
                          );
                        }}
                      />
                    );
                  })}
              </div>
            </div>
            {accExecutors?.length > 0 && (
              <div className="flex flex-col gap-3 mt-3">
                <span className="font-SegoeBold text-2xl font-bold text-blue">
                  Account Executives
                </span>
                <div className="flex flex-col gap-2">
                  {accExecutors?.map((accExecutor: any) => {
                    return (
                      <AccExecutor
                        key={accExecutor.id}
                        edit={true}
                        executor={accExecutor}
                        setAccExecutors={setAccExecutors}
                        accExecutors={accExecutors}
                        deletedExecutors={deletedExecutors}
                        setDeletedExecutors={setDeletedExecutors}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                      />
                    );
                  })}
                </div>

                <div className="flex h-full w-full items-center justify-center">
                  <button type="button" onClick={createNewAccExecutor}>
                    <div className="flex h-5 w-5 items-center justify-center rounded-full bg-blue text-white">
                      <PlusIcon></PlusIcon>
                    </div>
                  </button>
                </div>
              </div>
            )}

            {accExecutors?.length === 0 && (
              <button type="button" onClick={createNewAccExecutor}>
                <div className="flex flex-col gap-3 mt-3">
                  <span className="flex items-start font-SegoeBold text-2xl font-bold  text-blue">
                    Account Executives
                  </span>
                  <div className="flex flex-row items-center justify-center gap-2">
                    <div className="flex h-5 w-5 items-center justify-center rounded-full bg-blue text-white">
                      <PlusIcon></PlusIcon>
                    </div>
                    <span className="font-Segoe text-base text-blue">
                      Add an Account Executor
                    </span>
                  </div>
                </div>
              </button>
            )}
            <div className="flex flex-col gap-3 mt-4">
              <span className="font-SegoeBold text-2xl font-bold text-blue">
                Loans Information
              </span>
              <div className="flex flex-col gap-2">
                <LenderMultiEdit
                  placeholder="Loan Types"
                  info={loanTypes}
                  id="LenderLoanTypesEdit"
                  setInfo={setLoanTypes}
                  data={LOAN_TYPES}
                  // createable={true}
                  edit={true}
                ></LenderMultiEdit>
                <div className="flex flex-row gap-2 flex-wrap">
                  {loanTypes?.length > 0 &&
                    loanTypes?.map((loanType: any) => {
                      return (
                        <FilterDisplay
                          key={loanType}
                          btnText={loanType}
                          deleteFilter={() => {
                            setLoanTypes(
                              loanTypes.filter((item: any) => item !== loanType)
                            );
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-3">
              <span className="font-SegoeBold text-base font-bold text-blue">
                Fico Score Range
              </span>
              <div className="flex flex-col gap-6">
                <Checkbox
                  label="No FICO"
                  size="sm"
                  id="NoFico"
                  {...register('no_fico')}
                  styles={{
                    label: {
                      color: '#223C78',
                      fontWeight: 'bold',
                    },
                  }}
                />
                <div className="flex flex-row gap-3">
                  <Checkbox
                    label="FHA Minimum FICO"
                    size="sm"
                    {...register('FHAMinimum')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  <div
                    className={`
                      flex items-center justify-center ${
                        FHAMinimum ? '' : 'hidden'
                      }`}
                  >
                    <Input
                      variant="unstyled"
                      id="FHAMinimumValue"
                      disabled={!FHAMinimum}
                      required={FHAMinimum}
                      {...register('fha_minimum_fico')}
                      size="xs"
                      styles={{
                        wrapper: {
                          width: '70px',
                        },
                        input: {
                          borderBottom: '1px solid #DFF1FF !important',
                          height: '14px !important',
                          minHeight: '14px !important',
                          color: '#626262',
                          '::placeholder': {
                            color: '#626262',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-3">
                  <Checkbox
                    label="VA Minimum FICO"
                    size="sm"
                    id="VAMinimum"
                    {...register('VAMinimum')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  <div
                    className={`flex items-center justify-center ${
                      VAMinimum ? '' : 'hidden'
                    }`}
                  >
                    <Input
                      variant="unstyled"
                      disabled={!VAMinimum}
                      id="VAMinimumValue"
                      {...register('va_minimum_fico')}
                      required={VAMinimum}
                      size="xs"
                      styles={{
                        wrapper: {
                          width: '70px',
                        },
                        input: {
                          borderBottom: '1px solid #DFF1FF !important',
                          height: '14px !important',
                          minHeight: '14px !important',
                          color: '#626262',
                          '::placeholder': {
                            color: '#626262',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-3">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  Revenue
                </span>
                <TextInput
                  variant="unstyled"
                  id="RevenueNotes"
                  {...register('revenue_notes')}
                  styles={{
                    root: {
                      borderBottom: '1px solid #DFF1FF',
                    },
                  }}
                />
                <span className="font-SegoeBold text-base font-bold text-blue">
                  Comp Type
                </span>
                <MultiSelect
                  data={[
                    { label: 'BPC', value: 'BPC' },
                    { label: 'LPC', value: 'LPC' },
                  ]}
                  radius="md"
                  rightSection={<DownArrowIcon />}
                  maxSelectedValues={999}
                  id="comp_typesMultiFilter"
                  value={compTypes || []}
                  onChange={(selectedValues) => {
                    setValue('comp_types', selectedValues);
                  }}
                  placeholder={'Comp Type'}
                  styles={{
                    input: {
                      border: '1px solid #27ABE2',
                      height: '36px !important',
                    },
                  }}
                />
                <span className="font-SegoeBold text-base font-bold text-blue">
                  EPO Days
                </span>
                <MultiSelect
                  data={epoDayData}
                  radius="md"
                  rightSection={<DownArrowIcon />}
                  maxSelectedValues={1}
                  id="EPO_DaysMultiFilter"
                  searchable
                  creatable={hasAdminPermissions()}
                  getCreateLabel={(query) => `+ Create ${query}`}
                  onCreate={(query) => {
                    const item = { value: query, label: query };
                    setepoDay((current) => [...current, item]);
                    return item;
                  }}
                  value={epoDayState}
                  onChange={(selectedValues) => {
                    setValue('epo_days', selectedValues);
                    setepoDayState(selectedValues);
                  }}
                  placeholder={'EPO Days'}
                  styles={{
                    input: {
                      border: '1px solid #27ABE2',
                      height: '36px !important',
                    },
                  }}
                />
                {/* <div className="grid grid-cols-2">
                  <div className="flex flex-col gap-2">
                    <span className="font-SegoeBold text-base font-bold text-blue">
                      LPC
                    </span>
                    <div className="grid w-36 grid-cols-2 gap-0">
                      <div className="relative">
                        <span className="font-Segoe text-sm text-[#626262]">
                          Points
                        </span>
                        <Input
                          variant="unstyled"
                          id="lpc_points"
                          placeholder={
                            lenderData?.lpc_points !== null
                              ? lenderData?.lpc_points.toString()
                              : ''
                          }
                          {...register('lpc_points')}
                          size="xs"
                          styles={{
                            wrapper: {
                              width: '50px',
                            },
                            input: {
                              borderBottom: '1px solid #DFF1FF !important',
                              height: '14px !important',
                              minHeight: '14px !important',
                              color: '#626262',
                              '::placeholder': {
                                color: '#626262',
                              },
                            },
                          }}
                        />
                        <div className="absolute right-2 top-2">
                          <span className="font-Segoe text-lg text-blue">
                            +
                          </span>
                        </div>
                      </div>
                      <div>
                        <span className="font-Segoe text-sm text-[#626262]">
                          Flat Fee
                        </span>
                        <Input
                          variant="unstyled"
                          id="lpc_flat_fee"
                          placeholder={
                            lenderData?.lpc_flat_fee !== null
                              ? lenderData?.lpc_flat_fee.toString()
                              : ''
                          }
                          value={`$${LPCFlatFee}`}
                          onChange={(e: any) => {
                            setValue(
                              'lpc_flat_fee',
                              Number(e.target.value.replace('$', ''))
                            );
                          }}
                          size="xs"
                          styles={{
                            wrapper: {
                              width: '50px',
                            },
                            input: {
                              borderBottom: '1px solid #DFF1FF !important',
                              height: '14px !important',
                              minHeight: '14px !important',
                              color: '#626262',
                              '::placeholder': {
                                color: '#626262',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className="font-SegoeBold text-base font-bold text-blue ">
                      BPC
                    </span>
                    <div className="flex flex-col gap-0">
                      <span className="font-Segoe text-sm text-[#626262]">
                        Conditions
                      </span>
                      <div className="flex flex-row items-end gap-2">
                        <Select
                          data={['$', '%']}
                          id="bpc_conditions_unit"
                          value={BpcConditionsUnit}
                          onChange={(value: string) =>
                            setValue('bpc_conditions_unit', value)
                          }
                          variant="unstyled"
                          styles={{
                            input: {
                              width: '50px',
                              // height: '20px !important',
                              border: '2px solid #223C78 !important',
                              borderRadius: '10px',
                              height: '24px !important',
                              minHeight: 'unset !important',
                              maxHeight: 'unset',
                              paddingRight: '10px',
                              paddingLeft: '10px',
                            },
                            rightSection: {
                              width: '20px',
                            },
                          }}
                        />
                        <Input
                          variant="unstyled"
                          placeholder={
                            lenderData?.bpc_conditions_value !== null
                              ? lenderData?.bpc_conditions_value.toString()
                              : ''
                          }
                          {...register('bpc_conditions_value')}
                          id="bpc_conditions_value"
                          size="xs"
                          styles={{
                            wrapper: {
                              width: '50px',
                            },
                            input: {
                              borderBottom: '1px solid #DFF1FF !important',
                              height: '14px !important',
                              minHeight: '14px !important',
                              color: '#626262',
                              '::placeholder': {
                                color: '#626262',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <span className="font-SegoeBold text-base font-bold text-blue">
                EPO Policy
              </span>
              <TextInput
                variant="unstyled"
                id="LenderEpoPolicyNew"
                {...register('epo_policy')}
                styles={{
                  root: {
                    borderBottom: '1px solid #DFF1FF',
                  },
                }}
              />
              <div className="flex flex-col mt-3">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  Other Filters
                </span>
                <div className="mt-2 flex flex-col gap-6 md:flex-row">
                  <Checkbox
                    label="Lock - N - Shop"
                    size="sm"
                    id="lock_n_shop"
                    {...register('lock_n_shop')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  {/* <Checkbox
                    label="Manual UW"
                    size="sm"
                    id="manual_uw"
                    {...register('manual_uw')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  /> */}
                  <Checkbox
                    label="TBD Underwrite"
                    size="sm"
                    id="tdb_underwrite"
                    {...register('tdb_underwrite')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  <Checkbox
                    label="Loan Sifter"
                    size="sm"
                    id="loan_sifter"
                    {...register('loan_sifter')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  <Checkbox
                    label="Lender Price"
                    size="sm"
                    id="lender_price"
                    {...register('lender_price')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <LoaderOverlay loading={lender.loading && executors.loading} />
      <PopUp
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        reset={deleteLenderClick}
        title="Are you sure you want to delete the selected lender?"
        acceptButton={
          <IconBtn
            btnText="Delete"
            fontSize="16px"
            id="DeletePopUp"
            loading={deleteLenderLoading}
            onClick={deleteLenderClick}
            icon={<DeleteIcon />}
            className="rounded-[10px] bg-[#AE0000] font-Segoe text-white hover:bg-blue"
          />
        }
      ></PopUp>
      <PopUp
        isOpen={discard}
        setIsOpen={setDiscard}
        reset={returnBack}
        title="Are you sure you want to discard the changes and return back?"
        description="All the changes will be permanently lost."
        acceptButton={
          <IconBtn
            btnText="Discard Changes"
            fontSize="16px"
            onClick={returnBack}
            icon={<DeleteIcon />}
            className="rounded-[10px] bg-[#AE0000] font-Segoe text-white hover:bg-blue"
          />
        }
      ></PopUp>
    </>
  );
}
