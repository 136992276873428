import { Button } from '@mantine/core';

import CrossIcon from '../Icons/Cross';
import BtnText from '../Texts/BtnText';

interface FilterDisplayProps {
  btnText: string;
  fontSize?: string;
  className?: string;
  icon?: JSX.Element;
  deleteFilter?: (filter: string, type: any) => any;
  id?: string;
}

export default function FilterDisplay({
  btnText = '',
  fontSize = '14px',
  className = '!bg-[#223C78] text-white h-[31px] font-Segoe',
  icon = <CrossIcon />,
  deleteFilter = () => {},
  id = 'filterDisplay',
}: FilterDisplayProps) {
  return (
    <Button
      rightIcon={icon}
      id={id}
      className={className}
      radius="lg"
      size="sm"
      onClick={() => deleteFilter(btnText, typeof btnText)}
    >
      <BtnText text={`${btnText}`} fontSize={`${fontSize}`} />
    </Button>
  );
}
