// components/Rating.tsx
import '../../App.css';

import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface StarProps {
  halfFilled?: boolean;
  unfilled?: boolean;
  onClick?: () => void; // Nueva prop onClick
  id?: string;
}

const Star: React.FC<StarProps> = ({ halfFilled, unfilled, onClick, id }) => {
  if (unfilled) {
    return (
      <a id={id} className="yellow-star" onClick={onClick}>
        <FontAwesomeIcon icon={faStarRegular as IconProp} />
      </a>
    );
  }

  const starIcon = halfFilled ? faStarHalfAlt : faStar;

  return (
    <a id={id} className="yellow-star" onClick={onClick}>
      <FontAwesomeIcon icon={starIcon as IconProp} />
    </a>
  );
};

interface RatingClickProps {
  selectedRating: number | null;
  setSelectedRating: React.Dispatch<React.SetStateAction<number | null>>;
}

const RatingClick: React.FC<RatingClickProps> = ({
  selectedRating,
  setSelectedRating,
}) => {
  const handleStarClick = (index: number) => {
    if (selectedRating === index + 1) {
      setSelectedRating(null);
    } else {
      setSelectedRating(index + 1);
    }
  };

  const renderStar = (index: number) => {
    const isFilled = index < (selectedRating || 0);
    const isHalfFilled = selectedRating === index + 0.5;

    return (
      <Star
        id={`Star${index}`}
        onClick={() => handleStarClick(index)}
        halfFilled={isHalfFilled}
        unfilled={!isFilled && !isHalfFilled}
      />
    );
  };

  return (
    <div className="flex items-center" id="ratingDiv">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="h-6 w-6">
          {renderStar(index)}
        </div>
      ))}
    </div>
  );
};

export default RatingClick;
