import { Avatar, Group, Text } from '@mantine/core';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { apolloErrorModal } from '../state/atoms';
import H2 from '../Texts/H2';
import Link from '../Texts/Link';

/* function getIconColor(status: DropzoneStatus, theme: MantineTheme) {
  return status.accepted
    ? theme.colors[theme.primaryColor][theme.colorScheme === 'dark' ? 4 : 6]
    : status.rejected
    ? theme.colors.red[theme.colorScheme === 'dark' ? 4 : 6]
    : theme.colorScheme === 'dark'
    ? theme.colors.dark[0]
    : theme.colors.gray[7];
}
function ImageUploadIcon({
  status,
  ...props
}: React.ComponentProps<TablerIcon> & { status: DropzoneStatus }) {
  if (status.accepted) {
    return <Upload {...props} />;
  }
  if (status.rejected) {
    return <X {...props} />;
  }
  return <Photo {...props} />;
} */

export const dropzoneChildren = () => (
  /* status: DropzoneStatus,
  theme: MantineTheme */
  <div>
    <Group
      position="center"
      spacing="xl"
      style={{ minHeight: 220, pointerEvents: 'none' }}
    >
      <div>
        <div className="flex justify-center pb-5">
          <Avatar src="/Images/folder.png" size={117} />
        </div>
        {/* <ImageUploadIcon status={status} style={{ color: getIconColor(status, theme) }} size={80} /> */}
        <div className="flex">
          <H2 text="Drag and drop" fontSize="18px" fontWeight={600} />
          <div className="pl-2">
            <H2 text="your files here or" fontSize="18px" fontWeight={400} />
          </div>
          <div className="pl-2">
            <Link text="browse" fontSize="18px" />
          </div>
        </div>
        <Text weight={600} align="center" size="lg" color="dimmed" mt={7}>
          File extensions: .xls, .xlsx, .csv
        </Text>
      </div>
    </Group>
  </div>
);

export default function DropZone({
  onDrop,
}: {
  onDrop: (files: File[]) => void;
}) {
  // const theme = useMantineTheme();
  const [loading, setLoading] = useState(false);
  const modal = useRecoilValue(apolloErrorModal);

  useEffect(() => {
    if (modal.show) setLoading(false);
  }, [modal.show, setLoading]);

  return (
    <div>
      <Dropzone
        loading={!modal.show && loading}
        onDrop={(files: any) => {
          onDrop(files);
          setLoading(true);
        }}
        onReject={(files: any) => console.log('rejected files', files)}
        maxSize={3 * 1024 ** 2}
        accept={[MIME_TYPES.csv, MIME_TYPES.xls, MIME_TYPES.xlsx]}
        styles={{
          root: {
            border: '2px dashed #2bace2',
            borderRadius: '24px',
            opacity: 1,
          },
        }}
      >
        {dropzoneChildren()}
      </Dropzone>
    </div>
  );
}
