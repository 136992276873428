import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { apolloErrorModal } from '../state/atoms';

function ApolloErrorModal() {
  const [modal, setModal] = useRecoilState(apolloErrorModal);
  const [title, setTitle] = useState('Error connecting to the API');
  const [error, setError] = useState(
    'An error ocurred during your petition. Please try again in a few minutes, if the error persists, contact us.'
  );
  const getErrorMessage = () => {
    let tooBig = false;
    modal.errors.forEach((e) => {
      if (e === 'File too big') tooBig = true;
    });
    if (tooBig) {
      setTitle('Uploaded file was too big');
      setError(
        'Try deleting all empty columns and rows and reupload the file or try uploading the file in csv format.'
      );
    }
  };

  useEffect(() => {
    getErrorMessage();
  }, [modal, getErrorMessage]);
  return (
    <div id="matine-modal-apollo" className="relative h-full w-full">
      <Modal
        opened={modal.show}
        onClose={() => setModal({ show: false, errors: [] })}
        title={title}
        centered
      >
        {error}
      </Modal>
    </div>
  );
}

export default ApolloErrorModal;
