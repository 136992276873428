import { useSetRecoilState } from 'recoil';

import ProfileDD from '../Dropdowns/ProfileDD';
import MobileMenu from '../Navigation/MobileMenu';
import { menuSidebarOpenState } from '../state/atoms';

interface MobileMenuProps {
  links: {
    link: string;
    color: string;
    text: string;
  }[];
}

export default function NavBar({ links }: MobileMenuProps) {
  const setMenuSidebarOpen = useSetRecoilState(menuSidebarOpenState);

  return (
    <div
      className="flex items-center justify-between md:justify-end"
      style={{ height: '80px' }}
    >
      <div
        className="cursor-pointer pl-5 md:hidden"
        onClick={() => setMenuSidebarOpen(true)}
      >
        <img src="/Images/logo.png" alt="" />
      </div>
      <MobileMenu links={links} />
      <div className="pr-5 md:pr-0">
        <ProfileDD />
      </div>
    </div>
  );
}
