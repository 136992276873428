import type { ColDef } from 'ag-grid-community';
import dayjs from 'dayjs';

import { getTextFromHTML } from '../../../utils/formatting';
import EditOpaqueIcon from '../../Icons/EditOpaque';
import PlusOpaqueIcon from '../../Icons/PlusOpaque';
import buttonCellRenderer from '../cellRenderers/buttonCellRenderer';
import deleteCellRenderer from '../cellRenderers/deleteCellRenderer';
import linkRenderer from '../cellRenderers/linkRenderer';

type GetLendersColumnsType = (
  onAccountExecutivesClick: any,
  onLoanTypesClick: any,
  onStatesClick: any,
  onCommentsClick: any,
  onDescriptionClick: any,
  onProfilePictureClick: any,
  onDeleteClick: any,
  onCompTypesClick: any,
  onEpoDaysClick: any
) => ColDef[];

export const getLendersColumns: GetLendersColumnsType = (
  onAccountExecutivesClick,
  onLoanTypesClick,
  onStatesClick,
  onCommentsClick,
  onDescriptionClick,
  onProfilePictureClick,
  onDeleteClick,
  onCompTypesClick
) => [
  { headerName: 'N#', field: 'index', maxWidth: 55, editable: false },
  {
    headerName: 'Delete',
    cellRenderer: deleteCellRenderer,
    cellRendererParams: {
      onClick: onDeleteClick,
    },
    valueGetter: (params: any) => {
      return params;
    },
    maxWidth: 80,
  },
  { headerName: 'Lender Name', field: 'name', editable: true },
  {
    headerName: 'Profile picture',
    field: 'lender_picture',
    valueFormatter: (params: any) => {
      return decodeURI(params.value?.split('/').pop());
    },
    cellRenderer: buttonCellRenderer,
    cellRendererParams: {
      onClick: onProfilePictureClick,
      icon: <EditOpaqueIcon />,
    },
  },
  {
    headerName: 'Brief description',
    field: 'short_description',
    valueFormatter: (params: any) => {
      return getTextFromHTML(params.value);
    },
    cellRenderer: buttonCellRenderer,
    cellRendererParams: {
      onClick: onDescriptionClick,
      icon: <EditOpaqueIcon />,
    },
  },
  {
    headerName: 'Account Executives',
    valueGetter: (params: any) => {
      return params.data.account_executors
        ?.map((accExecutor: any) => accExecutor.full_name)
        .join(', ');
    },
    cellRenderer: buttonCellRenderer,
    cellRendererParams: {
      onClick: onAccountExecutivesClick,
      icon: <PlusOpaqueIcon />,
    },
  },
  { headerName: 'Revenue Notes', field: 'revenue_notes', editable: true },
  {
    headerName: 'Comp Types',
    field: 'comp_types',
    cellRenderer: buttonCellRenderer,
    cellRendererParams: {
      onClick: onCompTypesClick,
      icon: <PlusOpaqueIcon />,
    },
  },
  {
    headerName: 'Epo Days',
    field: 'epo_days',
    editable: true,
    // cellRenderer: buttonCellRenderer,
    // cellRendererParams: {
    //   onClick: onEpoDaysClick,
    //   icon: <PlusOpaqueIcon />,
    // },
  },
  { headerName: 'EPO', field: 'epo_policy', editable: true },
  {
    headerName: 'Website',
    field: 'website',
    editable: true,
    cellRenderer: linkRenderer,
  },
  {
    headerName: 'Updated Date',
    field: 'timestamp',
    valueFormatter: (params: any) => {
      return dayjs(params.value).format('DD/MM/YYYY');
    },
  },
  { headerName: 'Broker ID', field: 'broker_id', editable: true },
  {
    headerName: 'Loan Types',
    valueGetter: (params: any) => {
      return params.data.loan_types?.join(', ');
    },
    cellRenderer: buttonCellRenderer,
    cellRendererParams: {
      onClick: onLoanTypesClick,
      icon: <PlusOpaqueIcon />,
    },
  },
  {
    headerName: 'TBD Underwrite',
    field: 'tdb_underwrite',
    cellDataType: 'boolean',
    width: 140,
    editable: true,
  },
  {
    headerName: 'Loan Sifter',
    field: 'loan_sifter',
    cellDataType: 'boolean',
    width: 140,
    editable: true,
  },
  {
    headerName: 'Lender Price',
    field: 'lender_price',
    cellDataType: 'boolean',
    width: 140,
    editable: true,
  },
  {
    headerName: 'States',
    valueGetter: (params: any) => {
      return params.data.states?.join(', ');
    },
    cellRenderer: buttonCellRenderer,
    cellRendererParams: {
      onClick: onStatesClick,
      icon: <PlusOpaqueIcon />,
    },
  },
  {
    headerName: 'Comments',
    field: 'more_about',
    valueFormatter: (params: any) => {
      return getTextFromHTML(params.value);
    },
    cellRenderer: buttonCellRenderer,
    cellRendererParams: {
      onClick: onCommentsClick,
      icon: <EditOpaqueIcon />,
    },
  },
  { headerName: 'Fees', field: 'fees', editable: true },
  { headerName: 'Mortgagee Clause', field: 'mortgagee_clause', editable: true },
  { headerName: 'CPL - Clause', field: 'cpl_clause', editable: true },
  {
    headerName: 'Business Main Address',
    field: 'business_main_address',
    editable: true,
  },
  // {
  //   headerName: 'FHA',
  //   field: 'fha',
  //   cellDataType: 'boolean',
  //   width: 100,
  //   editable: true,
  // },
  // {
  //   headerName: 'FHA Minimum FICO Score',
  //   field: 'fha_minimum_fico',
  //   cellDataType: 'number',
  //   editable: true,
  // },
  // {
  //   headerName: 'VA',
  //   field: 'va',
  //   cellDataType: 'boolean',
  //   width: 100,
  //   editable: true,
  // },
  // {
  //   headerName: 'VA Min Score',
  //   field: 'va_minimum_fico',
  //   cellDataType: 'number',
  //   editable: true,
  // },
  // {
  //   headerName: 'NO FICO',
  //   field: 'no_fico',
  //   cellDataType: 'boolean',
  //   width: 100,
  //   editable: true,
  // },
  // {
  //   headerName: 'Lock - N - Shop',
  //   field: 'lock_n_shop',
  //   cellDataType: 'boolean',
  //   width: 140,
  //   editable: true,
  // },
  // {
  //   headerName: 'Manual UW',
  //   field: 'manual_uw',
  //   cellDataType: 'boolean',
  //   width: 140,
  //   editable: true,
  // },
];
