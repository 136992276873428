import { Loader, Table } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../routes/config';
import H1 from '../Texts/H1';

interface TitleOptionsProps {
  text: string;
  fontSize: string;
}

interface ElementsProps {
  name: string;
  email: string;
  role: string;
  id: string;
}

interface GeneralTableProps {
  titleOptions: TitleOptionsProps[];
  elements: ElementsProps[];
  loading?: boolean;
}

export default function UsersTable({
  titleOptions,
  elements,
  loading,
}: GeneralTableProps) {
  const navigate = useNavigate();

  const rows = elements.map((element) => (
    <tr
      onClick={() => navigate(ROUTES.SINGLE_USER.replace(':id', element.id))}
      className="cursor-pointer hover:bg-[#E8F5FF]"
      style={{ height: '50px', alignItems: 'center' }}
      key={element.name}
    >
      <td
        style={{
          color: '#626262',
          fontSize: '14px',
          textTransform: 'capitalize',
        }}
      >
        {/* <Button variant="subtle" style={{ color: '#626262', textTransform: 'capitalize', fontWeight: 400 }} component={Link} to={}> */}
        {element.name}
        {/* </Button> */}
      </td>
      <td style={{ color: '#626262', fontSize: '14px' }}>{element.email}</td>
      <td style={{ color: '#626262', fontSize: '14px' }}>{element.role}</td>
    </tr>
  ));

  return (
    <div>
      <Table style={{}}>
        <thead>
          <tr style={{ backgroundColor: '#E8F5FF' }}>
            {titleOptions.map((t) => {
              return (
                <th key={t.text}>
                  <H1 text={t.text} fontSize={t.fontSize} />
                </th>
              );
            })}
          </tr>
        </thead>
        {loading ? (
          // Loading state
          <tbody>
            <tr>
              <td colSpan={100} className="text-center">
                <div className="flex w-full justify-center py-10 pr-36">
                  <Loader color={'#0F182D'} />
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>{rows}</tbody>
        )}
      </Table>
    </div>
  );
}
