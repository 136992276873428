import '../../App.css';

import { useMutation } from '@apollo/client';
import { Checkbox, Modal } from '@mantine/core';
import { useEffect, useState } from 'react';

import { STATES } from '../../config/constants';
import { UPDATE_LENDER } from '../../graphql/mutations';
import IconBtn from '../Buttons/IconBtn';
import SaveIcon from '../Icons/Save';
import H1 from '../Texts/H1';

export type StatesPopupData = {
  id: string;
  name: string;
  states: string[];
};

type StatesPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  data: StatesPopupData | null;
};

const mapToObject = (loanTypes: string[]) => {
  const statesObject: { [key: string]: boolean } = {};
  loanTypes.forEach((loanType) => {
    statesObject[loanType] = true;
  });
  return statesObject;
};

const mapToArray = (loanTypes: { [key: string]: boolean }) => {
  const statesArray: string[] = [];
  Object.keys(loanTypes).forEach((key) => {
    if (loanTypes[key]) statesArray.push(key);
  });
  return statesArray;
};

const StatesPopup = ({ isOpen, onClose, onSave, data }: StatesPopupProps) => {
  const [title, setTitle] = useState('');
  const [updateLender] = useMutation(UPDATE_LENDER);
  const [states, setStates] = useState<{ [key: string]: boolean }>(
    mapToObject(data?.states || [])
  );
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setStates(mapToObject(data?.states || []));
  }, [data?.states]);

  const handleClosePopup = () => {
    onClose();
  };

  const handleSaveStates = async () => {
    if (saving) return;
    setSaving(true);
    await updateLender({
      variables: {
        id: data?.id,
        states: mapToArray(states),
      },
    });
    await onSave();
    setSaving(false);
  };

  useEffect(() => {
    if (data?.name) setTitle(`${data?.name} - States`);
  }, [data?.name]);

  return (
    <Modal opened={isOpen} onClose={handleClosePopup} centered size="auto">
      <div id="filterDialog" className="">
        <div className="m-5 w-[800px]">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-between">
              <H1 text={title} fontSize="24px" />
              <IconBtn
                btnText="Save"
                onClick={handleSaveStates}
                loading={saving}
                id="SubmitBtn"
                fontSize="16px"
                icon={<SaveIcon />}
                className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
              />
            </div>
            <div className="grid grid-rows-[repeat(11,minmax(0,1fr))] grid-flow-col gap-4 h-full w-full">
              {STATES.map((state) => (
                <Checkbox
                  key={state.value}
                  label={state.label}
                  checked={states[state.value]}
                  onChange={() => {
                    setStates({
                      ...states,
                      [state.value]: !states[state.value],
                    });
                  }}
                  styles={{
                    label: {
                      color: '#223C78',
                      fontWeight: 'bold',
                    },
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default StatesPopup;
