import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useRecoilState } from 'recoil';

import { menuSidebarOpenState } from '../state/atoms';
import NavigationCard from './NavigationCard';

interface MobileMenuProps {
  links: {
    link: string;
    color: string;
    text: string;
  }[];
}

export default function MobileMenu({ links }: MobileMenuProps) {
  const [sidebarOpen, setSidebarOpen] = useRecoilState(menuSidebarOpenState);

  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 flex xl:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="bg-gray-600 fixed inset-0 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div
            className="relative flex flex-1 flex-col bg-white"
            style={{ maxWidth: 257 }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute right-0 top-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex h-10 w-10 items-center justify-center rounded-full"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                </button>
              </div>
            </Transition.Child>
            <div className="h-0 flex-1 overflow-y-auto pb-4">
              <div>
                <div className="flex w-60 justify-center pb-6 pt-3.5">
                  <img src="/Images/complete-logo.png" alt="" />
                </div>
                <NavigationCard links={links} />
              </div>
            </div>
          </div>
        </Transition.Child>
        <div className="w-14 shrink-0">
          {/* Force sidebar to shrink to fit close icon */}
        </div>
      </Dialog>
    </Transition.Root>
  );
}
