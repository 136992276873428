export const ROUTES = {
  LOGOUT: '/logout',
  HOME: '/',
  PROFILE: '/profile',
  PHONE_SCRUB: '/phone-scrub',
  MASS_SCRUB_STEP_1: '/phone-scrub/mass-step-1',
  MASS_SCRUB_STEP_2: '/phone-scrub/mass-step-2',
  SINGLE_SCRUB: '/phone-scrub/single',
  LOGS: '/logs',
  USERS: '/users',
  SINGLE_USER: '/single-user/:id',
  LENDERS: '/lenders',
  LENDER: '/lender/:id',
  LENDER_EDIT: '/lenders/:id/edit',
  LENDER_NEW: '/lenders/new',
  LENDER_TABLE: '/lenders/table',
  LOAN_STATUS: '/loan-status',
  SINGLE_LOAN_STATUS: '/loan-status/:id',
  WELCOME: '/welcome',
  TUTORIALS: 'https://emcinet.com/inet/tutorials/',
  ESHOP: 'https://emortgagecapital.qbstores.com/home',
  MARKETING: 'https://www.theemcway.com/',
  EDUCATION: 'https://emortgagecapital.fastclass.com/',
  PAYROLL: 'https://emcinet.com/inet/paycomp/',
};
