interface FeaturedProps {
  className?: string;
}

const FeaturedIcon = ({ className }: FeaturedProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      id="FeaturedIcon"
      className={className}
    >
      <path
        d="M19.7579 21.3603L19.7574 21.3608C19.5237 21.5765 19.2297 21.7111 18.9176 21.7469C18.7181 21.7697 18.3835 21.6923 17.7083 21.214C17.0497 20.7474 16.2211 20.0159 15.0549 18.9842L15.0545 18.9839L15.0196 18.953C14.536 18.5258 14.1345 18.1711 13.7801 17.912C13.4073 17.6394 13.0311 17.4295 12.5873 17.3406C12.1997 17.2625 11.8003 17.2625 11.4126 17.3406C10.9685 17.4295 10.5921 17.6393 10.2192 17.912C9.86397 18.1717 9.46177 18.5274 8.97741 18.9557L8.9452 18.9841L8.94508 18.9842C7.77887 20.0159 6.95033 20.7474 6.29176 21.2138C5.61653 21.6921 5.28221 21.769 5.08305 21.746L5.08248 21.7459C4.76881 21.7099 4.47472 21.5751 4.24274 21.3609L4.24209 21.3603C4.08959 21.2199 3.92612 20.909 3.8375 20.0797C3.75104 19.2705 3.75 18.1575 3.75 16.591V11.597C3.75 9.43252 3.75156 7.88344 3.9082 6.70576C4.06209 5.54879 4.35333 4.8636 4.85112 4.36052L4.85132 4.36032C5.34807 3.85792 6.02272 3.5649 7.16354 3.40979C8.32678 3.25163 9.85757 3.25 12 3.25C14.1429 3.25 15.6737 3.25163 16.8369 3.40967C17.9776 3.56467 18.6521 3.85749 19.1489 4.35952C19.6467 4.86314 19.9379 5.54869 20.0918 6.70574C20.2484 7.88368 20.25 9.43303 20.25 11.598V16.591C20.25 18.157 20.249 19.27 20.1625 20.0793C20.0739 20.9089 19.9104 21.2199 19.7579 21.3603ZM7.93934 5.43934C7.65804 5.72064 7.5 6.10217 7.5 6.5C7.5 6.89783 7.65804 7.27936 7.93934 7.56066C8.22064 7.84196 8.60218 8 9 8H15C15.3978 8 15.7794 7.84196 16.0607 7.56066C16.342 7.27936 16.5 6.89783 16.5 6.5C16.5 6.10217 16.342 5.72064 16.0607 5.43934C15.7794 5.15804 15.3978 5 15 5H9C8.60218 5 8.22064 5.15804 7.93934 5.43934Z"
        stroke="#223C78"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default FeaturedIcon;
