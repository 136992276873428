import { Avatar, Table } from '@mantine/core';

import H1 from '../Texts/H1';

interface TitleOptionsProps {
  text: string;
  fontSize: string;
}

interface ElementsProps {
  name: string;
  phone: string;
  status: string;
  clean: boolean;
}

interface GeneralTableProps {
  titleOptions: TitleOptionsProps[];
  elements: ElementsProps[];
  input: string;
}

const CleanContact = ({ clean }: { clean: boolean }) => (
  <Avatar src={`/svg/${clean ? 'check-bold' : 'close'}.svg`} size={16} />
);

export default function SignleScrubTable({
  titleOptions,
  elements,
  input,
}: GeneralTableProps) {
  const printStrongPhone = (phone: string) => {
    const regex = /^[a-z]/i;
    const phoneRegex = new RegExp(input, 'gi');
    if (regex.test(input.toLowerCase())) return phone;
    return phone.replace(phoneRegex, `<strong>${input}</strong>`);
  };
  const printStrongName = (name: string) => {
    const regex = /^[a-z]/i;
    const nameRegex = new RegExp(input, 'gi');
    if (!regex.test(input.toLowerCase())) return name;
    return name.replace(nameRegex, `<strong>${input}</strong>`);
  };
  const rows = elements.map((element) => (
    <tr
      style={{ verticalAlign: 'text-top' }}
      key={element.name + element.phone}
    >
      <td
        style={{
          minWidth: '150px',
          color: '#626262',
          fontSize: '14px',
          textTransform: 'capitalize',
        }}
      >
        <p
          dangerouslySetInnerHTML={{
            __html: printStrongName(element.name || '-'),
          }}
        ></p>
      </td>
      <td style={{ minWidth: '100px', color: '#626262', fontSize: '14px' }}>
        <p
          dangerouslySetInnerHTML={{
            __html: printStrongPhone(element.phone || '-'),
          }}
        ></p>
      </td>
      <td
        className="text-webkit-center"
        style={{
          verticalAlign: 'middle',
          width: '20px',
          color: '#626262',
          fontSize: '14px',
        }}
      >
        <CleanContact clean={element.clean} />
      </td>
      <td style={{ minWidth: '100px', color: '#626262', fontSize: '14px' }}>
        {element.status || '-'}
      </td>
    </tr>
  ));

  return (
    <div>
      <Table style={{ maxWidth: '1045px', width: 'auto' }}>
        <thead>
          <tr style={{ backgroundColor: '#E8F5FF' }}>
            {titleOptions.map((t) => {
              return (
                <th key={t.text}>
                  <H1 text={t.text} fontSize={t.fontSize} />
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    </div>
  );
}
