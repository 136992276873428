import '../../App.css';

import { useMutation } from '@apollo/client';
import { Checkbox, Modal } from '@mantine/core';
import { useEffect, useState } from 'react';

import { UPDATE_LENDER } from '../../graphql/mutations';
import IconBtn from '../Buttons/IconBtn';
import SaveIcon from '../Icons/Save';
import H1 from '../Texts/H1';

export type CompTypesPopupData = {
  id: string;
  name: string;
  comp_types: string[];
};

type CompTypesPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  data: CompTypesPopupData | null;
};

const mapToObject = (compTypes: string[]) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const comp_typesObject: { [key: string]: boolean } = {};
  compTypes.forEach((loanType) => {
    comp_typesObject[loanType] = true;
  });
  return comp_typesObject;
};

const mapToArray = (compTypes: { [key: string]: boolean }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const comp_typesArray: string[] = [];
  Object.keys(compTypes).forEach((key) => {
    if (compTypes[key]) comp_typesArray.push(key);
  });

  return comp_typesArray;
};

export const COMP_TYPES = [
  { label: 'BPC', value: 'BPC' },
  { label: 'LPC', value: 'LPC' },
];

const CompTypesPopup = ({
  isOpen,
  onClose,
  onSave,
  data,
}: CompTypesPopupProps) => {
  const [title, setTitle] = useState('');
  const [updateLender] = useMutation(UPDATE_LENDER);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [comp_types, setCompTypes] = useState<{ [key: string]: boolean }>(
    mapToObject(data?.comp_types || [])
  );
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setCompTypes(mapToObject(data?.comp_types || []));
  }, [data?.comp_types]);

  const handleClosePopup = () => {
    onClose();
  };

  const handleSaveCompTypes = async () => {
    if (saving) return;
    setSaving(true);
    await updateLender({
      variables: {
        id: data?.id,
        comp_types: mapToArray(comp_types),
      },
    });
    await onSave();
    setSaving(false);
  };

  useEffect(() => {
    if (data?.name) setTitle(`${data?.name} - Comp Types`);
  }, [data?.name]);

  return (
    <Modal opened={isOpen} onClose={handleClosePopup} centered size="auto">
      <div id="filterDialog" className="">
        <div className="m-5 w-[800px]">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-between">
              <H1 text={title} fontSize="24px" />
              <IconBtn
                btnText="Save"
                onClick={handleSaveCompTypes}
                loading={saving}
                id="SubmitBtn"
                fontSize="16px"
                icon={<SaveIcon />}
                className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
              />
            </div>
            <div className="flex flex-row gap-4 h-full w-full mb-5">
              {COMP_TYPES.map((state) => (
                <Checkbox
                  key={state.value}
                  label={state.label}
                  checked={comp_types[state.value]}
                  onChange={() => {
                    setCompTypes({
                      ...comp_types,
                      [state.value]: !comp_types[state.value],
                    });
                  }}
                  styles={{
                    label: {
                      color: '#223C78',
                      fontWeight: 'bold',
                    },
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CompTypesPopup;
