const EmailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
    >
      <path
        d="M14.4166 1.65003C14.4166 0.944199 13.8391 0.366699 13.1333 0.366699H2.86659C2.16075 0.366699 1.58325 0.944199 1.58325 1.65003V9.35003C1.58325 10.0559 2.16075 10.6334 2.86659 10.6334H13.1333C13.8391 10.6334 14.4166 10.0559 14.4166 9.35003V1.65003ZM13.1333 1.65003L7.99992 4.85837L2.86659 1.65003H13.1333ZM13.1333 9.35003H2.86659V2.93337L7.99992 6.1417L13.1333 2.93337V9.35003Z"
        className="fill-current"
      />
    </svg>
  );
};

export default EmailIcon;
