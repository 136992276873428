import '../../App.css';

import { useMutation, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { EDIT_USER } from '../../graphql/mutations';
import { GET_USER } from '../../graphql/queries';
import LeftTextBtn from '../Buttons/LeftTextBtn';
import SingleUserTable from '../Grids/SingleUser';
import LoaderOverlay from '../Overlays/LoaderOverlay';
import H1 from '../Texts/H1';
import H2 from '../Texts/H2';

interface UserForm {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
}

export default function SingleUser() {
  const { id } = useParams();
  const { setValue, watch, reset, handleSubmit } = useForm<UserForm>();

  const { loading } = useQuery(GET_USER, {
    variables: { id },
    onCompleted: (data) => {
      reset({ ...data.user });
    },
  });

  const l = watch();

  const [editUser, { loading: loadingMutation }] = useMutation(EDIT_USER);

  const onSubmit = (data: UserForm) => {
    editUser({ variables: { id, role: data.role } });
  };

  return (
    <>
      <LoaderOverlay loading={loading || loadingMutation} />
      <div className="flex justify-between">
        <div className="flex">
          <H2 text="User" fontSize="32px" fontWeight={400} />
          <div className="pl-2">
            <H1 text="information" fontSize="32px" />
          </div>
        </div>
      </div>
      <div className="ml-[-20px] py-2">
        <LeftTextBtn btnText="Back to List" link="/users" />
      </div>
      <div className="py-2">
        {/* <Paragraph text='Breve Explicación de la funcionalidad de esta sección' /> */}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="pt-8">
        <SingleUserTable
          rowsInfo1={[
            { title: 'First name', text: l.first_name },
            { title: 'Last name', text: l.last_name },
            { title: 'Email', text: l.email },
            { title: 'Role', text: l.role },
          ]}
          setValue={(key, val) => setValue(key, val)}
        />
      </form>
    </>
  );
}
