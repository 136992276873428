import '../../App.css';

import { useQuery } from '@apollo/client';
import { Checkbox, Input, MultiSelect } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { LOAN_TYPES } from '../../config/constants';
import { GET_EPO_DAYS } from '../../graphql/queries';
import IconBtn from '../Buttons/IconBtn';
import CrossIcon from '../Icons/Cross';
import DownArrowIcon from '../Icons/DownArrow';
import SearchIcon from '../Icons/Search';
import RatingClick from '../Icons/StarsClick';
import type { FilterType } from '../pages/Lenders';

interface IDrawerProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setFilters: React.Dispatch<React.SetStateAction<FilterType>>;
  filters: FilterType;
  setReset: (value: boolean) => void;
  resetState: boolean;
}
type FormData = {
  name: string;
  state: string[] | null;
  loanType: string[] | null;
  noFico: boolean;
  vaMinimum: boolean;
  FHAMinimum: boolean;
  lockNShop: boolean;
  manualUW: boolean;
  tbdunderwrite: boolean;
  loanSifter: boolean;
  LenderPrice: boolean;
  rating: number;
  vaMinimumValue: number;
  FHAMinimumValue: number;
  compType: string[] | null;
  epo_days: string[] | null;
};

const DrawerFilter = ({
  isOpen,
  setIsOpen,
  filters,
  setFilters,
  resetState,
  setReset,
}: IDrawerProps) => {
  // const { hasAdminPermissions } = usePermissions();
  const [epoDayData, setepoDay] = useState([{ value: '0', label: '0' }]);
  const [selectedRating, setSelectedRating] = useState<number | null>(null);
  const toggleDialog = () => {
    setIsOpen(!isOpen);
  };
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    // formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    let { loanType, state } = data;
    if (data.loanType?.length === 0) {
      loanType = null;
    }
    if (data.state?.length === 0) {
      state = null;
    }
    const VAtoSend = data.vaMinimum ? Number(data.vaMinimumValue) : null;
    const FhaToSend = data.FHAMinimum ? Number(data.FHAMinimumValue) : null;
    const epoDayToSend = data.epo_days ? Number(data.epo_days) : null;

    const filtersProp = {
      lenderName: data.name,
      state,
      loanType,
      NoFico: data.noFico,
      VAMinimum: VAtoSend,
      FHAMinimum: FhaToSend,
      LockNShop: data.lockNShop,
      ManualUW: data.manualUW,
      TDBUnderwrite: data.tbdunderwrite,
      LoanSifter: data.loanSifter,
      LenderPrice: data.LenderPrice,
      MinRating: selectedRating,
      sortingOption: filters.sortingOption,
      compType: data.compType,
      epo_days: epoDayToSend,
    };
    setFilters(filtersProp as FilterType);
    toggleDialog();
  };

  useQuery(GET_EPO_DAYS, {
    onCompleted: (data) => {
      const epoData = Object.values(data.getEpoDays);
      // @ts-ignore
      const epoDB = [];
      epoData.forEach((item: any) => {
        if (item !== null) {
          epoDB.push({ value: String(item), label: String(item) });
        }
      });
      // @ts-ignore
      setepoDay(epoDB);
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (resetState) {
      reset();
      setReset(false);
    }
  }, [resetState, reset]);

  useEffect(() => {
    setValue('loanType', filters.loanType as string[]);
    setValue('state', filters.state as string[]);
    setValue('epo_days', [String(filters.epo_days)]);
    setValue('compType', filters.compType as string[]);
    setValue('name', filters.lenderName as string);
    setValue('noFico', filters.NoFico as boolean);
    setValue('lockNShop', filters.LockNShop as boolean);
    setValue('manualUW', filters.ManualUW as boolean);
    setValue('tbdunderwrite', filters.TDBUnderwrite as boolean);
    setValue('loanSifter', filters.LoanSifter as boolean);
    setValue('LenderPrice', filters.LenderPrice as boolean);
    setValue('vaMinimum', filters.VAMinimum !== null);
    setValue('FHAMinimum', filters.FHAMinimum !== null);
    setSelectedRating(filters.MinRating);
  }, [filters]);

  // const state = watch('state');
  const compType = watch('compType');
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const epo_days = watch('epo_days');
  const loanType = watch('loanType');
  const vaMinimum = watch('vaMinimum');
  const FHAMinimum = watch('FHAMinimum');

  return (
    <div
      className={`dialog-container ${isOpen ? 'open' : ''} `}
      id="MoreFiltersPopUp"
    >
      <div className="dialog-backdrop" onClick={toggleDialog} />
      <div id="filterDialog" className="dialog-content">
        <div className="m-5">
          <div className="flex justify-end">
            <a onClick={toggleDialog} className="cursor-pointer">
              <CrossIcon color="black" />
            </a>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col">
              <span className="font-Segoe text-xl font-bold text-blue ">
                Filters
              </span>
              <div className="mt-3 flex flex-col gap-3 md:flex-row">
                <Input
                  placeholder="Search Lender"
                  radius="lg"
                  id="SearchMoreFiltersInput"
                  rightSection={<SearchIcon />}
                  {...register('name')}
                  styles={() => ({
                    wrapper: {
                      width: '354px',
                    },
                    defaultVariant: {
                      border: '1px solid #27ABE2',
                    },
                  })}
                />
                <MultiSelect
                  data={LOAN_TYPES}
                  radius="md"
                  rightSection={<DownArrowIcon />}
                  maxSelectedValues={1}
                  creatable={true}
                  searchable
                  getCreateLabel={(query) => `+ Create ${query}`}
                  id="LoanTypeMultiFilter"
                  placeholder={'Loan Types'}
                  value={loanType || []}
                  onChange={(selectedValues) => {
                    setValue('loanType', selectedValues);
                  }}
                  styles={() => ({
                    wrapper: {
                      width: '354px',
                    },
                    defaultVariant: {
                      border: '1px solid #27ABE2',
                      height: '36px !important',
                    },
                  })}
                />
                {/* <MultiSelect
                  data={STATES}
                  radius="md"
                  rightSection={<DownArrowIcon />}
                  maxSelectedValues={1}
                  id="StateMultiFilter"
                  value={state || []}
                  searchable
                  onChange={(selectedValues) => {
                    setValue('state', selectedValues);
                  }}
                  placeholder={'States'}
                  styles={() => ({
                    wrapper: {
                      width: '354px',
                    },
                    defaultVariant: {
                      border: '1px solid #27ABE2',
                      height: '36px !important',
                    },
                  })}
                /> */}
              </div>
              <div className="mt-5">
                <span className="font-Segoe text-base text-blue">
                  Fico Score Range
                </span>
                <div className="mt-2 flex flex-col gap-6 md:flex-row">
                  <Checkbox
                    label="No FICO"
                    size="sm"
                    id="NoFicoCheckBox"
                    {...register('noFico')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  <div className="flex flex-row gap-3">
                    <Checkbox
                      label="FHA Minimum"
                      size="sm"
                      id="FHAMinimumFilter"
                      {...register('FHAMinimum')}
                      className=""
                      styles={{
                        label: {
                          color: '#223C78',
                          fontWeight: 'bold',
                        },
                      }}
                    />
                    <div
                      className={`flex items-center justify-center ${
                        FHAMinimum ? '' : 'hidden'
                      }`}
                    >
                      <Input
                        variant="unstyled"
                        placeholder=""
                        disabled={!FHAMinimum}
                        id="FHAMinimumValueFilter"
                        {...register('FHAMinimumValue')}
                        size="xs"
                        styles={{
                          wrapper: {
                            width: '70px',
                          },
                          input: {
                            borderBottom: '1px solid #DFF1FF !important',
                            height: '14px !important',
                            minHeight: '14px !important',
                            color: '#223C78',
                            '::placeholder': {
                              color: '#223C78',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-3">
                    <Checkbox
                      label="VA Minimum"
                      size="sm"
                      id="vaMinimumFilter"
                      {...register('vaMinimum')}
                      styles={{
                        label: {
                          color: '#223C78',
                          fontWeight: 'bold',
                        },
                      }}
                    />
                    <div
                      className={`flex items-center justify-center ${
                        vaMinimum ? '' : 'hidden'
                      }`}
                    >
                      <Input
                        variant="unstyled"
                        placeholder=""
                        {...register('vaMinimumValue')}
                        size="xs"
                        id="vaMinimumValueFilter"
                        disabled={!vaMinimum}
                        styles={{
                          wrapper: {
                            width: '70px',
                          },
                          input: {
                            borderBottom: '1px solid #DFF1FF !important',
                            height: '14px !important',
                            minHeight: '14px !important',
                            color: '#223C78',
                            '::placeholder': {
                              color: '#223C78',
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <span className="font-Segoe text-base text-blue">Revenue</span>
                <div className="mt-3 flex flex-col gap-3 md:flex-row">
                  <MultiSelect
                    data={[
                      { label: 'BPC', value: 'BPC' },
                      { label: 'LPC', value: 'LPC' },
                    ]}
                    radius="md"
                    rightSection={<DownArrowIcon />}
                    maxSelectedValues={99}
                    id="compTypeMultiFilter"
                    searchable
                    value={compType || []}
                    onChange={(selectedValues) => {
                      setValue('compType', selectedValues);
                    }}
                    placeholder={'Comp Type'}
                    styles={() => ({
                      wrapper: {
                        width: '354px',
                      },
                      defaultVariant: {
                        border: '1px solid #27ABE2',
                        height: '36px !important',
                      },
                    })}
                  />
                  <MultiSelect
                    data={epoDayData}
                    radius="md"
                    rightSection={<DownArrowIcon />}
                    maxSelectedValues={1}
                    id="EPO_DaysMultiFilter"
                    searchable
                    // creatable={hasAdminPermissions()}
                    // getCreateLabel={(query) => `+ Create ${query}`}
                    // onCreate={(query) => {
                    //   const item = { value: query, label: query };
                    //   setepoDay((current) => [...current, item]);
                    //   return item;
                    // }}
                    value={epo_days || []}
                    onChange={(selectedValues) => {
                      setValue('epo_days', selectedValues);
                    }}
                    placeholder={'EPO Days'}
                    styles={() => ({
                      wrapper: {
                        width: '354px',
                      },
                      defaultVariant: {
                        border: '1px solid #27ABE2',
                        height: '36px !important',
                      },
                    })}
                  />
                </div>
              </div>
              <div className="mt-5 text-blue flex md:flex-row flex-col md:gap-x-5 gap-y-5">
                <div>
                  <span className="font-Segoe text-base text-blue">
                    Other Filters
                  </span>
                  <div className="mt-2 flex flex-col gap-6 md:flex-row">
                    <Checkbox
                      label="Lock - N - Shop"
                      size="sm"
                      id="LockNShopCheckBoxFilter"
                      {...register('lockNShop')}
                      styles={{
                        label: {
                          fontWeight: 'bold',
                          color: '#223C78',
                        },
                      }}
                    />
                    {/* <Checkbox
                      label="Manual UW"
                      size="sm"
                      id="manualUWChechBoxFilter"
                      {...register('manualUW')}
                      styles={{
                        label: {
                          fontWeight: 'bold',
                          color: '#223C78',
                        },
                      }}
                    /> */}
                    <Checkbox
                      label="TBD Underwrite"
                      size="sm"
                      id="TBDUnderwriteCheckBoxFilter"
                      {...register('tbdunderwrite')}
                      styles={{
                        label: {
                          fontWeight: 'bold',
                          color: '#223C78',
                        },
                      }}
                    />
                    <Checkbox
                      label="Loan Sifter"
                      size="sm"
                      id="LoanSifterCheckBoxFilter"
                      {...register('loanSifter')}
                      styles={{
                        label: {
                          fontWeight: 'bold',
                          color: '#223C78',
                        },
                      }}
                    />
                    <Checkbox
                      label="Lender Price"
                      size="sm"
                      id="LenderPriceCheckBoxFilter"
                      {...register('LenderPrice')}
                      styles={{
                        label: {
                          fontWeight: 'bold',
                          color: '#223C78',
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-col justify-center">
                  <span className="font-Segoe text-base text-blue">Rating</span>
                  <RatingClick
                    selectedRating={selectedRating}
                    setSelectedRating={setSelectedRating}
                  />
                </div>
              </div>
              <div className="flex justify-end">
                <IconBtn
                  btnText="Search"
                  id="SearchMoreFiltersBtn"
                  fontSize="16px"
                  type="submit"
                  icon={<SearchIcon />}
                  className="h-[36px] rounded-[10px] bg-blue font-Segoe hover:bg-[#27abe2] hover:text-white mt-3"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DrawerFilter;
