import '../../App.css';

import { useMutation, useQuery } from '@apollo/client';
import { Checkbox, Input, MultiSelect, Select, TextInput } from '@mantine/core';
import { RichTextEditor } from '@mantine/rte';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useFilePicker } from 'use-file-picker';

import { LOAN_TYPES } from '../../config/constants';
import {
  CREATE_LENDER,
  UPDATE_OR_CREATE_ACCEXECUTOR,
  UPLOAD_IMAGE,
} from '../../graphql/mutations';
import { GET_EPO_DAYS } from '../../graphql/queries';
import { usePermissions } from '../../hooks/usePermissions';
import { ROUTES } from '../../routes/config';
import FilterDisplay from '../Buttons/FilterDisplay';
import IconBtn from '../Buttons/IconBtn';
import LeftTextBtn from '../Buttons/LeftTextBtn';
import AccExecutor from '../Cards/AccExecutor';
import LenderMultiEdit from '../Dropdowns/MultiSelectLenderEdit';
import DownArrowIcon from '../Icons/DownArrow';
import FeaturedIcon from '../Icons/Featured';
import PlusIcon from '../Icons/Plus';
import SaveIcon from '../Icons/Save';

type FormData = {
  name: string;
  rating: number;
  featured: boolean;
  description: string;
  website: string;
  more_about: string;
  FHAMinimum: boolean;
  FHAMinimumValue: number | null;
  VAMinimum: boolean;
  VAMinimumValue: number | null;
  NoFico: boolean;
  lpc_points: number;
  lpc_flat_fee: number;
  bpc_conditions_unit: string;
  bpc_conditions_value: number;
  lock_n_shop: boolean;
  manual_uw: boolean;
  tdb_underwrite: boolean;
  loan_sifter: boolean;
  epo_policy: string;
  broker_id: string;
  lender_price: boolean;
  comp_types: string[] | null;
  epo_days: string[] | null;
};

export default function NewLender() {
  const { hasAdminPermissions } = usePermissions();
  const [accExecutors, setAccExecutors] = useState<any>([]);
  const [epoDayData, setepoDay] = useState([{ value: '0', label: '0' }]);
  const [creating, setCreating] = useState<boolean>(false);
  const [deletedExecutors, setDeletedExecutors] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [loanTypes, setLoanTypes] = useState<any>([]);
  const [uploadImage] = useMutation(UPLOAD_IMAGE);
  const [createLender] = useMutation(CREATE_LENDER);
  const [updateOrCreateAccExecutor] = useMutation(UPDATE_OR_CREATE_ACCEXECUTOR);
  // const [isOpen, setIsOpen] = useState(false);
  const [newUrl, setNewUrl] = useState<string>('/Images/default-lender.svg');
  const navigate = useNavigate();

  const [openFileSelector] = useFilePicker({
    readAs: 'DataURL',
    accept: 'image/*',
    onFilesSuccessfulySelected: async ({ plainFiles }) => {
      const url = await uploadImage({ variables: { file: plainFiles[0] } });
      setNewUrl(url.data.uploadImage);
    },
  });
  useQuery(GET_EPO_DAYS, {
    onCompleted: (data) => {
      const epoData = Object.values(data.getEpoDays);
      // @ts-ignore
      const epoDB = [];
      epoData.forEach((item: any) => {
        if (item !== null) {
          epoDB.push({ value: String(item), label: String(item) });
        }
      });
      // @ts-ignore
      setepoDay(epoDB);
    },
    fetchPolicy: 'no-cache',
  });

  const onSubmit = async (data: FormData) => {
    if (creating) return;
    try {
      const updatedLenderData = {
        name: data.name,
        lender_picture: newUrl,
        short_description: data.description,
        more_about: data.more_about,
        featured: data.featured,
        website: data.website,
        states,
        loan_types: loanTypes,
        no_fico: data.NoFico,
        fha_minimum_fico: data.FHAMinimum ? Number(data.FHAMinimumValue) : null,
        va_minimum_fico: data.VAMinimum ? Number(data.VAMinimumValue) : null,
        lpc_points: Number(data.lpc_points),
        lpc_flat_fee: Number(data.lpc_flat_fee),
        bpc_conditions_unit: data.bpc_conditions_unit,
        bpc_conditions_value: Number(data.bpc_conditions_value),
        lock_n_shop: data.lock_n_shop,
        manual_uw: data.manual_uw,
        tdb_underwrite: data.tdb_underwrite,
        loan_sifter: data.loan_sifter,
        epo_policy: data.epo_policy,
        broker_id: data.broker_id,
        lender_price: data.lender_price,
        comp_types: data.comp_types,
        epo_days: Number(data.epo_days),
      };
      setCreating(true);
      createLender({
        variables: updatedLenderData,
      }).then((id) => {
        accExecutors.forEach(async (executor: any) => {
          await updateOrCreateAccExecutor({
            variables: {
              id: executor.id,
              full_name: executor.full_name,
              phone: executor.phone,
              email: executor.email,
              lender_id: id.data?.createLender.id,
              selected: executor.selected,
            },
          });
        });
        navigate(ROUTES.LENDER.replace(':id', id.data?.createLender.id));
      });

      // console.log('Lender data updated successfully');
    } catch (error) {
      // console.error('Error updating lender data', error);
      navigate(ROUTES.LENDERS);
    }
  };

  const createNewAccExecutor = () => {
    const newAccExecutor = {
      id: `new${Math.random().toString(36)}`,
      full_name: '',
      phone: '',
      email: '',
      selected: accExecutors.length === 0,
    };
    setAccExecutors([...accExecutors, newAccExecutor]);
  };

  const {
    handleSubmit,
    register,
    /*  reset */ watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      featured: false,
      website: '',
      NoFico: false,
      lock_n_shop: false,
      manual_uw: false,
      tdb_underwrite: false,
      loan_sifter: false,
      FHAMinimum: false,
      VAMinimum: false,
      lpc_points: 0,
      lpc_flat_fee: 0,
      bpc_conditions_unit: '$',
      FHAMinimumValue: null,
      VAMinimumValue: null,
    },
    reValidateMode: 'onChange',
  });

  const featured = watch('featured');
  const FHAMinimum = watch('FHAMinimum');
  const VAMinimum = watch('VAMinimum');
  const LPCFlatFee = watch('lpc_flat_fee');
  const name = watch('name');
  const moreAbout = watch('more_about');
  const description = watch('description');
  const compTypes = watch('comp_types');
  const epoDays = watch('epo_days');

  const BpcConditionsUnit = watch('bpc_conditions_unit');

  return (
    <>
      <div className="ml-[-20px] py-2">
        <LeftTextBtn btnText="Lenders" link="/lenders" />
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="pb-10">
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="flex flex-row gap-3">
            <TextInput
              placeholder={'Lender Name'}
              variant="unstyled"
              radius="xs"
              id="LenderNameNew"
              required
              {...register('name')}
              size="xl"
              styles={{
                input: {
                  '&::placeholder': {
                    color: '#223C78',
                    fontSize: '32px',
                    fontFamily: 'Segoe UI',
                    fontWeight: 'bold',
                  },
                  width: '260px',
                  color: '#223C78',
                  fontSize: '32px',
                  fontFamily: 'Segoe UI',
                  fontWeight: 'bold',
                },
              }}
            />
            {errors.name?.type === 'required' && (
              <span className="font-Segoe text-sm text-[#DC2626]">
                * Required
              </span>
            )}
            <div className={`flex items-center`}>
              <button
                type="button"
                id="featuredBtn"
                onClick={() => setValue('featured', !featured)}
              >
                <FeaturedIcon
                  className={`${featured ? 'fill-[#223C78]' : 'fill-none'}`}
                ></FeaturedIcon>
              </button>
            </div>
          </div>
          <div className="mb-5 flex items-center justify-center gap-3 md:mb-0">
            <IconBtn
              btnText="Save"
              id="SaveLenderNew"
              type="submit"
              fontSize="16px"
              loading={creating}
              icon={<SaveIcon />}
              className="bg-[#DFF1FF] font-Segoe text-blue hover:text-white hover:bg-blue"
            />
          </div>
        </div>

        <div className="grid w-full grid-cols-1 gap-5 pl-2 md:grid-cols-2 md:pl-0">
          <div className="flex h-full w-full flex-col ">
            <div className="mb-10 flex items-center">
              <div className="relative flex rounded-lg border border-[#DFF1FF]">
                <img
                  alt=""
                  id="LenderImage"
                  src={newUrl}
                  className="h-full w-full object-cover"
                />

                <a
                  className="absolute bottom-2 right-2 cursor-pointer"
                  id="uploadImageBtn"
                  onClick={openFileSelector}
                >
                  <img src="/Images/edit-lender.svg" />
                </a>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <span className="font-SegoeBold text-base font-bold text-blue">
                Brief Description
              </span>
              <RichTextEditor
                value={description}
                className={'briefDescription'}
                onChange={(value) => setValue('description', value)}
                styles={{
                  root: {
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    borderColor: '#DFF1FF',
                    borderRadius: '10px',
                  },
                }}
                controls={[
                  [
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'unorderedList',
                    'orderedList',
                  ],
                ]}
              />
            </div>
            <div className="mt-2 flex flex-col gap-2">
              <span className="font-SegoeBold text-base font-bold text-blue">
                Website
              </span>
              <TextInput
                variant="unstyled"
                id="LenderWebNew"
                {...register('website', {
                  required: true,
                  pattern:
                    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
                  onChange: (e) => {
                    if (
                      e.target.value.length > 0 &&
                      !e.target.value.startsWith('https://')
                    ) {
                      setValue('website', `https://${e.target.value}`);
                    }
                  },
                })}
                styles={{
                  wrapper: {
                    borderBottom: `1px solid ${
                      errors.website ? '#ff0000' : '#DFF1FF'
                    }`,
                  },
                }}
              />
              {errors.website?.type === 'required' && (
                <span className="font-Segoe text-sm text-[#DC2626]">
                  * Required
                </span>
              )}
              {errors.website?.type === 'pattern' && (
                <span className="font-Segoe text-sm text-[#DC2626]">
                  URL not valid
                </span>
              )}
            </div>
            <div className="mt-2 flex flex-col gap-2">
              <span className="font-SegoeBold text-2xl font-bold text-blue">
                More About {name}
              </span>
              <span className="font-SegoeBold text-base font-bold text-blue">
                EPO Policy
              </span>
              <TextInput
                variant="unstyled"
                id="LenderEpoPolicyNew"
                {...register('epo_policy')}
                styles={{
                  root: {
                    borderBottom: '1px solid #DFF1FF',
                  },
                }}
              />
              <RichTextEditor
                value={moreAbout}
                onChange={(value) => setValue('more_about', value)}
                className={'moreAbout'}
                styles={{
                  root: {
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    borderColor: '#DFF1FF',
                    borderRadius: '10px',
                    height: '200px',
                    maxHeight: '200px',
                  },
                }}
                controls={[
                  [
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'unorderedList',
                    'orderedList',
                  ],
                ]}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <span className="font-SegoeBold text-2xl font-bold text-blue mb-3">
              Lender Information
            </span>
            <div className="flex flex-col">
              <LenderMultiEdit
                placeholder="States"
                info={states}
                setInfo={setStates}
                edit={true}
                id="LenderStatesNew"
              ></LenderMultiEdit>
              <div className="flex flex-row gap-2  mt-2 flex-wrap">
                {states?.length > 0 &&
                  states?.map((state: any) => {
                    return (
                      <FilterDisplay
                        key={state}
                        btnText={state}
                        deleteFilter={() => {
                          setStates(
                            states.filter((item: any) => item !== state)
                          );
                        }}
                      />
                    );
                  })}
              </div>
            </div>
            <span className="font-SegoeBold text-base font-bold text-blue">
              Broker ID
            </span>
            <TextInput
              variant="unstyled"
              id="BrokerIdNew"
              {...register('broker_id')}
              styles={{
                root: {
                  borderBottom: '1px solid #DFF1FF',
                },
              }}
            />
            {accExecutors?.length > 0 && (
              <div className="flex flex-col gap-3 mt-3">
                <span className="font-SegoeBold text-2xl font-bold text-blue">
                  Account Executives
                </span>
                <div className="flex flex-col gap-2">
                  {accExecutors?.map((accExecutor: any) => {
                    return (
                      <AccExecutor
                        key={accExecutor.id}
                        edit={true}
                        executor={accExecutor}
                        setAccExecutors={setAccExecutors}
                        accExecutors={accExecutors}
                        deletedExecutors={deletedExecutors}
                        setDeletedExecutors={setDeletedExecutors}
                        register={register}
                        setValue={setValue}
                        errors={errors}
                      />
                    );
                  })}
                </div>

                <div className="flex h-full w-full items-center justify-center">
                  <button type="button" onClick={createNewAccExecutor}>
                    <div className="flex h-5 w-5 items-center justify-center rounded-full bg-blue text-white">
                      <PlusIcon></PlusIcon>
                    </div>
                  </button>
                </div>
              </div>
            )}

            {accExecutors?.length === 0 && (
              <button
                type="button"
                onClick={createNewAccExecutor}
                id="addAccountExecutor"
              >
                <div className="flex flex-col gap-3 mt-3">
                  <span className="flex items-start font-SegoeBold text-2xl font-bold  text-blue">
                    Account Executives
                  </span>
                  <div className="flex flex-row items-center justify-center gap-2">
                    <div className="flex h-5 w-5 items-center justify-center rounded-full bg-blue text-white">
                      <PlusIcon></PlusIcon>
                    </div>
                    <span className="font-Segoe text-base text-blue">
                      Add an Account Executor
                    </span>
                  </div>
                </div>
              </button>
            )}
            <div className="flex flex-col gap-3 mt-4">
              <span className="font-SegoeBold text-2xl font-bold text-blue">
                Loans Information
              </span>
              <div className="flex flex-col gap-2">
                <LenderMultiEdit
                  placeholder="Loan Types"
                  info={loanTypes}
                  setInfo={setLoanTypes}
                  data={LOAN_TYPES}
                  edit={true}
                  id="LenderLoanTypesNew"
                  // createable={true}
                ></LenderMultiEdit>
                <div className="flex flex-row gap-2 flex-wrap">
                  {loanTypes?.length > 0 &&
                    loanTypes?.map((loanType: any) => {
                      return (
                        <FilterDisplay
                          key={loanType}
                          btnText={loanType}
                          deleteFilter={() => {
                            setLoanTypes(
                              loanTypes.filter((item: any) => item !== loanType)
                            );
                          }}
                        />
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-2 mt-3">
              <span className="font-SegoeBold text-base font-bold text-blue">
                Fico Score Range
              </span>
              <div className="flex flex-col gap-6">
                <Checkbox
                  label="No FICO"
                  size="sm"
                  id="NoFico"
                  {...register('NoFico')}
                  styles={{
                    label: {
                      color: '#223C78',
                      fontWeight: 'bold',
                    },
                  }}
                />

                <div className="flex flex-row gap-3">
                  <Checkbox
                    label="FHA Minimum FICO"
                    size="sm"
                    id="FHAMinimum"
                    {...register('FHAMinimum')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  <div
                    className={`flex items-center justify-center ${
                      FHAMinimum ? '' : 'hidden'
                    }`}
                  >
                    <Input
                      variant="unstyled"
                      id="FHAMinimumValue"
                      disabled={!FHAMinimum}
                      {...(register('FHAMinimumValue'),
                      { required: FHAMinimum })}
                      onChange={(e: any) => {
                        if (e.target.value.length >= 0) {
                          setValue('FHAMinimumValue', e.target.value);
                        } else {
                          setValue('FHAMinimumValue', null);
                        }
                      }}
                      size="xs"
                      styles={{
                        wrapper: {
                          width: '70px',
                        },
                        input: {
                          borderBottom: '1px solid #DFF1FF !important',
                          height: '14px !important',
                          minHeight: '14px !important',
                          color: '#626262',
                          '::placeholder': {
                            color: '#626262',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex flex-row gap-3">
                  <Checkbox
                    label="VA Minimum FICO"
                    size="sm"
                    id="VAMinimum"
                    {...register('VAMinimum')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  <div
                    className={`flex items-center justify-center ${
                      VAMinimum ? '' : 'hidden'
                    }`}
                  >
                    <Input
                      variant="unstyled"
                      id="VAMinimumValue"
                      disabled={!VAMinimum}
                      {...register('VAMinimumValue')}
                      required={VAMinimum}
                      size="xs"
                      styles={{
                        wrapper: {
                          width: '70px',
                        },
                        input: {
                          borderBottom: '1px solid #DFF1FF !important',
                          height: '14px !important',
                          minHeight: '14px !important',
                          color: '#626262',
                          '::placeholder': {
                            color: '#626262',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-2 mt-3">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  Revenue
                </span>
                <div className="grid grid-cols-2">
                  <div className="flex flex-col gap-2">
                    <span className="font-SegoeBold text-base font-bold text-blue">
                      LPC
                    </span>
                    <div className="grid w-36 grid-cols-2 gap-0">
                      <div className="relative">
                        <span className="font-Segoe text-sm text-[#626262]">
                          Points
                        </span>
                        <Input
                          variant="unstyled"
                          placeholder={'Value'}
                          id="lpc_points"
                          {...register('lpc_points')}
                          size="xs"
                          styles={{
                            wrapper: {
                              width: '50px',
                            },
                            input: {
                              borderBottom: '1px solid #DFF1FF !important',
                              height: '14px !important',
                              minHeight: '14px !important',
                              color: '#626262',
                              '::placeholder': {
                                color: '#626262',
                              },
                            },
                          }}
                        />
                        <div className="absolute right-2 top-2">
                          <span className="font-Segoe text-lg text-blue">
                            +
                          </span>
                        </div>
                      </div>
                      <div>
                        <span className="font-Segoe text-sm text-[#626262]">
                          Flat Fee
                        </span>
                        <Input
                          variant="unstyled"
                          placeholder={'Value'}
                          id="lpc_flat_fee"
                          value={`$${LPCFlatFee}`}
                          onChange={(e: any) => {
                            setValue(
                              'lpc_flat_fee',
                              Number(e.target.value.replace('$', ''))
                            );
                          }}
                          size="xs"
                          styles={{
                            wrapper: {
                              width: '50px',
                            },
                            input: {
                              borderBottom: '1px solid #DFF1FF !important',
                              height: '14px !important',
                              minHeight: '14px !important',
                              color: '#626262',
                              '::placeholder': {
                                color: '#626262',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className="font-SegoeBold text-base font-bold text-blue ">
                      BPC
                    </span>
                    <div className="flex flex-col gap-0">
                      <span className="font-Segoe text-sm text-[#626262]">
                        Conditions
                      </span>
                      <div className="flex flex-row items-end gap-2">
                        <Select
                          data={['$', '%']}
                          id="bpc_conditions_unit"
                          value={BpcConditionsUnit}
                          onChange={(value: string) =>
                            setValue('bpc_conditions_unit', value)
                          }
                          variant="unstyled"
                          styles={{
                            input: {
                              width: '50px',
                              // height: '20px !important',
                              border: '2px solid #223C78 !important',
                              borderRadius: '10px',
                              height: '24px !important',
                              minHeight: 'unset !important',
                              maxHeight: 'unset',
                              paddingRight: '10px',
                              paddingLeft: '10px',
                            },
                            rightSection: {
                              width: '20px',
                            },
                          }}
                        />
                        <Input
                          variant="unstyled"
                          placeholder={'Value'}
                          {...register('bpc_conditions_value')}
                          size="xs"
                          id="bpc_conditions_value"
                          styles={{
                            wrapper: {
                              width: '50px',
                            },
                            input: {
                              borderBottom: '1px solid #DFF1FF !important',
                              height: '14px !important',
                              minHeight: '14px !important',
                              color: '#626262',
                              '::placeholder': {
                                color: '#626262',
                              },
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <MultiSelect
                  data={[
                    { label: 'BPC', value: 'BPC' },
                    { label: 'LPC', value: 'LPC' },
                  ]}
                  radius="md"
                  rightSection={<DownArrowIcon />}
                  maxSelectedValues={999}
                  id="comp_typesMultiFilter"
                  searchable
                  value={compTypes || []}
                  onChange={(selectedValues) => {
                    setValue('comp_types', selectedValues);
                  }}
                  placeholder={'Comp Type'}
                  styles={{
                    input: {
                      border: '1px solid #27ABE2',
                      height: '36px !important',
                    },
                  }}
                />
                <MultiSelect
                  data={epoDayData}
                  radius="md"
                  rightSection={<DownArrowIcon />}
                  maxSelectedValues={1}
                  id="EPO_DaysMultiFilter"
                  searchable
                  creatable={hasAdminPermissions()}
                  getCreateLabel={(query) => `+ Create ${query}`}
                  onCreate={(query) => {
                    const item = { value: query, label: query };
                    setepoDay((current) => [...current, item]);
                    return item;
                  }}
                  value={epoDays || []}
                  onChange={(selectedValues) => {
                    setValue('epo_days', selectedValues);
                  }}
                  placeholder={'EPO Days'}
                  styles={{
                    input: {
                      border: '1px solid #27ABE2',
                      height: '36px !important',
                    },
                  }}
                />
              </div>

              <div className="flex flex-col mt-3">
                <span className="font-SegoeBold text-base font-bold text-blue">
                  Other Filters
                </span>
                <div className="mt-2 flex flex-col gap-6 md:flex-row">
                  <Checkbox
                    label="Lock - N - Shop"
                    size="sm"
                    id="lock_n_shop"
                    {...register('lock_n_shop')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  {/* <Checkbox
                    label="Manual UW"
                    size="sm"
                    id="manual_uw"
                    {...register('manual_uw')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  /> */}
                  <Checkbox
                    label="TBD Underwrite"
                    size="sm"
                    id="tdb_underwrite"
                    {...register('tdb_underwrite')}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  <Checkbox
                    label="Loan Sifter"
                    size="sm"
                    id="loan_sifter"
                    {...(register('loan_sifter'),
                    {
                      onChange: (e) => {
                        setValue('loan_sifter', e.target.checked);
                      },
                    })}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                  <Checkbox
                    label="Lender Price"
                    size="sm"
                    id="lender_price"
                    {...(register('lender_price'),
                    {
                      onChange: (e) => {
                        setValue('lender_price', e.target.checked);
                      },
                    })}
                    styles={{
                      label: {
                        color: '#223C78',
                        fontWeight: 'bold',
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/* <LoaderOverlay loading={lender.loading && executors.loading} /> */}
    </>
  );
}
