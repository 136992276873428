import '../../App.css';

import { useMutation, useQuery } from '@apollo/client';
import { Modal, MultiSelect } from '@mantine/core';
import { useEffect, useState } from 'react';

import { UPDATE_LENDER } from '../../graphql/mutations';
import { GET_EPO_DAYS } from '../../graphql/queries';
import { usePermissions } from '../../hooks/usePermissions';
import IconBtn from '../Buttons/IconBtn';
import DownArrowIcon from '../Icons/DownArrow';
import SaveIcon from '../Icons/Save';
import H1 from '../Texts/H1';

export type EpoDaysPopupData = {
  id: string;
  name: string;
  epo_days: string[];
};

type EpoDaysPopupProps = {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  data: EpoDaysPopupData | null;
};

const EpoDaysPopup = ({ isOpen, onClose, onSave, data }: EpoDaysPopupProps) => {
  const [title, setTitle] = useState('');
  const [updateLender] = useMutation(UPDATE_LENDER);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [epo_days, setEpoDays] = useState<any>([]);
  const [epoDaysData, setEpoDaysData] = useState([{ value: '0', label: '0' }]);

  useQuery(GET_EPO_DAYS, {
    onCompleted: (epoDataCompleted) => {
      const epoData = Object.values(epoDataCompleted.getEpoDays);
      // @ts-ignore
      const epoDB = [];
      epoData.forEach((item: any) => {
        if (item !== null) {
          epoDB.push({ value: String(item), label: String(item) });
        }
      });
      // @ts-ignore
      setEpoDaysData(epoDB);
    },
    fetchPolicy: 'no-cache',
  });

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setEpoDays([String(data?.epo_days)]);
  }, [data?.epo_days]);

  const handleClosePopup = () => {
    onClose();
  };

  const handleSaveEpoDays = async () => {
    if (saving) return;
    setSaving(true);
    console.log('al guardar', epo_days, epo_days.length === 0);
    await updateLender({
      variables: {
        id: data?.id,
        epo_days: epo_days.length === 0 ? null : Number(epo_days),
      },
    });
    await onSave();
    setSaving(false);
  };

  useEffect(() => {
    if (data?.name) setTitle(`${data?.name} - Epo Days`);
  }, [data?.name]);
  const { hasAdminPermissions } = usePermissions();

  return (
    <Modal opened={isOpen} onClose={handleClosePopup} centered size="auto">
      <div id="filterDialog" className="">
        <div className="m-5 w-[800px]">
          <div className="flex flex-col gap-2 w-full">
            <div className="flex flex-row justify-between">
              <H1 text={title} fontSize="24px" />
              <IconBtn
                btnText="Save"
                onClick={handleSaveEpoDays}
                loading={saving}
                id="SubmitBtn"
                fontSize="16px"
                icon={<SaveIcon />}
                className="bg-[#DFF1FF] font-Segoe text-blue hover:bg-blue hover:text-white"
              />
            </div>
            <MultiSelect
              data={epoDaysData}
              radius="md"
              rightSection={<DownArrowIcon />}
              maxSelectedValues={1}
              id="EPO_DaysMultiFilter"
              searchable
              creatable={hasAdminPermissions()}
              getCreateLabel={(query) => `+ Create ${query}`}
              onCreate={(query) => {
                const item = { value: query, label: query };
                setEpoDaysData((current) => [...current, item]);
                return item;
              }}
              value={epo_days}
              onChange={(selectedValues) => {
                setEpoDays(selectedValues);
              }}
              placeholder={'EPO Days'}
              styles={{
                input: {
                  border: '1px solid #27ABE2',
                  height: '36px !important',
                },
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EpoDaysPopup;
