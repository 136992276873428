import { Text } from '@mantine/core';

interface BtnTextProps {
  text: string;
  fontSize: string;
  weight?: number;
}

export default function BtnText({
  text,
  fontSize,
  weight = 600,
}: BtnTextProps) {
  return (
    <>
      <Text weight={weight} style={{ fontFamily: '', fontSize }}>
        {text}
      </Text>
    </>
  );
}
