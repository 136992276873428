import { Avatar, Button, Input, SimpleGrid, Text } from '@mantine/core';
import { useState } from 'react';

import H2 from '../Texts/H2';

interface RowsInfoProps {
  title: string;
  register: any;
  value: string;
  error: any;
}

interface ProfileGridProps {
  rowsInfo2: RowsInfoProps[];
}

export default function ProfileEditingForm({ rowsInfo2 }: ProfileGridProps) {
  const [isEditing, setIsEditing] = useState(false);

  return isEditing ? (
    <div className="flex flex-row pt-10">
      <div className="w-1/4">
        <H2 text="Contact Information" fontSize="20px" fontWeight={600} />
      </div>
      <SimpleGrid cols={3}>
        {rowsInfo2.map((r, i) => {
          return (
            <div style={{ width: '220px' }} key={i}>
              <div className="pb-2">
                <H2 text={r.title} fontSize="16px" fontWeight={600} />
              </div>
              <div className="pb-5">
                <Input placeholder={r.title} {...r.register} />
                {r.error && 'Invalid Input'}
              </div>
            </div>
          );
        })}
      </SimpleGrid>
      <Button
        onClick={() => setIsEditing(false)}
        leftIcon={<Avatar src={'/svg/save.svg'} size={12} />}
        variant="white"
      >
        <Text
          weight={600}
          style={{ color: '#2BACE2', fontFamily: '', fontSize: '14px' }}
        >
          {' '}
          Save changes{' '}
        </Text>
      </Button>
    </div>
  ) : (
    <div className="flex flex-row pt-10">
      <div className="w-1/4">
        <H2 text="Contact Information" fontSize="20px" fontWeight={600} />
      </div>
      <SimpleGrid cols={3}>
        {rowsInfo2.map((r, i) => {
          return (
            <div style={{ width: '220px' }} key={i}>
              <div className="pb-2">
                <H2 text={r.title} fontSize="16px" fontWeight={600} />
              </div>
              <div className="pb-5">
                <Text
                  style={{ color: '#737588', fontFamily: '', fontSize: '16px' }}
                >
                  {' '}
                  {r.value}{' '}
                </Text>
              </div>
            </div>
          );
        })}
      </SimpleGrid>
      <Button
        type="submit"
        onClick={() => setIsEditing(true)}
        leftIcon={<Avatar src={'/svg/edit.svg'} size={12} />}
        variant="white"
      >
        <Text
          weight={600}
          style={{ color: '#809FB8', fontFamily: '', fontSize: '14px' }}
        >
          {' '}
          Edit Section{' '}
        </Text>
      </Button>
    </div>
  );
}
