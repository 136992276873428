import { Button, Loader } from '@mantine/core';

import BtnText from '../Texts/BtnText';

interface FilledBtnProps {
  width: string;
  height: string;
  bgColor: string;
  hoverColor: string;
  btnText: string;
  fontSize: string;
  onclick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
}

export default function FilledBtn({
  width,
  height,
  hoverColor,
  btnText,
  fontSize,
  disabled,
  loading,
  onclick,
  type,
}: FilledBtnProps) {
  return (
    <Button
      radius="xl"
      type={type || 'button'}
      style={{ width: `${width}`, height: `${height}` }}
      styles={(theme) => ({
        root: {
          '&:hover': {
            backgroundColor: theme.fn.darken(`${hoverColor}`, 0.05),
          },
          // color:'white'
        },
      })}
      className="bg-blue"
      onClick={() => (onclick ? onclick() : () => {})}
      disabled={disabled}
    >
      {loading ? (
        <Loader size={'sm'} sx={() => ({ stroke: 'white' })} />
      ) : (
        <BtnText text={`${btnText}`} fontSize={`${fontSize}`} />
      )}
    </Button>
  );
}
