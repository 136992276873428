/* eslint-disable */

import '../../App.css';

import { useMutation } from '@apollo/client';
import { Group, Text, useMantineTheme } from '@mantine/core';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ADD_CONTACTS_FILE, COMPARE_FILE } from '../../graphql/mutations';
import { ROUTES } from '../../routes/config';
import FilledBtn from '../Buttons/FilledBtn';
import LeftTextBtn from '../Buttons/LeftTextBtn';
import ColorCards from '../Cards/ColorCards';
import AddPhonesModal from '../Modals/AddPhonesModal';
import H2 from '../Texts/H2';
import Paragraph from '../Texts/Paragraph';

interface LocationState {
  uuid: string;
  preview: string[][];
  ext: string;
  compareName: boolean;
  addNumbers: boolean;
  states: string[];
  federalDnc: boolean;
  tcpa: boolean;
  dncComplainers: boolean;
}

export interface AddResult {
  addedCount: number;
  invalidCount: number;
  duplicateCount: number;
  invalidNumbers?: {
    email: string;
    phone: string;
    name: string;
  }[];
}

export interface SelectedIndexes {
  phone: number | undefined;
  phone2: number | undefined;
  phone3: number | undefined;
  phone4: number | undefined;
  first_name: number | undefined;
  last_name: number | undefined;
  email: number | undefined;
}

export default function MassScrubStep2() {
  const [exportedColumns, setExportedColumns] = useState<number[]>([]);
  const [exportAll, setExportAll] = useState(false);
  const [keepHeaderColumns, setKeepHeaderColumns] = useState<number[]>([]);
  const [keepFileFormat, setKeepFileFormat] = useState(false);
  const [groupByPerson, setGroupByPerson] = useState(false);
  const [error, setError] = useState('');
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const {
    uuid,
    preview,
    ext,
    compareName,
    addNumbers,
    states,
    federalDnc,
    tcpa,
    dncComplainers,
  } = useLocation().state as LocationState;

  const [addModal, setAddModal] = useState<AddResult | null>();

  const [selected, setSelected] = useState<SelectedIndexes>({
    phone: undefined,
    phone2: undefined,
    phone3: undefined,
    phone4: undefined,
    first_name: undefined,
    last_name: undefined,
    email: undefined,
  });

  const [compare] = useMutation(COMPARE_FILE);
  const [add] = useMutation(ADD_CONTACTS_FILE);

  const [disabled, setDisabled] = useState(false);

  const onCompare = async () => {
    if (selected.phone === undefined)
      return setError('Phone must be selected before comparing a file.');
    setError('');
    setDisabled(true);
    if (addNumbers) {
      const resAdd = await add({
        variables: {
          id: uuid,
          phone: selected.phone,
          phone2: selected.phone2,
          phone3: selected.phone3,
          phone4: selected.phone4,
          first_name: selected.first_name,
          last_name: selected.last_name,
          email: selected.email,
        },
      });
      setAddModal(resAdd.data.addContacts as AddResult);
    } else {
      await compare({
        variables: {
          id: uuid,
          phone: selected.phone,
          phone2: selected.phone2,
          phone3: selected.phone3,
          phone4: selected.phone4,
          first_name: selected.first_name,
          last_name: selected.last_name,
          email: selected.email,
          compareName,
          states,
          federalDnc,
          tcpa,
          dncComplainers,
          exportedColumns,
          keepHeaderColumns,
          outputFileFormat: keepFileFormat ? ext : 'csv',
          groupByPerson,
        },
      });
      navigate(ROUTES.PHONE_SCRUB);
    }
    return null;
  };

  const selectedHelper = (
    value: keyof SelectedIndexes | null,
    cardIndex: number
  ) => {
    if (value === null) {
      setSelected((prev) => {
        if (prev.phone === cardIndex) {
          prev.phone = undefined;
        } else if (prev.phone2 === cardIndex) {
          prev.phone2 = undefined;
        } else if (prev.phone3 === cardIndex) {
          prev.phone3 = undefined;
        } else if (prev.phone4 === cardIndex) {
          prev.phone4 = undefined;
        } else if (prev.first_name === cardIndex) {
          prev.first_name = undefined;
        } else if (prev.last_name === cardIndex) {
          prev.last_name = undefined;
        } else if (prev.email === cardIndex) {
          prev.email = undefined;
        }
        return { ...prev };
      });
      setExportAll(false);
    } else {
      setSelected((prev) => {
        prev[value] = cardIndex;
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const i in prev as SelectedIndexes) {
          const index:
            | 'phone'
            | 'phone2'
            | 'phone3'
            | 'phone4'
            | 'last_name'
            | 'first_name'
            | 'email' = i as
            | 'phone'
            | 'phone2'
            | 'phone3'
            | 'phone4'
            | 'last_name'
            | 'first_name'
            | 'email';
          if (index !== value && prev[index] === cardIndex)
            prev[index] = undefined;
        }
        return { ...prev };
      });
    }
  };

  const getCardsToExport = (cardsToExport: number[]) => {
    return cardsToExport.filter(
      (_c, i) =>
        i !== selected.email &&
        i !== selected.last_name &&
        i !== selected.first_name &&
        i !== selected.phone &&
        i !== selected.phone2 &&
        i !== selected.phone3 &&
        i !== selected.phone4
    );
  };

  const onExport = (col: number) => {
    const index = exportedColumns.findIndex((c) => c === col);
    if (index >= 0) {
      const newColumns = [...exportedColumns];
      newColumns.splice(index, 1);
      setExportedColumns([...newColumns]);
      setExportAll(false);
    } else {
      const newExported = [...exportedColumns, col];
      setExportedColumns([...newExported]);
      const cards = preview[0]?.map((_col, i) => preview.map((row) => row[i]));
      const cardsToExport = Array.from({ length: cards?.length || 0 }, (_, i) => i);
      const exportCards = getCardsToExport(cardsToExport);
      if (
        exportCards.length === newExported.length &&
        newExported.every((n) => exportCards.includes(n))
      ) {
        setExportAll(true);
      }
    }
  };

  const onExportAll = () => {
    const cards = preview[0]?.map((_col, i) => preview.map((row) => row[i]));
    const cardsToExport = Array.from({ length: cards?.length || 0 }, (_, i) => i);
    const exportCards = getCardsToExport(cardsToExport);
    if (exportAll) {
      setExportedColumns([]);
    } else {
      setExportedColumns([...exportCards]);
    }
    setExportAll(!exportAll);
  };

  const onSelectColumn = (col: number) => {
    const index = exportedColumns.findIndex((c) => c === col);
    const newColumns = [...exportedColumns];
    if (index >= 0) {
      newColumns.splice(index, 1);
      setExportedColumns([...newColumns]);
    }
    const cards = preview[0]?.map((_col, i) => preview.map((row) => row[i]));
    const cardsToExport = Array.from({ length: cards?.length || 0 }, (_, i) => i);
    const exportCards = getCardsToExport(cardsToExport);
    if (
      exportCards.length === newColumns.length &&
      newColumns.every((n) => exportCards.includes(n))
    ) {
      setExportAll(true);
    } else {
      setExportAll(false);
    }
  };

  const onKeepHeader = (col: number) => {
    const index = keepHeaderColumns.findIndex((c) => c === col);
    if (index >= 0) {
      const newColumns = [...keepHeaderColumns];
      newColumns.splice(index, 1);
      setKeepHeaderColumns([...newColumns]);
    } else {
      const newColumns = [...keepHeaderColumns, col];
      setKeepHeaderColumns([...newColumns]);
    }
  };

  const mapCards = () => {
    const cards = preview[0]?.map((_col, i) => preview.map((row) => row[i]));
    return cards?.map((value, i) => (
      <ColorCards
        key={i}
        cardIndex={i}
        selected={selected}
        onSelect={selectedHelper}
        descriptions={value.map((item) => ({ text: item || "" }))}
        onExport={() => onExport(i)}
        isExported={exportedColumns.findIndex((c) => c === i) >= 0}
        onSelectColumn={() => onSelectColumn(i)}
        isKeepHeader={keepHeaderColumns.includes(i)}
        onKeepHeader={() => onKeepHeader(i)}
      />
    ));
  };

  return (
    <>
      <AddPhonesModal
        opened={!!addModal}
        close={() => {
          setAddModal(null);
          navigate(ROUTES.PHONE_SCRUB);
        }}
        res={addModal || null}
      />
      <div className="flex justify-between">
        <H2 text="New Phone Scrub" fontSize="32px" fontWeight={300} />
      </div>
      <div className="pt-1">
        <Paragraph text="On this page you can compare your phone numbers with our database to find any DNC/TCPA numbers" />
      </div>
      <div className="ml-[-20px] py-2">
        <LeftTextBtn btnText="Back to List" link={ROUTES.PHONE_SCRUB} />
      </div>
      <div className="">
        <H2 text="Step 2 of 2" fontSize="24px" fontWeight={300} />
      </div>
      <div className="py-2">
        <Paragraph text="Map your sheet fields with Phone Scrub System fields. You must at least map Full Name and Phone." />
        <Text style={{ color: '#228be6', fontFamily: '', fontSize: '12px' }}>
          The columns marked as "Export" will be included in the exported CSV
          file.
        </Text>
      </div>
      <div className="flex flex-row items-center gap-x-2 font-nunito text-sm text-[#0f182d]">
        <input
          id={`export-all`}
          type={'checkbox'}
          onChange={() => onExportAll()}
          checked={exportAll}
        />
        <label className="pt-1" htmlFor={`export-all`}>
          Export All
        </label>
      </div>
      <div className="flex flex-row items-center gap-x-2 font-nunito text-sm text-[#0f182d]">
        <input
          id="group-by-person"
          type="checkbox"
          onChange={() => setGroupByPerson(!groupByPerson)}
          checked={
            groupByPerson &&
            (selected.phone2 !== undefined ||
              selected.phone3 !== undefined ||
              selected.phone4 !== undefined)
          }
          disabled={
            selected.phone2 === undefined &&
            selected.phone3 === undefined &&
            selected.phone4 === undefined
          }
        />
        <label className="pt-1" htmlFor="group-by-person">
          Group by Person
        </label>
      </div>
      {ext !== 'csv' && (
        <div className="flex flex-row items-center gap-x-2 font-nunito text-sm text-[#0f182d]">
          <input
            id="keep-ext"
            type="checkbox"
            onChange={() => setKeepFileFormat(!keepFileFormat)}
            checked={keepFileFormat}
          />
          <label className="pt-1" htmlFor="keep-ext">
            Keep File Format (.{ext})
          </label>
        </div>
      )}
      <div>
        <Group
          position="left"
          style={{
            marginBottom: 5,
            marginTop: theme.spacing.sm,
            alignItems: 'start',
          }}
        >
          {mapCards()}
        </Group>
        <div className="mb-10 pt-5">
          <p className=" pb-3 text-[14px] text-rose-600">{error}</p>
          <FilledBtn
            width="167px"
            height="42px"
            bgColor="#2BACE2"
            hoverColor="#4BC9FD"
            btnText="Complete import"
            fontSize="16px"
            disabled={disabled}
            onclick={onCompare}
          />
        </div>
      </div>
    </>
  );
}
