import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';

import EditLender from '../components/pages/EditLender';
import Lenders, {
  filtersState as lenderFilters,
} from '../components/pages/Lenders';
import LendersTable from '../components/pages/LendersTable';
import {
  filtersState as loanStatusFilters,
  // LoanStatus,
} from '../components/pages/LoanStatus';
import { Logout } from '../components/pages/Logout';
import Logs from '../components/pages/Logs';
import MassScrubStep1 from '../components/pages/MassScrubStep1';
import MassScrubStep2 from '../components/pages/MassScrubStep2';
import NewLender from '../components/pages/NewLender';
import PhoneScrub from '../components/pages/PhoneScrub';
import Profile from '../components/pages/Profile';
import SingleLender from '../components/pages/SingleLender';
// import SingleLoanStatus from '../components/pages/SingleLoanStatus';
import SingleScrub from '../components/pages/SingleScrub';
import SingleUser from '../components/pages/SingleUser';
import Users from '../components/pages/Users';
import Welcome from '../components/pages/Welcome';
import { usePermissions } from '../hooks/usePermissions';
import { ROUTES } from './config';

const RoutesIntranet = () => {
  const { hasAdminPermissions } = usePermissions();
  const resetLenderFilters = useResetRecoilState(lenderFilters);
  const resetLoanStatusFilters = useResetRecoilState(loanStatusFilters);

  const location = useLocation();
  useEffect(() => {
    // If the current route in not a lender page, not starting with /lender(s),
    // forget the saved filters.
    if (
      !location.pathname.startsWith(ROUTES.LENDERS) &&
      !location.pathname.startsWith('/lender')
    )
      resetLenderFilters();
    // If the current route in not a loan status page, not starting with /loan-status,
    // forget the saved filters.
    if (
      !location.pathname.startsWith(ROUTES.LOAN_STATUS) &&
      !location.pathname.startsWith('/loan-status')
    )
      resetLoanStatusFilters();
  }, [location]);

  return (
    <Routes>
      <Route
        path={ROUTES.HOME}
        element={<Navigate to={ROUTES.PHONE_SCRUB} replace />}
      ></Route>
      {/* ROUTES FOR ALL USERS */}
      <Route path={ROUTES.LOGOUT} element={<Logout />}></Route>
      {/* <Route path={ROUTES.HOME} element={<Dashboard />}></Route> */}
      {/* <Route path="/ui-kit" element={<UIKit />}></Route> */}
      <Route path={ROUTES.PROFILE} element={<Profile />}></Route>
      <Route path={ROUTES.PHONE_SCRUB} element={<PhoneScrub />}></Route>
      <Route
        path={ROUTES.MASS_SCRUB_STEP_1}
        element={<MassScrubStep1 />}
      ></Route>
      <Route
        path={ROUTES.MASS_SCRUB_STEP_2}
        element={<MassScrubStep2 />}
      ></Route>
      <Route path={ROUTES.SINGLE_SCRUB} element={<SingleScrub />}></Route>
      <Route path={ROUTES.LENDERS} element={<Lenders />}></Route>
      <Route path={ROUTES.WELCOME} element={<Welcome />}></Route>
      <Route
        path={ROUTES.LENDER}
        element={<SingleLender hasAdmin={hasAdminPermissions()} />}
      ></Route>
      {/* 
      {hasLPPermissions() && (
        <>
          <Route path={ROUTES.LOAN_STATUS} element={<LoanStatus />}></Route>
          <Route
            path={ROUTES.SINGLE_LOAN_STATUS}
            element={<SingleLoanStatus />}
          ></Route>
        </>
      )} */}

      {/* ADMIN ROUTES */}
      {/* {hasAdminPermissions() && ( */}
      <>
        <Route path={ROUTES.LENDER_EDIT} element={<EditLender />}></Route>
        <Route path={ROUTES.LOGS} element={<Logs />}></Route>
        <Route path={ROUTES.USERS} element={<Users />}></Route>
        <Route path={ROUTES.SINGLE_USER} element={<SingleUser />}></Route>
        <Route path={ROUTES.LENDER_TABLE} element={<LendersTable />} />
        <Route path={ROUTES.LENDER_NEW} element={<NewLender />}></Route>
      </>
      {/* )} */}
    </Routes>
  );
};
export default RoutesIntranet;
